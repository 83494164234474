
@media (max-width: 1310px) {
  .profession_list {
    max-width: 384px !important;
  }
}

@media (max-width: 990px) {
  .profession_list {
    display: none !important;
  }
}

.profession_list {
  display: flex;
  margin: auto 0;
  padding: 5px 15px 10px 20px;
  max-height: calc(100vh - 20px) !important;
  min-height: calc(100vh - 20px) !important;
  max-width: 404px;
  width: 100%;
  background: #2b2932;//#201E26;
  box-shadow: 0 85px 100px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  .professions {
    margin: 8px auto 0 auto;
    padding-right: 5px;
    position: relative;
    max-width: 370px;
    width: 100%;
    overflow-y: auto;
    user-select: none;

    .search_profession {
      position: relative;

      img {
        position: absolute;
        left: 8px;
        top: 14px;
        z-index: 6;
      }

      .input_classic {
        padding-left: 30px;
        margin: 5px 0 10px 0;
        background: transparent;
      }
    }

    .buttons_select {
      display: flex;
    }

    .btn_intro {
      position: absolute;
      right: 5px;
      top: 0;
      padding: 1px 10px 3px 10px;
      border: 1px solid white;
      border-radius: 10px;
      cursor: pointer;
    }

    .title {
      margin-bottom: 8px;
      font-size: 16px;

      img {
        margin-right: 15px;
        margin-left: 5px;
      }
    }

    ul:not(:nth-child(1)) {
      opacity: 0;
      transform: scale(0);
      max-height: fit-content;
      height: auto;
      transition: all .4s ease;

      li {
        display: flex;
        align-items: center;
        padding: 6px 15px;
        max-width: 370px;
        width: 100%;
        height: 0;
        opacity: 0;
        max-height: 0;
        border: 1px solid #474C5A;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
        border-radius: 18px;
        background-color: var(--dark);
        transition: all .4s ease;
        cursor: pointer;

        svg {
          vertical-align: middle;
          transition: all .2s linear;

          &.active {
            path {
              fill: #FFD23B;
            }
          }

          &:not(:last-child) {
            &:hover {
              transition: all .2s linear;

              path {
                fill: #FFD23B;
              }
            }
          }
        }

        .border {
          margin: 0 10px;
          display: inline-block;
          height: 24px;
          width: 1px;
          vertical-align: middle;
          background-color: #4E535F;
        }

        span {
          margin-right: 15px;
          padding: 5px 0;
          display: inline-block;
          max-width: 160px;
          width: 100%;
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .into_icon {
          margin-left: auto;
          margin-right: 10px;
          min-width: 14px;
          min-height: 14px;
          transition: all .2s linear;
          z-index: 4;

          &.active {
            path {
              fill: var(--dark);
            }
          }

          &:hover {
            path {
              transition: all .2s linear;
              fill: #757A88 !important;
            }
          }
        }

        span.ant-progress-text {
          font-size: 10px;
          color: white !important;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 10px;
        }

        &:hover {
          border: 1px solid #757A88;
        }

        &.active {
          background: #474C5A;
          border: 1px solid #A2AACA;
        }
      }

      &.list_profs {
        opacity: 1;
        transform: scale(1);
        max-height: fit-content;
        height: auto;

        li {
          height: auto;
          max-height: none;
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    .pagination_all {
      margin: 0 auto;

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }

    .star {
      min-width: 14px;
      min-height: 14px;
    }
  }
}