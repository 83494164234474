
.sidebar {
  padding: 10px;
  position: relative;
  margin: auto 30px auto 0;
  width: 100%;
  max-width: 60px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 20px);
  min-height: calc(100vh - 20px);
  font-size: 14px;
  background-color: var(--dark);
  border-radius: 0 20px 20px 0;
  transition: all .3s linear;
  z-index: 4;

  .logo {
    position: relative;
    margin-bottom: 70px;
    min-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    svg {
      margin-bottom: 0;
      width: 40px;
      height: 40px;
    }

    img {
      margin-bottom: 6px;
    }
    .current_user {
      width: 100%;
      position: absolute;
      top: 150%;
      display: flex;
      align-items: center;
      .wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 5px;
        .icon_wrapper {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .icon {
          margin: 0;
          max-width: unset;
          position: absolute;
          width: 20px;
          height: 20px;
        }
      }
      .email {
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--text_color_dark);
      }
    }
  }

  .logo_b {
    max-width: 190px;
    width: 100% !important;
    box-shadow: none !important;
    filter: none !important;
  }

  .notification_btn {
    margin: 0 0 20px 4px;
    display: flex;
    align-items: center;
    width: calc(100% - 4px);
    position: relative;
    user-select: none;
    border-radius: 16px;
    transition: all .3s linear;
    cursor: pointer;

    svg {
      min-width: 32px;
      min-height: 33px;
    }

    .count_notification {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      top: -5px;
      right: -2px;
      font-size: 8px;
      line-height: 10px;
      border-radius: 50%;
      background-color: #FF5937;
      color: white;
    }

    &.active {
      svg {
        filter: drop-shadow(0px 10px 14px rgba(48, 82, 255, 0.4));

        rect:first-child {
          fill: var(--blue);
          fill-opacity: 1;
        }
      }
    }
  }

  .navigation_btn {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    user-select: none;
    transition: all .3s linear;
    background: transparent;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      border-radius: 16px;
      transition: all .3s linear;
      border: 1px solid transparent;

      &:hover {
        border-color: var(--blue)
      }
    }

    svg {
      margin-left: -1px;
      min-width: 40px;
      min-height: 40px;
    }

    .generate_course-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
      background: #16151B;
      border-radius: 16px;
      transition: all .3s linear;

      svg {
        margin-left: 0;
        min-width: 22px;
        min-height: 18px;
        max-width: 22px;
        max-height: 18px;
        border-radius: 0;
      }

      &:hover {
        background: var(--blue);
      }
    }

    &.active {

      img {
        border-color: var(--blue);
      }

      svg {
        filter: drop-shadow(0px 10px 14px rgba(48, 82, 255, 0.4));

        rect:first-child {
          fill: var(--blue);
          fill-opacity: 1;
        }
      }

      .generate_course-icon {
        background: var(--blue);
        filter: drop-shadow(0px 10px 14px rgba(48, 82, 255, 0.4));
      }

      .history_pay {
        background: var(--blue);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .link {
    margin-left: 5px;
    text-align: center;
    padding: 5px 15px;
    color: var(--text_color_dark);
    border-radius: 8px;
    transition: all .3s linear;
    white-space: nowrap;
  }

  .collapse_btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: -15px;
    width: 28px;
    height: 28px;
    transform: translateY(-50%);
    cursor: pointer;
    border-radius: 50%;
    background: rgba(231, 231, 232, .4);
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.40), 0 5px 10px 0 rgba(0, 0, 0, 0.20), 0 0 0 1px rgba(0, 0, 0, 0.10);
    transition: all .3s linear;

    svg {
      margin-bottom: 0;
      border-radius: 0;
      transform: rotate(180deg);
    }

    &:hover {
      background: rgba(231, 231, 232, 1);
      transform: translateY(-50%) scale(1.1);
    }

    &.open {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  svg {
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
    transition: all .3s linear;
    border-radius: 16px;
    user-select: none;

    rect, filter {
      transition: all .3s linear;
    }

    &.active, &:hover {
      filter: drop-shadow(0px 10px 14px rgba(48, 82, 255, 0.4));

      rect:first-child {
        fill: var(--blue);
        fill-opacity: 1;
      }
    }
  }

  .bottom_nav {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    .support {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0;
      margin: 5px 0 12px;
    }

    .support-icon {
      flex: 1 0 40px;
      width: 40px;
      height: 40px;
      fill: white;
      background-color: #27292F;
      border-radius: 16px;
    }

    .support-text {
      flex: 1 1 auto;
      padding: 5px 10px;
      font-size: 12.5px;
    }

    .change_parent {
      margin: 0 0 12px 0;
      display: flex;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      transition: all .3s;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
        box-shadow: 5px 15px 22px -12px rgba(0, 0, 0, 0.55);

        .change_language-collapse {
          color: white;
        }
      }
    }

    .change_language {
      padding: 8px 11px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      max-height: 33px;
      background: #27292F;
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;
      transition: all .3s;
      cursor: pointer;

      div {
        text-align: center;
        color: #757A88;
        transition: all .3s;
        cursor: pointer;

        &:hover,
        &.active {
          transition: all .3s;
          color: white;
        }
      }

      &.open {
        transition: all .3s;
        flex-direction: column;
        max-height: 60px;
        height: 100%;

        div:first-child {
          padding-bottom: 2px;
          border-bottom: 1px solid var(--background_lighten-dark);
        }
      }
    }

    .change_language-collapse {
      position: absolute;
      left: 58px;
      color: var(--text_color_dark);
      transition: all .3s linear;
      white-space: nowrap;
    }
  }

  .history_pay {
    display: flex;
    margin: 0 auto 0 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    background: #201E26;
    border-radius: 16px;
    transition: all .3s linear;

    svg {
      width: 26px;
      min-width: 26px;
      height: 26px;
      min-height: 26px;

      rect:first-child {
        fill: #757A88 !important;
      }
    }

    &:hover {
      background: var(--blue);
    }
  }

  &.z_index_0 {
    z-index: 0;
  }

  &.display_none {
    display: none;
  }

  &.open {
    max-width: 210px;

    .notification_btn {
      background: rgba(255, 255, 255, 0.05);

      &:hover {
        background: rgba(255, 255, 255, 0.3);
        box-shadow: 5px 15px 22px -12px rgba(0, 0, 0, 0.55);

        .link {
          color: white;
        }
      }
    }

    .navigation_btn {
      background: rgba(255, 255, 255, 0.1);

      &:hover, &.active {
        background: rgba(255, 255, 255, 0.3);
        box-shadow: 5px 15px 22px -12px rgba(0, 0, 0, 0.55);

        .link {
          color: white !important;
        }
      }
    }
  }

  &.hide {
    pointer-events: none;
    opacity: 0.5;
  }
}

@media (max-width: 820px) {
  .sidebar {
    display: none;
  }
}