.modal_filter {
  padding-bottom: 0;
  max-width: 1340px;
  background: #F0E9FF;
  line-height: 24px;
  color: var(--dark);
  border: 1px solid #343843;
  box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 60px;

  .title {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #8146FF;

    .h1 {
      font-size: 40px;
      line-height: 48px;
      color: #16151B;
    }

    .close_icon {
      position: absolute;
      top: 45px;
      right: 40px;
      cursor: pointer
    }

  }

  .parent {
    display: flex;
    margin-bottom: 50px;
  }

  .left_block-filter {
    max-width: 20%;
    width: 20%;
    font-size: 20px;
  }

  .right_block-filter {
    margin-left: 20px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      // margin-left: 10px;
      font-size: 16px;
      line-height: 19px;
    }

    label {
      display: flex;
      align-items: center;
    }

    .input_classic {
      width: 100%;
      padding: 8.8px 15px;
      margin-bottom: 15px;
      background: rgba(129, 70, 255, 0.2);
      border: 1px solid #8146FF;
      border-radius: 17px;
      font-size: 16px;
      color: #16151B;
    }

    .regionSelect {
      margin-bottom: 15px;
      font-size: 16px;

      &.active {
        .ant-select-selector {
          border-radius: 17px 17px 0 0;
        }
      }
    }

    .ant-select-selector {
      padding-top: 6px;
      width: 100%;
      height: 44px;
      border-radius: 17px;
      background: rgba(129, 70, 255, 0.2);
      border: 1px solid #8146FF !important;
      color: #16151B;

      input {
        padding-top: 10px !important;
      }


      &::placeholder {
        color: #16151B;
      }

      &:active {
        border: 1px solid #8146FF !important;
        box-shadow: none !important;
      }

      &:focus {
        border: 1px solid #8146FF !important;
        box-shadow: none !important;
      }

      &:hover {
        border: 1px solid #8146FF !important;
        box-shadow: none !important;
      }
    }

    .btn {
      padding: 1.5px 20px;
      color: #16151B;
      border: 1px solid #8146FF;
      background: rgba(129, 70, 255, 0.3);
      border-radius: 34px;
      cursor: pointer;
    }

    .select_specialization {
      padding-bottom: 5px;
      align-self: center;
      font-size: 16px;
      line-height: 19px;
      color: #8146FF;
      border-bottom: 1px solid #8146FF;
      cursor: pointer;
    }

    .btn_add {
      position: absolute;
      padding: 9px 24px;
      top: 5px;
      right: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 17px;
      color: white;
      background: #8146FF;
      box-shadow: 0 9px 10px rgba(129, 70, 255, 0.3);
      border-radius: 13px;
      cursor: pointer;
      z-index: 3;
    }

    .basket_cities {
      display: flex;

      .city {
        margin-right: 11px;
        padding: 7px 35px 4px 12px;
        height: 32px;
        position: relative;
        font-size: 14px;
        line-height: 17px;
        color: #16151B;
        text-align: center;
        background: rgba(129, 70, 255, 0.3);
        border-radius: 34px;

        img {
          position: absolute;
          top: 5px;
          right: 8px;
          cursor: pointer;
        }
      }
    }

    .apostrophe {
      margin: 0 20px;
    }

    .slider {
      .ant-slider-rail {
        height: 1px;
        background-color: #8146FF;
      }
    }

    .radio {
      .ant-radio-inner {
        background: rgba(129, 70, 255, 0.2);
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: #8146FF !important;
        border-width: 4px !important;
        box-shadow: none !important;
      }

      .ant-radio:hover .ant-radio-inner {
        border-color: transparent;
        box-shadow: none !important;
      }

      .ant-radio-checked .ant-radio-inner:after {
        background-color: rgba(129, 70, 255, 0.2) !important;
        box-shadow: none !important;
      }

      .ant-radio-checked .ant-radio-inner:focus {
        border-color: transparent !important;
        box-shadow: none !important;
      }

      .ant-radio-inner::after {
        background-color: blue !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }

      span.ant-radio.ant-radio-checked {
        border: none !important;
      }
    }

  }

  .line_bottom {
    height: 1px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #8146FF;
  }

  .btn_bottom {
    position: relative;
    width: 100%;
    padding: 13px 10px;
    color: white;
    text-align: center;
    background: #8146FF;
    border-radius: 26px;
    cursor: pointer;
  }

  .ant-modal-content {
    border-radius: 60px;
  }

  .checkbox {
    .slider:before {
      background-color: #8146FF;
    }
  }

  .helpfull_course {
    .left_block-filter {
      display: flex;

      span {
        margin-right: 10px;
      }
    }
  }
}

.modal_filter-category {
  // border: 1px solid #343843;
  max-width: 716px;
  width: 100% !important;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  color: white;


  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
      font-size: 40px;
      line-height: 48px;
    }

    img {
      align-self: center;
      width: 30px;
      height: 30px;
    }
  }

  .tree_antd {
    background-color: transparent;
    color: white;

    span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    span.ant-tree-switcher.ant-tree-switcher-noop {
      margin-left: -24px;
    }

    .ant-tree-checkbox-inner,
    span.ant-tree-checkbox.ant-tree-checkbox-checked {
      background-color: transparent !important;
      border: none !important;

      &:hover {
        border-color: transparent !important;
      }

      &:after {
        border-color: transparent;
        background-color: transparent !important;
      }
    }

    .ant-tree-checkbox-inner::before {
      content: '';
      -webkit-appearance: none;
      background: rgba(162, 170, 202, 0.2);
      border: 1px solid #A2AACA;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 9px;
      top: -3px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 5px;
    }

    .ant-tree-checkbox-inner::after {
      content: '';
      display: block;
      position: absolute;
      top: 0 !important;
      left: 8px;
      width: 6px;
      height: 12px;
      border: solid #A2AACA;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}