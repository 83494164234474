.modal_profession-info {
  width: calc(100% - 40px) !important;
  max-width: 1400px;
  top: 40px;
  padding-bottom: 0;
  margin-bottom: 100px;
  background: #201E26;
  border: 1px solid #343843;
  box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white !important;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding-bottom: 0 !important;
  }

  .title {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    font-size: 40px;
    line-height: 48px;


    .close_modal {
      cursor: pointer;
    }

    img {
      vertical-align: unset;
    }
  }

  .description {
    padding: 2px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 20px;
    max-height: 700px;
    white-space: pre-wrap;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }

  .info {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 17px;
  }

  .title_popular {
    margin: 20px 0;
    font-size: 24px;
    line-height: 29px;
  }

  .block_skill {
    padding: 12px 12px 7px 12px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #343843;
    border-radius: 20px;

    .skill {
      padding: 5px 5px 5px 12px;
      height: 33px;
      display: flex;
      align-items: center;
      margin-right: 5px;
      margin-bottom: 5px;
      width: fit-content;
      text-align: center;
      background: rgba(22, 21, 27, 0.65);
      border: 1px solid rgba(207, 26, 26, 0.6);
      border-radius: 34px;
      white-space: nowrap;

      img {
        margin-left: 5px;
      }

      span {
        margin-bottom: 1px;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .title_price {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 24px;
  }

  .recomendation_block {
    width: 100%;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .group_ {
        margin-right: 10px;

        .h3 {
          margin-bottom: 8px;
          font-size: 24px;
          line-height: 29px;
        }

        .text {
          font-size: 14px;
          line-height: 17px;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .btn_show {
        max-width: 180px;
        width: 100%;
        padding: 9px;
        height: 40px;
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        background: #8146FF;
        box-shadow: 0 9px 20px rgba(129, 70, 255, 0.3);
        border-radius: 15px;
        cursor: pointer;
      }

    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .reuse_course-item {
        width: min-content;
        min-height: 600px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        background: #343843;
        border-radius: 30px;

        .rate {
          position: absolute;
          padding: 4px 14px;
          top: 25px;
          left: 22px;
          background: rgba(32, 30, 38, 0.6);
          border-radius: 34px;

          .ant-rate {
            font-size: 10px;
          }

          li.ant-rate-star {
            margin-right: 3px;
            margin-bottom: 0;
            background-color: transparent;
          }
        }

        .popular {
          position: absolute;
          top: 23px;
          right: 22px;
          display: flex;
          padding: 2px 3px;
          background: rgba(32, 30, 38, 0.6);
          border-radius: 34px;

          span {
            margin: 0 10px;
            font-size: 14px;
          }
        }

        .category {
          display: flex;
          margin: 0 20px 15px 20px;
          padding: 4px 10px;
          width: fit-content;
          border-radius: 34px;

          img {
            margin-right: 10px;
            align-self: center;
          }

          span {
            font-size: 14px;
          }
        }

        .info {
          margin: 0 20px 7px 20px;
          max-width: 280px;
          font-size: 20px;
          line-height: 24px;
          overflow-wrap: break-word;
        }

        .stage {
          ul, li {
            padding: 0;
            margin: 0;
          }

          ul {
            margin: 0 20px 5px 20px;
          }

          li {
            white-space: nowrap;
            font-size: 16px;
            color: var(--text_color_dark);

            &:not(:last-child) {
              margin-right: 15px;
            }
          }
        }

        .title_skill {
          margin: 0 20px 10px 20px;
          font-size: 16px;
        }

        .key_skills {
          margin: 0 20px 10px 20px;
          max-height: 140px;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;

          .skills {
            display: flex;
            padding: 4px 10px;
            margin-bottom: 5px;
            position: relative;
            flex-wrap: wrap;
            color: var(--text_color_dark);
            font-size: 14px;
            background: rgba(162, 170, 202, 0.2);
            border-radius: 16px;

            img {
              position: absolute;
              top: 8px;
              right: 5px;
              cursor: pointer;
            }

            &:not(:last-child) {
              margin-right: 8px;
            }
          }
        }

        .date_course {
          padding: 3px;
          margin: 0 20px 10px 20px;
          width: fit-content;
          background: rgba(32, 30, 38, 0.6);
          border-radius: 34px;

          img {
            width: 19px;
            height: 19px;
            vertical-align: sub;
          }

          span {
            margin: 0 7px;
            font-size: 14px;
          }
        }

        .bottom_block {
          margin-top: auto;

          a {
            color: white;
          }
        }

        .cost {
          margin: 0 20px 15px 20px;
          font-size: 16px;

          span {
            margin-right: 5px;
          }
        }

        .btn_open-course {
          padding: 12px;
          text-align: center;
          font-size: 14px;
          background: #3077FF;
          box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
          border-radius: 15px;
          cursor: pointer;
        }
      }
    }
  }


  .bottom_info {
    padding: 25px;
    background: #343843;
    border: 1px solid #474C5A;
    border-radius: 25px;
    font-size: 14px;
    line-height: 17px;

    div:not(:last-child) {
      margin-bottom: 15px;
    }

    span {
      margin-left: 5px;
      color: #B3DAFF;
    }
  }

  i.ant-spin-dot-item {
    background-color: #343843;
  }
}
