@media (max-width: 820px) {
  .view_lesson {
    padding: 5px 15px 10px 15px !important;
    margin-bottom: 20px !important;
    border-radius: 15px !important;

    .name_lesson {
      padding-top: 5px !important;
      font-size: 16px !important;
    }

    .group_inner {
      flex-direction: column !important;

      .stage_block {
        padding: 0 !important;
        background-color: transparent !important;
        margin-right: 0 !important;
        max-width: inherit !important;
        border: none !important;

        .stage {
          padding: 8px 5px !important;
          height: 40px !important;
          margin-right: 10px !important;
          user-select: none;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .group {
        margin-left: inherit !important;
      }

      .content_parent {
        padding: 15px !important;

        .title {
          flex-direction: column !important;

          .group {
            max-width: inherit !important;
          }

          span {
            // font-size: 24px !important;
            display: none !important;
          }
        }
      }
    }
  }
}

@keyframes show-matherial {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide-matherial {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.view_lesson {
  padding: 0 18px 20px 18px;
  margin-bottom: 15px;
  width: 100%;
  background: var(--dark);
  border: 1px solid var(--background_lighten-dark);
  border-radius: 24px;

  &.entering {
    animation: show-matherial .4s forwards linear;
  }

  &.entered {
    animation: show-matherial .4s forwards linear;
  }

  &.exiting {
    animation: hide-matherial .4s forwards linear;
  }

  &.exited {
    animation: hide-matherial .4s forwards linear;
  }

  .name_lesson {
    display: flex;
    padding-top: 15px;
    justify-content: space-between;
    margin-bottom: 7px;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;

    .eye {
      margin: -10px 77px 0 0;
      padding: 5px;
      border-radius: 50%;
      background-color: #201E26;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s linear;
      transform: rotate(180deg);
    }

    .img {
      width: 30px;
      height: 30px;
      align-self: center;
      cursor: pointer;
    }

    .arrow {
      padding: 10px;
      border-radius: 50%;
      background-color: #201E26;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s linear;
      transform: rotate(180deg);
    }
  }

  .group_inner {
    display: flex;
    width: 100%;
    margin-right: 42px;

    .group {
      display: flex;
      margin-left: auto;
      max-width: 305px;
      width: 100%;
      height: 25px;
      align-self: center;
      background: rgba(162, 170, 202, 0.2);
      border: 1px solid var(--text_color_dark);
      border-radius: 15px;

      div {
        width: 100%;
        text-align: center;
        color: #757A88;
        transition: all .7s ease;
        white-space: nowrap;
        cursor: pointer;

        &.active {
          background: #3077FF;
          border-radius: 26px;
          color: white;
        }

      }
    }

    .stage_block {
      max-width: 315px;
      margin-right: 30px;
      height: fit-content;
      min-height: 50px;
      width: 100%;
      padding: 10px;
      background: #201E26;
      border: 1px solid var(--background_lighten-dark);
      border-radius: 20px;

      .stage {
        padding: 8px 10px;
        margin-bottom: 10px;
        display: flex;
        width: 100%;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 16px;
        cursor: pointer;

        img {
          margin-right: 10px;
          align-self: center;
        }

        .info {
          align-self: center;
          overflow-wrap: anywhere;
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid #757A88;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .loading_matherial {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--text_color_dark);
        background: rgba(32, 30, 38, 0);
        border-radius: 8px;
        padding: 3px 8px;

        .ant-spin {
          margin-top: 8px;
        }
      }

      .btn_add {
        display: flex;
        cursor: pointer;

        img {
          margin-right: 15px;
          align-self: center;
        }

        span {
          color: #3077FF;
        }
      }

      .arrow_block {
        position: relative;
        min-width: 40px;
        height: 40px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 16px;

        svg {
          position: absolute;
          top: 16px;
          left: 14px;
          // transform: translate(-50%, -50%);
          transition: all .3s ease;
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid #757A88;

          svg {
            transition: all .3s ease;
            transform: rotate(180deg);
          }
        }
      }
    }

    .content_parent {
      padding: 25px 25px;
      max-width: 912px;
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #201E26;
      border: 1px solid var(--background_lighten-dark);
      border-radius: 20px;

      &.entering {
        animation: show-matherial 1s forwards linear;
      }

      &.entered {
        animation: show-matherial 1s forwards linear;
      }

      &.exiting {
        animation: hide-matherial .5s forwards linear;
      }

      &.exited {
        animation: hide-matherial .5s forwards linear;
      }

      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        span {
          font-size: 40px;
          line-height: 38px;
          overflow-wrap: anywhere;

        }

        .group {
          display: flex;
          margin-bottom: 3px;
          max-width: 305px;
          width: 100%;
          height: 25px;
          align-self: end;
          background: rgba(162, 170, 202, 0.2);
          border: 1px solid var(--text_color_dark);
          border-radius: 15px;

          div {
            width: 100%;
            text-align: center;
            color: #757A88;
            transition: all .7s ease;
            white-space: nowrap;
            cursor: pointer;

            &.active {
              background: #3077FF;
              border-radius: 26px;
              color: white;
            }

          }
        }
      }

      .video_block {
        padding: 20px;
        background-color: var(--dark);
        border-radius: 16px;

        .video_content {
          position: relative;
          padding-top: 56.25%;

          .react-player {
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .video_file-name {
          margin: 5px 0 0 0;
          font-size: 16px;
          color: var(--text_color_dark);
        }
      }

      video,
      iframe {
        max-height: 600px;
      }

      .description_title {
        color: white;
        font-size: 24px;
        line-height: 28px;
      }

      .description {
        color: var(--text_color_dark);
        white-space: break-spaces;
        font-size: 20px;
        overflow-wrap: anywhere;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

        a {
          color: #3077FF;
        }
      }

      .article_container {
        display: flex;
      }

      .article_content {
        background-color: var(--dark);
        padding: 20px;
        border-radius: 16px;

        .h4 {
          font-size: 24px;
          color: white;
        }

        .description {
          color: var(--text_color_dark);
          white-space: break-spaces;
          font-size: 20px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        }

        .ant-image-mask {
          border-radius: 20px;
        }

        &.voice {
          height: 100%;
          width: 0;
          padding: 0;
          opacity: 0;
          transition: 0.3s;

          display: flex;
          flex-direction: column;
          gap: 10px;

          .hint {
            color: #a2aaca;
          }

          .audio-item {
            audio {
              width: 280px;
              height: 35px;
              box-shadow: rgba(48, 119, 255, 0.45) 0px 0px 10px;
              //border: 1.5px solid #777777;
              border-radius: 18px;
            }
            .label {
              color: #a2aaca;
            }
          }
        }
        &.voice.open {
          margin-left: 10px;
          padding: 20px;
          opacity: 1;
          width: 320px;
          min-width: 320px;
        }
      }

      .voice_block {
        margin-top: 15px;
        display: flex;
        gap: 10px;
      }

      .scorm_content {
        iframe {
          width: 100%;
          height: auto;
          max-height: initial;
          min-height: 600px;
        }
      }

      .custom_steps {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .line {
          margin: 0 5px;
          margin-top: 15px;
          height: 1px;
          max-width: 50px;
          width: 100%;
          background-color: var(--text_color_dark);
        }

        .step {
          position: relative;
          margin-bottom: 10px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: var(--dark);
          border: 1px solid transparent;
          transition: all .3s ease;
          cursor: pointer;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:hover {
            border: 1px solid white;
          }

          &.active {
            border: 1px solid white;
          }
        }

        .ant-steps-item-finish .ant-steps-item-icon {
          border-color: orange;
        }

        .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
          color: orange;
        }
      }

      .task_content {
        background-color: #343843;
        padding: 20px;
        border-radius: 16px;

        .player-wrapper {
          position: relative;
          margin-top: 10px;
          padding-top: 56.25%
        }

        .react-player {
          position: absolute;
          top: 0;
          left: 0;
        }

        .description {
          color: var(--text_color_dark);
          white-space: break-spaces;
          font-size: 20px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        }

        video,
        iframe {
          max-height: 600px;
          border-radius: 15px;
        }
      }

      .code_block {
        padding: 20px;
        background-color: #343843;
        border-radius: 16px;
      }

      .h4 {
        font-size: 24px;
        color: white;
      }

      .text {
        color: #A2AACA;
        font-size: 20px;
        margin-bottom: 0
      }

      .url_title {
        font-size: 24px;
        margin-top: 5px;

      }

      .url_ref {
        font-size: 20px;
        color: var(--text_color_dark);

        a {
          color: #3077FF;
        }
      }

      .comment_title {
        margin: 20px 0 0px 0;
        color: white;
        font-size: 24px;
        line-height: 29px;
      }

      .comment_input {
        position: relative;

        .input_classic {
          border-radius: 15px;
          padding: 9px 50px 9px 20px;

          &::placeholder {
            font-size: 16px;
            line-height: 19px;
            color: #757A88;
          }
        }

        img {
          position: absolute;
          top: 0px;
          right: 0px;
          z-index: 4;
          cursor: pointer;
        }
      }

      .comment {
        padding: 7px 30px 30px 53px;
        margin-top: 15px;
        margin-bottom: 20px;
        position: relative;
        border-radius: 15px;
        background: #343843;

        &_photo {
          position: absolute;
          top: 5px;
          left: 5px;
        }

        &_fio {
          margin-bottom: 3px;
          font-size: 14px;
          line-height: 17px;
        }

        &_date {
          margin-bottom: 14px;
          font-size: 12px;
          line-height: 14px;
          color: #474C5A;
        }

        &_text {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid var(--background_lighten-dark);
          font-size: 16px;
          line-height: 19px;
        }

        &_like-dislike {
          display: flex;

          span {
            margin-right: 10px;
            font-size: 14px;
            line-height: 17px;
            cursor: pointer;
          }

          svg {
            margin-right: 5px;
            align-self: center;
            cursor: pointer;
          }
        }

        &_remove {
          position: absolute;
          display: flex;
          justify-content: center;
          top: 10px;
          right: 8px;
          width: 20px;
          height: 20px;
          background: #FF3434;
          border-radius: 13px;
          cursor: pointer;

          svg {
            align-self: center;
          }
        }
      }

      iframe,
      video {
        border-radius: 10px;
      }

      // antd styles
      .greenStep.ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-icon {
        background-color: #30E0A1;
        border-color: #30E0A1;
      }

      .ant-steps-item-description {
        margin-top: 5px;
        color: white;
      }

      .ant-steps-item-icon {
        background-color: var(--dark);
        border-color: var(--dark);

        span {
          color: white;
        }
      }

      .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
        border-color: white;

        span {
          color: white;
        }
      }

      .ant-steps-item-icon .ant-steps-icon {
        top: -0.7px;
      }

      .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-card {
        margin-top: 0;
      }

      .ant-upload-list-item-info {
        background-color: transparent !important;

        span,
        a {
          color: var(--text_color_dark);
        }
      }

      .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: var(--text_color_dark) !important;
      }

      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: var(--text_color_dark) !important;
      }
    }
  }
}
