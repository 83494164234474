@media (max-width: 820px) {
  .sidebar_mobile {
  display: block !important;
  }
}
@media (max-width: 376px) {
  .sidebar_mobile {
    .demo_row-mobile {
      bottom: 55px !important;
    }

    .group_mobile {
      flex-direction: column !important;
      height: auto !important;
      background-color: transparent !important;
      border-color: transparent !important;

      div {
        background: var(--background_lighten-dark) !important;
        border: 1px solid var(--text_color_dark) !important;
        border-radius: 15px !important;

        &:not(:first-child) {
          margin-top: 5px !important;
        }

        &.active {
          background: #3077FF !important;
          color: white !important;
        }
      }
    }
  }
}

.sidebar_mobile {
  display: none;
  position: fixed;
  bottom: 10px;
  opacity: 0;
  width: calc(100% - 30px);
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  transition: all .5s;

  .demo_row-mobile {
    position: fixed;
    bottom: 60px;
    left: 50%;
    width: 100%;
    opacity: 0;
    transform: translate(-50%, 0);
    z-index: -1;
    transition: all .5s;

    .group_mobile {
      display: flex;
      width: 100%;
      height: 25px;
      align-self: end;
      background: var(--background_lighten-dark);
      border: 1px solid var(--text_color_dark);
      border-radius: 15px;

      div {
        width: 100%;
        text-align: center;
        transition: all .7s ease;
        white-space: nowrap;
        color: white;
        cursor: pointer;

        &.active {
          background: #3077FF;
          border-radius: 26px;
          color: white;
        }
      }
    }

    &.open {
      transition: all .5s;
      z-index: 3;
      opacity: 1;
    }
  }

  .bar_mobile {
    margin-top: 10px;
    display: flex;
    padding: 6px;
    justify-content: space-between;
    background: #343843;
    border-radius: 16px;

    img:nth-child(2) {
      border-radius: 12px;

      &.active {
        border: 1px solid #3077FF;
        filter: drop-shadow(0px 9px 10px rgba(48, 119, 255, 0.27));
      }
    }

    svg {
      align-self: center;

      &.active {
        filter: drop-shadow(0px 10px 14px rgba(48, 119, 255, 0.4));

        rect:first-child {
          fill: #3077FF;
        }
      }
    }

    img,
    svg {
      cursor: pointer;
    }

    .history_pay-svg,
    .academic-plan-svg {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      width: 38px;
      height: 38px;
      background: #201E26;
      border-radius: 12px;
      transition: all .5s ease;

      svg {
        margin-bottom: 0;
        min-width: 36px;
        min-height: 40px;
      }

      &.active {
        background-color: #3077FF;
        filter: drop-shadow(0px 10px 14px rgba(48, 119, 255, 0.4));
      }
    }
    .academic-plan-svg {
      svg {
        min-width: 16px;
        min-height: 21px;
        width: 18px;
        height: 23px;
      }
    }
  }

  .notification_svg {
    position: relative;
    max-width: 38px;
    max-height: 38px;
    min-width: 38px;
    min-height: 38px;
    background: rgba(162, 170, 202, 0.2);
    border-radius: 16px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 12px;
      max-height: 15px;
      min-width: 12px;
      min-height: 15px;
    }

    .count_notification {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      top: -5px;
      right: -2px;
      font-size: 8px;
      line-height: 10px;
      border-radius: 50%;
      background-color: #FF5937;
      color: white;
    }

    &.active {
      background: rgba(48, 119, 255, 0.2);
    }
  }

  &.active {
    transition: all .5s;
    opacity: 1;
    z-index: 5;
  }
}