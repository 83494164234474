@import 'ReuseComponents';
@import '../pages/Profile/Profile';
@import '../pages/MyCourse/MyCourse';
@import '../pages/CourseManage/CourseManage';
@import '../pages/CourseManage/ChangeContent/ChangeLessonParent';
@import '../pages/CourseManage/ViewContent/ViewLessonParent';
@import '../pages/CurrentCourse/CurrentCourse';
@import '../pages/Login/Login';
@import '../pages/Register/Register';
@import '../pages/CheckWork/CheckHomeWork';
@import '../pages/CheckTest/CheckTest';
@import '../pages/GroupsList/GroupsList';
@import '../pages/InnerRequestMember/InnerRequestMember';
@import '../pages/Notification/Notification';
@import '../pages/ViewItemJournal/ViewItemJournal';
@import '../pages/PaymentRespStore/PaymentResp';
@import '../pages/InfoStudents/StudentCard/StudentCard';
@import '../pages/GroupsList/StudentHomework/StudentHomework';
@import '../pages/CheckCodeWork/CheckCode';
@import '../pages/HistoryPay/HistoryPay';
@import '../pages/Modules/Modules';
@import '../pages/LandingNew/LandingNew';

@media (max-width: 300px) {
  .app {
    padding: 0 !important;
  }
}

:root {
  --full-height: 100vh;
  --dark: #343843;
  --background_lighten-dark: #474c5a;
  --text_color_dark: #A2AACA;
  --blue: #3077FF;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(rgba(162, 170, 202, 0), rgba(128, 137, 158, 1));
  border-radius: 9em;
}

::selection {
  background: grey; /* Safari */
}

::-moz-selection {
  background: grey; /* Firefox */
}

ul, li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.app {
  overflow-y: auto;
  background: #16151B;
  overflow-x: hidden;

  &.overflow_hidden {
    overflow-y: hidden;
  }
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.introjs {
  &-overlay {
    display: none;
  }

  &-helperLayer {
    z-index: 0;
  }

  &-arrow.left {
    border-right-color: var(--blue);
  }

  &-arrow.right {
    border-left-color: var(--blue);
  }

  &-arrow.top {
    border-bottom-color: var(--blue);
  }

  &-arrow.bottom {
    border-top-color: var(--blue);
  }


  &-tooltipbuttons {
    border-top-color: transparent;

    a {
      border-radius: 15px;
      color: white;
      border-color: #474C5A;
      background: transparent;
      text-shadow: none;
      transition: all .3s linear;

      &:hover {
        color: white;
        border-color: #3077FF;
        background: #3077FF;
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
        text-shadow: none;
      }

      &:focus {
        background: #3077FF;
        border-color: #3077FF;
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
        text-shadow: none;
        color: white;
      }
    }
  }

  &-tooltiptext {
    margin: 0 auto;
    padding: 0 0 20px 0;
    max-width: 210px;
    border-bottom: 1px solid #474C5A;
  }

  &-tooltip {
    background: rgba(30, 31, 38, 0.8);
    border: 1px solid #1E1F26;
    border-radius: 15px 24px 24px 24px;
    font-size: 13px;
    line-height: 16px;
    color: white;

    .introjs-skipbutton {
      color: white;
    }
  }
}

.introjs-tooltipReferenceLayer {
  height: auto !important;
}

.introjs-helperLayer {
  height: auto !important;
}

.ant-modal {
  color: white;
}

.ant-message {
  z-index: 10000000200;
}

.ant-popover-inner,
span.ant-popover-arrow-content {
  user-select: none;
  background-color: #474c5a;

  .ant-popover-message-title {
    color: #A2AACA;
  }

  button.ant-btn.ant-btn-sm {
    color: #474c5a;

    &.ant-btn-primary {
      background-color: #3077FF;
      border-color: transparent;
      color: white;
    }

    &:hover {
      border-color: #A2AACA;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}