@media (max-width: 1550px) {
  .modules {
    margin-left: 20px !important;
  }
}

@media (max-width: 1420px) {
  .modules {
    .inner_block {
      margin-right: 30px !important;

      .module_list {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 820px) {
  .modules {
    margin-left: 0 !important;

    .inner_block {
      margin-right: 0 !important;
    }
  }
}

@media (max-width: 720px) {
  .modules {
    .title {
      font-size: 24px !important;
      line-height: 24px !important;

    }
  }
}

.modules {
  display: flex;
  width: 100%;
  color: white;
  position: relative;

  .inner_block {
    width: 100%;
  }

  .title {
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 38px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--background_lighten-dark);
  }

  .module_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    li {
      padding: 15px;
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: column;
      min-width: 297px;
      width: calc(25% - 30px);
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 22px;

      .module_image {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        min-height: 40px;
        background: #201E26;
        border-radius: 13px;
      }

      .module_name {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }

      .module_info {
        margin-bottom: 13px;
        height: 100%;
        line-height: 17px;
        color: #757A88;
      }

      .module_cost {
        margin: auto 20px 10px 0;
        display: flex;
        align-items: center;
        font-size: 16px;

        div {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
            height: 23px;
            color: #30E0A1;
            white-space: nowrap;
          }
        }
      }

      .btn {
        margin-top: auto;
        padding: 6px 7px 8px 7px;
        width: 100%;
        text-align: center;
        color: white;
        background: #3077FF;
        box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
        border-radius: 16px;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

.modal_module {
  padding-bottom: 0;
  max-width: 716px;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white;

  .close_modal {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }

  .ant-modal-content,
  .ant-modal-body {
    background: #201E26;
    border-radius: 40px;
    box-shadow: none;

    .module_icon {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: var(--dark);
      border-radius: 13px;
    }

    .h2 {
      margin-bottom: 15px;
      font-size: 40px;
      line-height: 48px;
    }

    .module_image {
      margin-bottom: 15px;
      width: 100%;

      img {
        max-width: 655px;
        width: 100%;
        max-height: 360px;
        border-radius: 20px;
      }
    }

    .h3 {
      margin-bottom: 15px;
      font-size: 20px;
      line-height: 24px;
    }

    .detail {
      padding: 1px;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 19px;
      color: var(--text_color_dark);
      white-space: pre-wrap;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    .duration {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
          color: var(--text_color_dark);
        }
      }
    }

    .groups_btn-provide {
      display: flex;
      align-items: center;
      user-select: none;
    }

    .btn_provide {
      margin: 0 auto;
      padding: 10px 15px 11px 15px;
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      text-align: center;
      background: #3077FF;
      box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
      border-radius: 16px;
      cursor: pointer;
      user-select: none;

      &.pay_subscription {
        margin-left: 15px;
        background: #30E0A1;
        box-shadow: 0px 9px 20px rgba(48, 224, 161, 0.2);
      }
    }
  }

  .ant-modal-body {
    padding: 30px;
  }
}

@media (max-width: 780px) {
  .modal_module {
    width: auto !important;
    margin-left: 15px !important;
    margin-right: 15px !important;

    .h2 {
      font-size: 26px !important;
      line-height: 26px !important;
    }
  }
}

@media (max-width: 550px) {
  .modal_module {
    .duration {
      flex-wrap: wrap;

      span:first-child {
        white-space: nowrap;
      }
    }

    .groups_btn-provide {
      flex-direction: column;

      .btn_provide.pay_subscription {
        margin-left: 0 !important;
        margin-top: 15px !important;
      }
    }
  }
}