@media (max-width: 1470px) {
  .modal_filter {
    width: calc(100% - 30px) !important;
  }
}

@media (max-width: 1310px) {
  .search_profession-parent-skillset {

    .main {
      margin-left: 15px !important;
    }

    .vacancies_title.active {
      top: 0 !important;
    }

    .vacancies_title-text {
      top: 7px !important;
    }

    .vacancies_block {
      max-width: 280px !important;
    }

    .vacancies_info-block {
      margin-left: 0 !important;
      // min-width: 405px !important;
    }

  }

  .filters {
    /* padding-bottom: 63px !important;
     max-height: 102px !important;*/

    .filter,
    .favorites {
      width: -webkit-fill-available;
      text-align: center;

      span {
        vertical-align: sub;
      }
    }

    .search_profession {
      position: absolute !important;
      margin-left: 0 !important;
      top: 50px;
      left: 0;
    }

    .ant-select-selector {
      // margin-left: 10px !important;
      height: 38px !important;
    }

    .regionSelect {
      /*margin-left: 12px !important;*/
    }

    &.hide {
      padding-bottom: 0 !important;
      max-height: 0 !important;
    }
  }

  .modal_filter {

    .title {
      padding-bottom: 15px !important;
      margin-bottom: 15px !important;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .h1 {
      font-size: 28px !important;
      line-height: 29px !important;
    }

    .parent {
      margin-bottom: 15px !important;
      flex-direction: column !important;

      &.helpfull_course {
        margin-bottom: 0 !important;
        flex-direction: row !important;
      }
    }

    .left_block {
      margin-bottom: 15px !important;
      width: auto !important;
      max-width: none !important;
      white-space: nowrap !important;
      font-size: 18px !important;
      line-height: 21px !important;
    }
  }

  .modal_favorite {
    width: auto !important;
  }
}

@media (max-width: 1020px) {
  .title_vacancy {
    span {
      max-width: 400px !important;
    }
  }
}

@media (max-width: 1010px) {
  .search_profession-parent-skillset {

    .vacancies_block,
    .vacancies_info-block {

      /* display: none !important;*/
      .h1 {
        max-width: 120px !important;
        font-size: 24px !important;
        line-height: 24px !important;
      }

      .danger_skill {
        .skill {
          white-space: normal !important;
          border-radius: 16px !important;


        }
      }

      .h2 {
        max-width: 120px !important;
        font-size: 16px !important;
        line-height: 16px !important;
      }

      .tags_block {
        padding: 10px !important;
      }
    }
  }
}

@media (max-width: 990px) {
  .search_profession-parent-skillset {
    margin: auto !important;
    padding: 0 !important;

    .main {
      display: none !important;
    }

    .title_vacancy {
      span {
        max-width: 300px !important;
      }
    }

    .mobile_block {
      display: block !important;
    }

    .middle_catalog {
      padding-bottom: 130px !important;
    }

    .footer_bar {
      display: block !important;
      max-width: calc(100% - 30px) !important;
      width: 100% !important;
      left: 50% !important;
      transform: translate(-50%, 0) !important;
      bottom: 5px !important;
    }
  }

  .modal_filter {
    .ant-modal-body {
      padding: 15px !important;
      border-radius: 20px !important;

      .close_icon {
        top: 20px !important;
      }
    }

    .left_block-filter {
      font-size: 18px !important;
      line-height: 21px !important;
      white-space: nowrap;
    }
  }

  .modal_favorite {
    .title {
      padding-bottom: 5px !important;
      font-size: 24px !important;
      line-height: 29px !important;

      .close_icon {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .category {
      display: none !important;
    }

    .category_mobile {
      display: block !important;
    }

    .list_category-mobile {
      display: block !important;
    }

    .list_category {
      display: none !important;
    }

    .active_vacancy-title {
      margin-bottom: 10px !important;
      font-size: 28px !important;
      line-height: 28px !important;
    }

    .active_vacancy-popular {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 820px) {
  .ant-modal.modal_filter {
    margin-bottom: 150px !important;

    .title {
      div:nth-child(2) {
        top: 20px !important;
      }
    }

    .right_block-filter {
      margin-left: 0 !important;
      margin-top: 10px !important;
      width: 100% !important;
    }
  }
}

@media (max-width: 620px) {
  .footer_bar {
    width: 460px;
  }
}

@media (max-width: 430px) {
  .modal_course {
    .reuse_course-item {
      margin: 0 auto 10px auto !important;
    }
  }
}

.search_profession-parent-skillset {
  display: flex;
  width: 100%;
  height: 100%;
  background: none;
  color: white;

  .main {
    margin-left: 30px;
    //max-width: 960px;
    position: relative;
    width: calc(100% - 404px);

    .vacancies_title-row {
      margin-bottom: 28px;
      position: relative;
      display: flex;
      justify-content: space-between;
      transition: all .3s ease;

      .vacancies_title {
        padding-bottom: 100px;
        position: relative;
        height: 45px;
        color: white;
        font-size: 16px;
        background-color: #16151B;
        transition: all .3s ease;

        img {
          margin-right: 15px;
        }

        &-text {
          position: absolute;
          top: 7px;
          left: 0;
          color: white;
          font-size: 16px;

          span {
            white-space: nowrap;
            vertical-align: middle;
          }
        }
      }

      .filters {
        margin-top: 6px;
        display: flex;
        align-items: center;
        position: relative;
        max-height: 59px;
        transition: all .3s ease;

        .filter,
        .favorites {
          position: relative;
          padding: 4px 32px 4px 40px;
          border-radius: 17px;
          height: 38px;
          white-space: nowrap;
          cursor: pointer;

          img {
            position: absolute;
            top: 4px;
            left: 5px;
          }

          span {
            vertical-align: sub;
          }
        }

        .filter {
          background-color: #8146FF;

          .svg {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 5px;
            left: 5px;
            min-width: 30px;
            min-height: 30px;
            max-width: 30px;
            max-height: 30px;
            border-radius: 13px;
            background-color: #27292F;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              min-width: 13px;
              min-height: 13px;
              max-width: 13px;
              max-height: 13px;
            }
          }

          &.disabled {
            background-color: grey;
            cursor: auto;
          }
        }

        .favorites {
          padding-right: 7px;
          margin: 0 12px;
          position: relative;
          background-color: #FFD23B;

          .svg {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 4px;
            left: 5px;
            min-width: 30px;
            min-height: 30px;
            max-width: 30px;
            max-height: 30px;
            border-radius: 13px;
            background-color: #27292F;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              min-width: 13px;
              min-height: 12px;
              max-width: 13px;
              max-height: 12px;
            }
          }
        }

        .select_language {
          position: relative;

          .img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
            z-index: 1;
          }
        }

        .regionSelect {
          width: 100%;
          min-width: 200px;
          display: flex;
          align-items: center;
          height: 38px;

          &::after {
            content: url('../../../images/arrow.svg');
            position: absolute;
            top: 7px;
            right: 10px;
          }

          &.active {
            .ant-select-selector {
              border-radius: 16px 16px 0 0 !important;
            }

            &::after {
              content: url('../../../images/arrow.svg');
              position: absolute;
              top: 9px;
              right: 10px;
              transform: rotate(180deg);
            }
          }
        }

        .ant-select-selector {
          padding-left: 35px;
          align-items: center;
          height: 38px;
          border-color: transparent !important;
          background-color: rgba(162, 170, 202, 0.2) !important;
          color: white;
          box-shadow: none !important;
          height: -webkit-fill-available;
          border-radius: 16px;

          input {
            padding-left: 25px;
            padding-top: 7px;
          }

          &:hover,
          &:active,
          &:focus {
            border-color: #A2AACA !important;
            box-shadow: none !important;
          }

          span.ant-select-selection-item {
            color: white !important;
          }
        }

        svg {
          position: absolute;
          top: 17px;
          right: 10px;

          &.active {
            transform: rotate(180deg);
          }
        }

        &.hide {
          transition: all .3s ease;
          max-height: 0;
          opacity: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      &.hide {
        transition: all .3s ease;

        .vacancies_title {
          transition: all .3s ease;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          mask: linear-gradient(#fff, transparent);
          z-index: 2;
          opacity: 1;
        }

        .filters {
          transition: all .3s ease;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
          opacity: 0;
        }
      }
    }

    .middle_block {
      display: flex;
      margin-top: -50px;
      //max-width: 960px;
      width: 100%;
      position: relative;
      z-index: 1;
      /*overflow: hidden;*/
      transition: all .3s ease;

      ul,
      li {
        padding: 0;
        margin: 0;
      }

      .vacancies_block {
        margin-top: -10px;
        margin-right: 5px;
        position: relative;
        max-width: 345px;
        width: 100%;
        overflow-y: scroll;

        li {
          padding: 15px 15px 8px 15px;
          display: flex;
          flex-direction: column;
          background: var(--dark);
          border: 1px solid transparent;
          border-radius: 18px;
          transition: all .3s ease;
          cursor: pointer;

          .border {
            margin: 0 10px;
            width: 1px;
            background-color: #4E535F;
          }

          .title_vacancy {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #27292F;

            span {
              padding-right: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            svg {
              min-width: 14px;
              min-height: 14px;

              &.active {
                path {
                  fill: #FFD23B;
                }
              }

              &:hover {
                path {
                  fill: #FFD23B;
                }
              }
            }
          }

          .compensation {
            padding-top: 10px;
            display: flex;

            span:first-child {
              width: 50%;
            }

            span:last-child {
              max-width: 50%;
              margin-left: 10px;
            }

            .border {
              margin: 0;
            }
          }

          img {
            margin-left: auto
          }

          svg,
          img {
            align-self: center;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:last-child {
            margin-bottom: 50px;
          }

          &:hover {
            background-color: var(--dark);
            border: 1px solid #757A88;
          }

          &.active {
            background: #474C5A;
            border: 1px solid #A2AACA;
            box-shadow: 0 20px 60px rgba(0, 0, 0, 0.8);
          }
        }

        &.introjs-showElement {
          .list_vacancy {
            position: relative;
          }
        }

        &.active {
          max-height: 610px;
        }

        &.opacity {
          opacity: 0.4;
        }
      }

      .vacancies_info-block {
        margin: -10px 0 0 10px;
        position: relative;
        //max-width: 605px;
        width: calc(100% - 345px);
        max-height: 563px;
        overflow: auto;
        color: white;

        .profession_section {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          padding: 15px 10px;
          margin-bottom: 15px;
          background: #201E26;
          border-radius: 20px;
          opacity: 0;
          visibility: hidden;
          transition: all .3s linear;

          .description {
            margin-bottom: 15px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--background_lighten-dark);
          }

          .h1 {
            padding-right: 80px;
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 21px;
          }

          .h2 {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 24px;
          }

          .group_icons {
            position: absolute;
            display: flex;
            top: 40px;
            right: 30px;

            div {
              position: relative;
              width: 30px;
              height: 30px;
              background: var(--dark);
              box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              cursor: pointer;

              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 18px;
                min-height: 18px;
                max-width: 18px;
                max-height: 18px;
                transform: translate(-50%, -50%);
              }

              &:first-child {
                margin-right: 15px;
              }

              &:last-child {

                &.active,
                &:hover {
                  svg {
                    path {
                      fill: #FFD23B;
                    }
                  }
                }
              }
            }
          }

          .tags_block {
            padding: 10px 10px 5px 10px;
            border-radius: 12px;
            visibility: hidden;

            .h2 {
              margin-bottom: 10px;
              font-size: 16px;
              line-height: 19px;
            }

            .danger_skill {
              margin-bottom: 0;
            }
          }

          &.opacity {
            opacity: 0.4 !important;
          }

          &.active {
            position: relative;
            opacity: 1;
            visibility: initial;
            transition: all .3s linear;

            .tags_block {
              visibility: initial;
            }
          }
        }

        .vacancy_section {
          position: absolute;
          padding: 20px;
          top: 0;
          right: 0;
          width: 100%;
          background: var(--background_lighten-dark);
          border-radius: 20px;
          opacity: 0;
          visibility: hidden;
          color: white;
          transition: all .4s linear;

          .description {
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #757A88;
          }

          .group_icons {
            position: absolute;
            display: flex;
            top: 38px;
            right: 30px;

            div {
              position: relative;
              width: 30px;
              height: 30px;
              background: #201E26;
              box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
              border-radius: 10px;
              cursor: pointer;

              svg,
              img {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 18px;
                min-height: 18px;
                max-width: 18px;
                max-height: 18px;
                transform: translate(-50%, -50%);
              }

              img {
                min-width: 20px;
                min-height: 15px;
                max-width: 20px;
                max-height: 15px;
              }

              &:first-child {
                margin-right: 15px;
                border: 1px solid var(--dark);
                background-color: transparent;
              }

              &:last-child {

                &.active,
                &:hover {
                  svg {
                    path {
                      fill: #FFD23B;
                    }
                  }
                }
              }
            }
          }

          .h1 {
            margin-bottom: 10px;
            padding-right: 85px;
            font-size: 18px;
            line-height: 21px;
          }

          .tags_block {
            margin-bottom: 10px;
            padding: 10px 10px 5px 10px;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
            visibility: hidden;
            border-radius: 12px;

            .danger_skill {
              margin-bottom: 0;
            }

            &::-webkit-scrollbar-thumb {
              background: var(--dark) !important;
            }
          }

          p {
            margin: 20px 0;
            font-size: 16px;
            line-height: 19px;
          }

          .btn_go {
            margin-top: 30px;
            padding: 7px;
            text-align: center;
            color: white;
            background: #3077FF;
            box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
            border-radius: 12px;
            cursor: pointer;
          }

          &.active {
            position: relative;
            opacity: 1;
            visibility: initial;
            transition: all .4s linear;

            .tags_block {
              visibility: initial;
            }
          }

          &.opacity {
            background: rgba(71, 76, 90, 0.4);

            .h1,
            .group_icons,
            .intro_,
            .btn_go,
            .tags_block,
            p,
            .analytics_block-vertical {
              opacity: 0.4;
            }
          }

          &.current_intro {
            .intro_, .tags_block {
              opacity: 1 !important;
            }
          }
        }

        &.opacity {
          opacity: 0.4;
        }

        &.hide_scroll {
          margin: 0 0 0 10px;
        }
      }

      &.active {
        margin-top: 11px;
      }

      &.z_index_0 {
        z-index: 0;
      }
    }

    .no_data {
      margin-top: 90px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      color: white;
      font-size: 24px;
    }
  }
}

.mobile_block {
  padding: 0 0 70px 0;
  margin: 0 auto;
  display: none;
  width: 100%;

  .top_filter {
    padding: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    background: var(--dark);
    border-radius: 34px;
    transition: all .2s ease;

    .icon {
      display: flex;
    }

    .filters_mobile {
      display: flex;
      margin: 0 auto;
      align-self: center;
      transition: all .2s ease;

      div:not(:first-child) {
        margin-left: 5px;
      }
    }

    .settings {
      align-self: center;
    }

    &.hidden {
      .filters_mobile {
        display: none;
        height: 0;
        transform: scale(0);
      }
    }
  }

  .search_mobile {
    position: relative;
    display: none;
    border-radius: 14px;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    transition: all .3s ease;

    img {
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 4;
    }

    .input_search-mobile {
      padding-left: 35px;
      background: rgba(161, 163, 178, 0.2);
      border: 1px solid #A2AACA !important;

      &:hover {
        //border: 1px solid #A2AACA !important;
      }
    }

    span {
      margin-left: 15px;
      align-self: center;
      font-size: 14px;
      color: white;
      cursor: pointer;
    }

    &.active {
      display: flex;
      align-self: center;
      max-height: 34px;
      max-width: fit-content;
      opacity: 1;
    }
  }

  .middle_catalog {
    .title_catalog {
      display: flex;

      img {
        margin-right: 15px;
        align-self: center;
      }

      span {
        font-size: 16px;
        align-self: center;
      }
    }

    .list_catalog {
      margin-top: 15px;

      ul {
        li {
          display: flex;
          padding: 6px 15px;
          //  min-width: 310px;
          background-color: var(--dark);
          border-radius: 18px;
          border: 1px solid #474C5A;
          cursor: pointer;

          svg {
            align-self: center;

            &.active {
              path {
                fill: #FFD23B;
              }
            }

            &:not(:last-child) {
              &:hover {
                path {
                  fill: #FFD23B;
                }
              }
            }
          }

          .border {
            margin: 0 10px;
            display: inline-block;
            height: 24px;
            width: 1px;
            align-self: center;
            background-color: #4E535F;
          }

          span {
            margin-right: 15px;
            padding: 5px 0;
            display: inline-block;
            max-width: 160px;
            width: 160px;
            align-self: center;
            white-space: nowrap;
            overflow: hidden;
            /* Обрезаем все, что не помещается в область */
            text-overflow: ellipsis;
          }

          .group_progress {
            margin-left: auto;
            white-space: nowrap;

            .into_icon {
              margin-right: 10px;
              z-index: 4;
              vertical-align: middle;

              &.active {
                path {
                  fill: var(--dark);
                }
              }

              &:hover {
                path {
                  fill: #757A88 !important;
                }
              }
            }
          }

          span.ant-progress-text {
            font-size: 10px;
            color: white !important;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:hover {
            border: 1px solid #757A88;
          }

          &.active {
            background: #474C5A;
            border: 1px solid #A2AACA;
          }
        }
      }

      .pagination_all {
        margin: 5px auto;

        .ant-pagination {
          display: flex;
        }

        .ant-pagination-item {
          padding: 2px 3px;
          margin-right: 5px;
          background-color: transparent;
          border: none;

          a {
            border-radius: 8px !important;
            color: white;
            background-color: var(--dark);
            padding: 0 10px;
          }

          &-active {
            a {
              background-color: lighten(#343843, 20%);
            }

            border-color: transparent;
          }
        }

        li.ant-pagination-options {
          display: none;
        }

        li.ant-pagination-prev,
        li.ant-pagination-next {
          display: none;
        }

        li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
          padding: 0;
          background-color: transparent;
          border: none;
        }

        span.ant-pagination-item-ellipsis,
        span.anticon.anticon-double-right.ant-pagination-item-link-icon,
        span.anticon.anticon-double-left.ant-pagination-item-link-icon {
          color: #61687c;
          max-width: 30px;
          background-color: transparent !important;
          padding: 0;
        }

        li.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
          padding: 0;
          background-color: transparent;
          border: none;
        }
      }
    }

    .prev_button {
      display: flex;
      padding: 4px 15px;
      margin-bottom: 10px;
      width: fit-content;
      background: #343843;
      border-radius: 20px;

      svg {
        margin-right: 10px;
        align-self: center;
        transform: rotate(90deg);
      }
    }

    .slider_section {
      margin: 15px auto;
      width: 100%;

      .vacancy {
        padding: 10px 12px;
        position: relative;
        max-width: 110px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #1D1E25;
        border: 1px solid transparent;
        border-radius: 16px;
        color: white;
        transition: all .3s linear;

        .favorite {
        }

        .title_vacancy {
          padding-bottom: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #343843;

          span {
            margin-right: 5px;
            max-width: 70px;
            width: 100%;
            font-size: 13px;
            line-height: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          svg {
            &.active {
              path {
                fill: #FFD23B;
              }
            }
          }
        }

        .salary {
          margin-bottom: 10px;
          width: 100%;
          font-size: 14px;
          line-height: 17px;
        }

        .bottom_row {
          margin-top: auto;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .company {
            max-width: 70px;
            margin-right: 4px;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .redirect_btn {
          }
        }

        &.active {
          border-color: #9197A8;
        }
      }

      .slick-track {
        transform: translate3d(0, 0, 0);
      }
    }

    .block-profession {
      padding: 15px;
      background: #201E26;
      border-radius: 20px;

      .h1 {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 29px;
        color: white;
      }

      .h2 {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 19px;
      }

    }

    .vacancy_info {
      margin: 0 auto 10px auto;
      padding: 20px 12px 10px 12px;
      width: 100%;
      max-width: 360px;
      background: #343843;
      border-radius: 24px;

      .title_vacancy {
        padding-bottom: 15px;
        margin-bottom: 20px;
        max-width: 330px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        line-height: 19px;
        border-bottom: 1px solid #474C5A;
      }

      .tags_block {
        padding: 0;
        border: none;
      }

    }

    .no_data {
      margin: 0 auto;
      width: fit-content;
      color: var(--text_color_dark);
    }

    .spin {
      position: relative;
      width: 100%;
      margin: 20px 0;
    }
  }

  .show_traektory {
    margin-bottom: 10px;
    padding: 5px;
    background: #27292F;
    box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.6);
    border-radius: 20px;

    .btn {
      padding: 6px;
      text-align: center;
      font-size: 13px;
      background: #3077FF;
      box-shadow: 0 9px 10px rgba(48, 119, 255, 0.27);
      border-radius: 24px;
      cursor: pointer;
      transition: all .3s linear;
    }

    &.sticky {
      position: sticky;
      top: 5px;
      z-index: 10;
    }
  }
}

.modal_traektory-mobile {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  background: #27292F;
  box-shadow: 0 -5px 40px rgba(0, 0, 0, 0.6);
  border-radius: 20px;

  .title {
    display: flex;

    img {
      align-self: center;
    }

    span {
      margin-left: 8px;
      font-size: 18px;
      align-self: center;
      color: white;
    }
  }

  .study_traektory {
    position: relative;
    margin-top: 15px;

    .input_classic {
      padding-left: 5px;
      border-radius: 16px;
      background-color: var(--background_lighten-dark);
      box-shadow: none !important;

      .ant-select-selector {
        padding: 18px 0;
        background-color: var(--background_lighten-dark) !important;
        border-color: transparent !important;
        box-shadow: none !important;
        border-radius: 16px;

        .ant-select-selection-placeholder {
          position: absolute;
          top: 2px;
          left: 10px;
          color: white;
          font-size: 14px;
        }

        &:active,
        &:focus,
        &:hover {
          border: 1px solid #343843;
        }
      }
    }

    img {
      position: absolute;
      top: 4px;
      right: 3px;
      z-index: 3;
    }

    .down_block-traektory {
      margin-top: -28px;
      padding: 30px 10px 0 10px;
      border: 1px solid #474C5A;
      border-radius: 0 0 24px 24px;
      background-color: var(--dark);

      .block_tags {
        display: flex;
        flex-wrap: wrap;
      }

      .tag {
        padding: 2px 30px 4px 15px;
        margin: 0 5px 10px 0;
        position: relative;
        display: flex;
        align-items: center;
        width: -moz-fit-content;
        width: fit-content;
        background: rgba(22, 21, 27, 0.65);
        border: 1px solid transparent;
        border-radius: 29px;
        transition: all 0.3s linear;
        color: white;
        font-size: 12px;

        img {
          top: 2px;
          right: 2px;
        }
      }
    }
  }

  .button_classic {
    margin-top: 15px;
    padding: 6px;
    text-align: center;
    box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
    border-radius: 20px;
  }

  .close {
    margin-top: 15px;
    font-size: 14px;
    color: white;
    text-align: center;
  }

  .buttons {
    position: relative;
    z-index: 1;

    .btn_search-course {
      padding: 3px;
      text-align: center;
      box-shadow: none;
      width: 100%;
      border: 1px solid #757A88;
      background: transparent;
      border-radius: 20px;
      cursor: pointer;
      color: white;
      transition: all .3s linear;
      z-index: 1;

      &:hover {
        background: #3077FF;
      }

      &:first-child {
        margin: 15px 0;
      }
    }
  }
}

.modal_settings-mobile {
  background: #343843;
  border-radius: 16px;

  .title {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #474C5A;

    .group_left {
      display: flex;

      img {
        margin-right: 10px;
        align-self: center;
      }

      span {
        font-size: 18px;
        color: white;
      }
    }

    .close {
      align-self: center;

      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }

  }

  .list {
    min-height: 200px;
    font-size: 16px;
    line-height: 19px;
    color: #757A88;
    cursor: pointer;

    div {
      margin-top: 20px;

      &.active {
        color: lighten(#757A88, 25%);
      }
    }
  }
}

.rc-virtual-list {
  background-color: transparent !important;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  padding: 0;
  background-color: var(--dark);

  div {
    color: white;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background-color: lighten(rgba(162, 170, 202, 0.2), 5%);
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: var(--background_lighten-dark);
    color: white;
  }
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ant-modal-content {
  background-color: transparent !important;
  box-shadow: none;
}