@media (max-width: 540px) {
  .notification {
    .inner_block {
      .top {
        padding-bottom: 15px !important;
        margin-bottom: 15px !important;
        flex-direction: column !important;

        span {
          font-size: 24px !important;
          line-height: 24px !important;
          text-align: center;
        }

        .group {
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }

    .notification_items {
      margin-right: 5px !important;

      .item {
        flex-direction: column !important;

        .type {
          margin-right: 0 !important;
          max-width: 100% !important;
          width: 100% !important;
        }

        .date {
          align-self: center !important;
        }

        .border {
          display: none !important;
        }

        .message {
          margin-right: 0 !important;
          text-align: center !important;
        }

        .btn {
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
}

.notification {
  display: flex;
  width: 100%;
  color: white;

  .inner_block {
    position: relative;
    width: 100%;

    .top {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--background_lighten-dark);

      .block {
        display: flex;
        flex-direction: column;

        .btn_prev {
          padding: 1px 15px 2px 30px;
          margin-top: 17px;
          margin-bottom: 10px;
          height: 25px;
          width: fit-content;
          position: relative;
          background: var(--dark);
          border-radius: 20px;

          svg {
            position: absolute;
            top: 9px;
            left: 12px;
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
      }

      span {
        font-size: 32px;
        line-height: 38px;
      }

      .group {
        display: flex;
        margin-top: 12px;
        max-width: 305px;
        width: 100%;
        height: 25px;
        background: rgba(162, 170, 202, 0.2);
        border: 1px solid var(--text_color_dark);
        border-radius: 15px;
        user-select: none;

        div {
          width: 100%;
          text-align: center;
          color: #757A88;
          transition: all .3s ease;
          white-space: nowrap;
          cursor: pointer;

          &.active {
            background: #3077FF;
            border-radius: 26px;
            color: white;
          }

        }
      }
    }

    .notification_items {
      padding: 10px 10px 0 10px;
      width: 100%;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 24px;

      .item {
        display: flex;
        align-items: baseline;
        padding: 5px 6px;
        margin-bottom: 10px;
        width: 100%;
        background: var(--background_lighten-dark);
        box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.3);
        border-radius: 15px;

        .type {
          margin-right: 20px;
          padding: 2px;
          height: 28px;
          max-width: 160px;
          min-width: 160px;
          text-align: center;
          border: 1px solid #FFFFFF;
          border-radius: 10px;
          white-space: nowrap;
        }

        .border {
          margin: 0 20px;
          width: 1px;
          height: 20px;
          align-self: center;
          background-color: #757A88;
        }

        .date,
        .course_name,
        .phone,
        .student_name {
          white-space: nowrap;
        }

        .message {
          margin-right: 15px;
          max-width: 245px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .btn {
          margin-left: auto;
          padding: 2px;
          max-width: 165px;
          width: 100%;
          height: 28px;
          color: #B3DAFF;
          text-align: center;
          background: rgba(179, 218, 255, 0.2);
          border: 1px solid transparent;
          border-radius: 10px;
          transition: all .3s;
          cursor: pointer;

          &.show {
            background: rgba(48, 224, 161, 0.2);
            color: #30E0A1;
          }

          &:hover {
            transition: all .3s;
            border-color: var(--text_color_dark);
          }
        }
      }
    }

    .pagination_all {
      margin: 20px auto 0 auto;

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }
  }
}

.modal_notification {
  max-width: 460px;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white;

  .close_modal {
    cursor: pointer;
  }

  .ant-modal-content,
  .ant-modal-body {
    background: #201E26;
    border-radius: 40px;
    box-shadow: none;

    .title {
      margin-bottom: 25px;
      text-align: center;
      font-size: 24px;
      line-height: 29px;
    }

    .name,
    .phone,
    .message {
      display: flex;
      margin-bottom: 15px;
      padding: 0 15px;
      height: 46px;
      padding-bottom: 2px;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 20px;

      span {
        align-self: center;
      }
    }

    textarea.ant-input.textarea_antd.ant-input-disabled {
      background-color: var(--dark);
      color: white;
      border: 1px solid var(--background_lighten-dark);
      cursor: auto;
    }

    .link_redirect {
      padding: 5px 15px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      font-size: 16px;
      color: white;
      max-width: 240px;
      width: 100%;
      text-align: center;
      background: #3077FF;
      text-decoration: none;
      border-radius: 8px;
      transition: all .3s;
      cursor: pointer;

    }
  }

  .ant-modal-body {
    padding-bottom: 0;
  }
}
