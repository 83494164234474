@media (max-width: 820px) {
  .view_study-page {
    margin-left: 0 !important;
    padding-bottom: 100px !important;

    .btn_prev {
      margin-top: 0 !important;
    }

    .footer_bar {
      display: block !important;
    }
  }
}

@media (max-width: 520px) {
  .view_study-page {
    .top_info {
      padding-bottom: 10px !important;

      .full_name {
        margin-bottom: 10px !important;
        font-size: 24px !important;
        line-height: 24px !important;
      }

      .group_avatar {
        flex-direction: column !important;

        img {
          margin: 0 0 10px 0 !important;
        }
      }
    }
  }
}

.view_study-page {
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;

  .top_info {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--dark);

    .full_name {
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 38px;
      border-bottom: 1px solid var(--dark);
    }

    .group_avatar {
      display: flex;

      img {
        margin-right: 15px;
        width: 102px;
        height: 102px;
        border-radius: 20px;
        align-self: center;
      }

      .skills_study {
        display: flex;
        padding: 16px 20px 13px 20px;
        flex-direction: column;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.3);
        border-radius: 20px;

        span {
          margin-bottom: 15px;
          color: white;
        }

        .skills {
          display: flex;
          flex-wrap: wrap;

          .skill {
            margin: 0 5px 5px 0;
            padding: 4px 12px;
            width: fit-content;
            background: rgba(48, 224, 161, 0.2);
            border-radius: 34px;
            color: #30E0A1;

            &.yellow {
              color: #FFD23B;
              background: rgba(255, 210, 59, 0.2);
            }
          }
        }
      }
    }
  }

  .stages_block {
    .stage {
      .title {
        margin-bottom: 10px;
        color: var(--text_color_dark);
        font-size: 16px;
        line-height: 19px;
      }

      .h4 {
        margin-bottom: 14px;
        font-size: 20px;
        line-height: 24px;
      }

      .icons_block {
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0 10px 0;

        .icon_svg {
          &:not(:first-child) {
            margin-left: 15px;
          }

          svg {
            circle {
              fill: var(--dark);
            }
          }

          &.true {
            svg {
              circle {
                fill: #30E0A1;
              }
            }
          }

          &.failed {
            svg {
              circle {
                fill: #CF1A1A;
              }
            }
          }

          &.incomplete {
            svg {
              circle {
                fill: #757A88;
              }
            }
          }
        }
      }
    }
  }
}