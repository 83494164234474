@media (max-width: 1610px) {
  .profile {
    .info_profile {
      padding-left: 30px !important;
    }
  }
}

@media (max-width: 1580px) {
  .profile {
    .info_profile {
      padding-left: 40px !important;
    }
  }
}

@media (max-width: 1550px) {
  .profile {
    .info_profile {
      padding-left: 60px !important;
    }
  }
}

@media (max-width: 920px) {
  .profile {
    .client_profile {
      .row {
        &:nth-child(7) {
          .name {
            white-space: initial !important;
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .profile {
    flex-direction: column !important;
    overflow-x: hidden !important;

    .info_profile {
      min-height: auto !important;
      max-height: fit-content !important;
      min-width: auto !important;
      margin: 0 !important;
      padding: 10px !important;
      overflow-y: initial !important;
      overflow-x: initial !important;

      .btn_save-changed {
        margin-top: 15px !important;
      }
    }

    .organization_block {
      margin: 15px 0 0 0 !important;
      padding-right: 0 !important;
      min-height: auto !important;
      max-height: fit-content !important;
      overflow-y: inherit !important;
      overflow-x: initial !important;
      overflow: initial !important;
      max-width: inherit !important;

      .form_block {
        padding: 10px !important;
        border-radius: 16px !important;

        .btn_send {
          margin-top: 10px !important;
        }
      }


      .client_profile {
        padding: 10px !important;
        margin-bottom: 70px;
        max-width: 100% !important;
        border-radius: 16px !important;
      }
    }

    .btn_green {
      max-width: 100% !important;
    }
  }

}

@media (max-width: 360px) {
  .profile {
    padding: 10px 0 10px 5px !important;

    .photo_profile {
      .top {
        flex-direction: column !important;
        align-items: center !important;
        text-align: center !important;

        .left {
          margin-right: 0 !important;
        }
      }

      label {
        padding: 3px !important;
        text-align: center !important;
        width: calc(100% - 10px) !important;
      }
    }

    .organization_block {
      .checkbox_group {
        span {
          white-space: initial !important;
        }
      }
    }

    .client_profile {
      .row {
        .name {
          white-space: initial !important;
        }

        &:nth-child(7) {
          .name {
            overflow-wrap: anywhere !important;
          }
        }
      }
    }
  }
}

.profile {
  display: flex;
  width: 100%;
  color: white;

  .info_profile {
    margin: auto 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    min-width: 404px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #201E26;
    box-shadow: 0 20px 100px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    .photo_profile {
      padding: 15px;
      margin-bottom: 25px;
      position: relative;
      background: #343843;
      border: 1px solid #474C5A;
      box-shadow: 0 15px 20px rgba(0, 0, 0, 0.11);
      border-radius: 24px;

      .top {
        display: flex;
        margin-bottom: 45px;

        .left {
          margin-right: 15px;

          img {
            max-width: 80px;
            min-width: 80px;
            min-height: 80px;
            max-height: 80px;
            border-radius: 16px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          white-space: nowrap;

          .title {
            margin-top: 7px;
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 19px;
            color: white;
          }

          .format {
            display: flex;
            flex-direction: column;
            font-size: 11px;
            line-height: 13px;
            color: var(--text_color_dark);

            span {
              white-space: pre-wrap;
            }
          }

        }
      }

      label {
        position: absolute;
        bottom: 15px;
        padding: 3px;
        left: 50%;
        width: calc(100% - 30px);
        text-align: center;
        transform: translate(-50%, 0);
        background: #3077FF;
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
        border-radius: 20px;
        white-space: nowrap;
        cursor: pointer;
        color: white;
      }

      .btn_edit-photo {
        margin-top: 15px;
        padding: 5px;
        text-align: center;
        color: white;
      }
    }

    .account_confirm {
      margin: -15px 0 20px 0;
      padding: 5px 8px 8px 0;
      height: 39px;
      text-align: center;
      font-size: 16px;
      color: var(--text_color_dark);
      background-color: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;
    }

    .language {
      margin-bottom: 25px;

      svg {
        &.active {
          transform: rotate(180deg);
        }
      }

      .drop_down-language {
        width: 100px;
        padding: 4px 10px;
        position: relative;
        background-color: var(--dark);
        border-radius: 17px;
        border: 1px solid #474C5A;
        transition: all .2s ease;
        z-index: 3;

        span {
          margin-left: 10px;
          color: white;
          vertical-align: middle;
        }

        svg {
          position: absolute;
          top: 14px;
          right: 10px;
          margin-left: 8px;
        }

        &.active {
          border-color: transparent;
          border-radius: 17px 17px 0 0;
        }

        .drop_down-block {
          max-height: 0;
          position: absolute;
          top: 32px;
          left: -1px;
          width: 100%;
          opacity: 0;
          transform: scale(0);
          background-color: darken(#474c5a, 10%);
          border-radius: 0 0 17px 17px;
          transition: all .2s ease;
          z-index: 0;

          ul {
            padding: 0;
            margin: 0 auto;
          }

          li {
            padding: 5px 15px 5px 10px;
            margin: 0;
            cursor: pointer;

            &:hover {
              background-color: darken(#474c5a, 5%);
            }

            &:last-child {
              padding-top: 0;
              padding-bottom: 5px;
              border-radius: 0 0 17px 17px;
            }
          }

          &.active {
            min-width: 100px;
            max-height: 200px;
            transform: scale(1);
            opacity: 1;
          }
        }

        cursor: pointer;
      }

      .drop_down-language {
        width: auto;
        border: none;

        .drop_down-block {
          left: 0;
          border: 1px solid transparent;
        }
      }
    }

    .mailing {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;

      span {
        color: var(--text_color_dark);
        font-size: 14px;
        line-height: 17px;
      }

      .switch .slider:before {
        position: absolute;
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 2px;
        content: "";
        background-color: #3077FF;
        border: 1px solid #FFFFFF;
        box-shadow: 0 7px 9px rgb(0 0 0 / 43%);
        transition: 0.4s;
      }

      input:checked + .slider:before {
        transform: translateX(25px);
      }

      .slider.round {
        background: #343843;
      }
    }

    .btn_save-changed {
      margin-top: auto;
      padding: 6px 12px;
      text-align: center;
    }
  }

  .organization_block {
    padding-right: 10px;
    margin: auto 0 auto 30px;
    max-height: calc(100vh - 20px);
    min-height: calc(100vh - 20px);
    //max-width: 640px;
    width: calc(100% - 404px);
    overflow-y: auto;
    overflow-x: hidden;

    .h2 {
      display: flex;
      margin-bottom: 15px;
      font-size: 20px;
      line-height: 24px;
      color: white;

      .toggle_organization {
        margin: 2px 0 0 15px;
        display: flex;
        align-items: center;

        .switch {
          margin: 0 15px;

          input:checked + .slider {
            background-color: #292c35;
          }
        }

        span {
          font-size: 15px;
          color: var(--text_color_dark);

          &.active {
            color: white;
          }
        }
      }
    }

    .warning_account-confirm {
      margin: -15px 0 10px 0;
      font-size: 15px;
      color: var(--text_color_dark);
    }

    .form_block {
      margin-bottom: 15px;
      padding: 20px;
      background: #201F26;
      border-radius: 25px;

      .reuse_input-parent {
        margin-bottom: 5px;

        .input_classic {
          margin-bottom: 0;
        }
      }

      .info_short {
        margin: 5px 0 15px 15px;
        font-size: 14px;
        line-height: 17px;
        color: var(--background_lighten-dark);
      }

      .title_about {
        margin-left: 15px;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 17px;
        color: var(--text_color_dark);
      }

      .textarea_antd {
        padding: 12px 15px;
        max-height: 115px;
        min-height: 115px;
        overflow-y: auto;
        resize: none;
        font-size: 16px;
        line-height: 19px;
        color: white;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 16px;

        &::placeholder {
          color: #757A88;
          font-size: 14px;
        }
      }

      .checkbox_group {
        flex-direction: row-reverse;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin: 0;
        padding: 0;

        span {
          color: white;
          white-space: nowrap;
        }
      }

      .btn_send {
        padding: 6px 12px;
      }
    }

    .client_profile,
    .adress_block {
      margin-bottom: 15px;
      padding: 20px;
      background: #201F26;
      width: 100%;
      border-radius: 25px;
      border: 1px solid transparent;
      transition: border .5s;

      .h3 {
        font-size: 16px;
        line-height: 20px;
        color: var(--text_color_dark);
      }

      .row {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;

        .name {
          margin: 0 0 5px 15px;
          padding-right: 30px;
          position: relative;
          display: flex;
          align-items: center;
          width: calc(100% - 15px);
          color: var(--text_color_dark);

          svg {
            margin-left: auto;
            /*  position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%);*/
            max-width: 16px;
            max-height: 16px;
            min-width: 16px;
            min-height: 16px;
            cursor: pointer;

            path {
              transition: all .4s;
              fill: var(--background_lighten-dark);
            }

            &:hover {
              path {
                fill: var(--dark);
              }
            }
          }
        }

        .input_group {
          display: flex;
          width: 100%;

          .reuse_input-parent {
            width: 100%;

            .input_classic {
              margin: 0;

              &.error {
                transition: border .5s;
                border-color: red !important;
              }
            }
          }

          .birthday {
            padding: 9px 15px 9px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 39px;
            line-height: 19px;
            font-size: 14px;
            background-color: var(--dark);
            border: 1px solid var(--background_lighten-dark);
            transition: all .5s;
            border-radius: 16px;
            z-index: 6;

            .svg {
              margin-left: 10px;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }

            svg {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              min-height: 5px;
              max-height: 5px;
              min-width: 10px;
              max-width: 10px;
              transition: all .3s;

              &.open {
                transform: rotate(180deg);
              }
            }

            .day,
            .month,
            .year {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              span {
                max-width: 100%;
                width: 100%;
                text-align: center;
              }
            }

            .day {
              max-width: 100px;

              span {
                text-align: start;
              }
            }

            .month {
              max-width: 250px;
            }

            .year {
              max-width: 100px;
            }

            .border {
              width: 1px;
              margin: 0 32px;
            }

            .drop_down {
              position: absolute;
              padding-top: 13px;
              width: calc(100% + 2px);
              top: 20px;
              left: -1px;
              opacity: 0;
              max-height: 0;
              transform: scale(0);
              color: #757A88;
              background-color: var(--dark);
              border-radius: 0 0 16px 16px;
              z-index: 7;

              div {
                padding: 5px 15px 5px 15px;
                text-align: center;

                &:hover {
                  background-color: var(--background_lighten-dark);
                }
              }

              &.open {
                transition: all .2s;
                border-color: #9B99A7;
                opacity: 1;
                max-height: 120px;
                transform: scale(1);
                overflow: auto;
              }
            }
          }

          .country,
          .seo_country {
            position: relative;
            padding: 9px 15px 9px 15px;
            display: flex;
            width: 100%;
            height: 39px;
            line-height: 19px;
            font-size: 14px;
            background-color: var(--dark);
            border: 1px solid var(--background_lighten-dark);
            transition: all .2s;
            border-radius: 16px;
            cursor: pointer;
            z-index: 6;

            svg {
              position: absolute;
              top: 15px;
              right: 20px;
              transition: all .3s;

              &.open {
                transform: rotate(180deg);
              }
            }

            .drop_down-children {
              position: absolute;
              padding-top: 13px;
              width: calc(100% + 2px);
              top: 20px;
              left: -1px;
              opacity: 0;
              max-height: 0;
              transform: scale(0);
              color: #757A88;
              background-color: var(--dark);
              border-radius: 0 0 16px 16px;
              z-index: -2;

              div {
                padding: 5px 15px 5px 15px;

                &:hover {
                  background-color: var(--background_lighten-dark);
                }
              }

              &.open {
                transition: all .2s;
                border-color: #9B99A7;
                opacity: 1;
                max-height: 140px;
                transform: scale(1);
                overflow: auto;
              }
            }
          }

          .phone_parent {
            margin-bottom: 0;
            width: 100%;

            .icon_country {
              top: 8px;
              left: 15px;
            }

            .arrow_phone {
              top: 2px;
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.error {
        transition: border .5s;
        border-color: red !important;
      }
    }

    .h2_checkbox {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .h2 {
        margin-bottom: 0px;
        line-height: 29px;
      }

      .checkbox_group {
        margin: 0 0 0 20px;

        .form-group {
          margin: 0;
        }
      }
    }

    .btn_green {
      max-width: 305px;
      width: 100%;
      color: var(--dark);
      text-align: center;
      background: #30E0A1;
      box-shadow: none;
      border-radius: 15px;
    }

    .btn_send {
      margin-top: 15px;
    }


    .ant-input[disabled] {
      color: white;
      background-color: var(--dark);
      border-color: var(--background_lighten-dark);
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }
  }
}

.ant-modal.modal_edit-image.profile_img {
  .ant-modal-content {
    border-radius: 40px;
  }
}