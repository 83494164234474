@media (max-width: 820px) {
  .check_work-parent {
    padding-bottom: 100px !important;
  }
}
@media (max-width: 700px) {
  .check_work-parent {
    .group_demo-block {
      flex-direction: column !important;
      align-items: inherit !important;

      div:first-child {
        margin-bottom: 10px !important;
      }

      .group_demo {
        max-width: 100% !important;
      }
    }
  }

}

@media (max-width: 550px) {
  .check_work {
    .btn_row .btn {
      height: fit-content !important;
    }

    .text_row {
      flex-direction: column !important;
    }
  }
}

@media (max-width: 300px) {
  .check_work-parent {
    margin: 15px 10px !important;
  }
}

.check_work-parent {
  margin: 15px auto 15px auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: calc(100vh - 30px);
  max-width: 1320px;
  overflow: auto;
  color: white;

  .check_work {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 70px);
    min-height: calc(100vh - 70px);

    .top_info {
      padding: 20px;
      width: 100%;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 24px;

      .image_block {
        display: flex;
        margin-bottom: 15px;
        width: 100%;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--background_lighten-dark);

        img {
          width: 50px;
          height: 50px;
          border-radius: 16px;
          align-self: center;
        }

        .fio {
          margin-left: 15px;
          align-self: center;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .info_row {
        margin-top: 10px;
        display: flex;

        span {
          align-self: center;
          font-size: 16px;

          &:first-child {
            color: #757A88;
            margin-right: 5px;
          }
        }
      }
    }

    .h3 {
      margin: 25px 0 20px 0;
      font-size: 40px;
      line-height: 48px;
    }

    .img_preview {
      margin-bottom: 15px;
      border-radius: 30px;
      max-width: 960px;
      width: fit-content;
    }

    .h4 {
      font-size: 24px;
      line-height: 29px;
    }

    .question {
      margin-bottom: 10px;
      font-size: 20px;
      color: var(--text_color_dark);
    }

    .upload_icon {
      margin-right: 5px;
      height: 21px;
      width: fit-content;
      transform: rotate(45deg);
    }

    .box_teatcher-answer {
      position: relative;
      margin-top: 20px;
      padding: 20px;
      max-width: 960px;
      width: 100%;
      background: var(--dark);
      border: 1px solid #FF3434;
      box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      z-index: 2;

      .top_row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--background_lighten-dark);
        font-size: 24px;
        line-height: 29px;

        .number_answer {
          align-self: center;
        }

        .time {
          font-size: 16px;
          color: #757A88;
          align-self: center;
        }
      }

      .bottom_row {
        .text_row {
          display: flex;
          margin-top: 5px;

          span {
            align-self: center;
            font-size: 16px;

            &:first-child {
              margin-right: 5px;
              color: #757A88;
            }
          }

          img {
            margin-right: 5px;
            height: 21px;
            width: fit-content;
            transform: rotate(45deg);
          }

          a {
            align-self: center !important;
            font-size: 16px !important;
            color: white !important;
          }

        }
      }

      &.border_green {
        border-color: #30E0A1;
      }

      &.white_blue {
        border-color: #B3DAFF;
      }

      &.border_red {
        border-color: rgb(255, 52, 52);
      }
    }

    .box_teatcher-answer-bottom {
      margin-top: -40px;
      padding: 40px 20px 20px 20px;
      max-width: 960px;
      width: 100%;
      background: var(--background_lighten-dark);
      border-radius: 24px;
      z-index: 1;

      .bottom_row {
        .text_row {
          display: flex;
          margin-top: 5px;

          span {
            align-self: center;
            font-size: 16px;

            &:first-child {
              margin-right: 5px;
              color: #757A88;
            }
          }

        }
      }
    }

    .box_teatcher-answer-response {
      margin-top: -40px;
      padding: 55px 20px 20px 20px;
      max-width: 960px;
      width: 100%;
      background: var(--background_lighten-dark);
      border-radius: 24px;
      z-index: 1;

      .btn_row {
        display: flex;

        .btn {
          padding: 2px;
          height: 28px;
          text-align: center;
          border-radius: 10px;
          border: 1px solid transparent;
          cursor: pointer;
        }

        .success {
          margin-right: 13px;
          max-width: 160px;
          width: 100%;
          color: #30E0A1;
          background: rgba(48, 224, 161, 0.2);

          &.active {
            border: 1px solid #30E0A1;

          }
        }

        .denied {
          margin-right: 13px;
          max-width: 200px;
          width: 100%;
          color: #FF3434;
          background: rgba(255, 52, 52, 0.2);

          &.active {
            border: 1px solid #FF3434;
          }
        }
      }

      .parent_block {
        margin-top: 10px;

        .ant-upload.ant-upload-drag {
          background-color: var(--dark) !important;
          border-color: transparent !important;
          border-radius: 16px;

          .ant-upload-drag-container {
            color: #757A88 !important;
          }
        }

        .btn_success,
        .btn_denied {
          margin-top: 15px;
          padding: 2px;
          height: 28px;
          max-width: 160px;
          width: 100%;
          color: #30E0A1;
          background: rgba(48, 224, 161, 0.2);
          text-align: center;
          border-radius: 10px;
          cursor: pointer;
        }

        .btn_denied {
          max-width: 200px;
          color: #FF3434;
          background: rgba(255, 52, 52, 0.2);
        }
      }
    }

    .comment_title {
      margin: 35px 0 15px 0;
      color: white;
      font-size: 18px;
      line-height: 21px;
    }

    .comment_input {
      position: relative;

      .input_classic {
        border-radius: 15px;
        padding: 9px 50px 9px 20px;

        &::placeholder {
          font-size: 16px;
          line-height: 19px;
          color: #757A88;
        }
      }

      img {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 4;
        cursor: pointer;
      }
    }

    .comment {
      padding: 7px 30px 7px 53px;
      margin: 15px 0;
      position: relative;
      background-color: #201E26;
      border-radius: 15px;

      &_photo {
        position: absolute;
        top: 5px;
        left: 5px;
      }

      &_fio {
        margin-bottom: 3px;
        font-size: 14px;
        line-height: 17px;
      }

      &_date {
        margin-bottom: 14px;
        font-size: 12px;
        line-height: 14px;
        color: #474C5A;
      }

      &_text {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--background_lighten-dark);
        font-size: 16px;
        line-height: 19px;
      }

      &_like-dislike {
        display: flex;

        span {
          margin-right: 10px;
          font-size: 14px;
          line-height: 17px;
          cursor: pointer;
        }

        svg {
          margin-right: 5px;
          align-self: center;
          cursor: pointer;
        }
      }


    }

    .ant-upload-list-item-name {
      color: white;
    }

    span.ant-upload-list-item-card-actions.picture {
      display: none;
    }
  }

}


