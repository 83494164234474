@media (max-width: 650px) {
  .parent_register {
    .organization_block {
      margin-top: 20px !important;
    }
  }
}

@media (max-width: 600px) {
  .register {
    width: 100% !important;
    margin: 10px auto 0 auto !important;
    position: relative !important;
    transform: translate(0%, 0%) !important;
    top: 0 !important;
    left: 0 !important;
    min-height: inherit !important;
    height: fit-content !important;

    h2 {
      font-size: 26px !important;
      line-height: 28px !important;
    }
  }
}

.parent_register {
  display: flex;
  height: 100vh;

  .register {
    padding: 20px 20px 0 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 450px;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 50vw;
    background: #201E26;
    border: 1px solid #343843;
    box-shadow: 0px 20px 160px rgba(0, 0, 0, 0.3);
    border-radius: 40px;

    h2 {
      font-size: 36px;
      line-height: 38px;
      color: white;
      text-align: center;
    }

    .title {
      margin: 0 0 5px 15px;
      color: var(--text_color_dark);
    }

    .checkbox_group {
      max-width: fit-content;
      width: 100%;
      margin-top: 0;
      padding-left: 0;

      .form-group {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }

    .input_custom {
      padding: 3px 11px 5px;
      width: 100%;
      height: 40px;
      background: var(--background_lighten-dark);
      border-radius: 16px;
      font-size: 16px;
      line-height: 19px;
      border-color: transparent;
      color: white;
      z-index: 0;
      box-shadow: none;

      &::placeholder {
        color: #757A88;
        font-size: 16px;
        line-height: 19px;
      }

      &:hover {
        border-color: transparent;
        box-shadow: none;
      }

      &:focus {
        border-color: var(--text_color_dark);
        box-shadow: none;
      }

      &:active {
        border-color: transparent;
        box-shadow: none;
      }
    }

    input#basic_username, input#basic_password, input#basic_password2 {
      background: transparent;
      color: white;
    }

    .btn_send {
      padding: 4px 5px;
      text-align: center;
      color: white;
      max-width: 200px;
      width: 100%;
      background: #3077FF;
      box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
      border-radius: 15px;
      border: none;
      cursor: pointer;

      &:hover {
        background: #3077FF;
        box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
      }

      &:active {
        box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
      }
    }

    .a {
      color: white;
    }

    .ant-form-item-has-error {
      input {
        background-color: var(--background_lighten-dark) !important;

        &:hover {
          border-color: transparent;
          box-shadow: none;
        }

        &:focus {
          border-color: var(--text_color_dark) !important;
          box-shadow: none !important;
        }

        &:active {
          border-color: transparent !important;
          box-shadow: none !important;
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: white !important;
    }
  }

  span.ant-input-affix-wrapper.input_custom.ant-input-affix-wrapper-lg.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-has-feedback {
    background: var(--background_lighten-dark);
  }

  .organization_block {
    margin: auto;
    max-width: 580px;
    width: 100%;

    .h2 {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 24px;
      color: white;
    }

    .form_block {
      padding: 20px;
      background: #201E26;
      border: 1px solid #343843;
      box-shadow: 0px 20px 160px rgb(0 0 0 / 30%);
      border-radius: 25px;

      .reuse_input-parent {
        margin-bottom: 5px;

        .input_classic {
          margin-bottom: 0;
        }
      }

      .info_short {
        margin: 5px 0 15px 15px;
        font-size: 14px;
        line-height: 17px;
        color: var(--background_lighten-dark);
      }

      .title_about {
        margin-left: 15px;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 17px;
        color: var(--text_color_dark);
      }

      .textarea_antd {
        padding: 12px 15px;
        max-height: 115px;
        min-height: 115px;
        overflow-y: auto;
        resize: none;
        font-size: 16px;
        line-height: 19px;
        color: white;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 16px;

        &::placeholder {
          color: #757A88;
          font-size: 14px;
        }
      }

      .checkbox_group {
        flex-direction: row-reverse;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin: 0;
        padding: 0;

        span {
          color: white;
          white-space: nowrap;
        }
      }
    }
  }
}
