@media (max-width: 1280px) {
  .inner_request {
    .statistic_row {
      flex-wrap: wrap !important;
      max-height: inherit !important;

      .item {
        margin-bottom: 10px;
      }

      // display: none !important;
      &.hide {
        max-height: 0 !important;
      }
    }
  }
}

@media (max-width: 1170px) {
  .inner_request {
    .statistic_row {
      flex-wrap: wrap !important;
      max-height: inherit !important;

      .item {
        margin-bottom: 10px;
      }

      // display: none !important;
      &.hide {
        max-height: 0 !important;
      }
    }

    .request_top.mrg {
      margin-top: -10px !important;
    }

    .home_work-list {
      justify-content: space-around;

    }
  }
}

@media (max-width: 820px) {
  .inner_request {

    .footer_bar {
      display: block !important;
    }

    .middle_block {
      .top_sort {
        .search {
          display: none !important;
        }

        .reuse_drop-down {
          max-width: 100% !important;
        }
      }
    }

    .inner_block {
      padding-bottom: 60px !important;

      .top {
        display: none !important;
      }
    }
  }
}

@media (max-width: 783px) {
  .inner_request {

    .course_top-filter {
      display: none !important;
    }

    .demo_row-mobile {
      display: block !important;
    }

    .top_filter-mobile {
      display: flex !important;
    }
  }
}

@media (max-width: 630px) {
  .inner_request {
    .statistic_row {

      .item {

        &:last-child {
          margin-right: 0 !important;
        }

        &:not(:last-child) {
          margin-right: 5px !important;
        }

        &:nth-child(2) {
          margin-right: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 603px) {
  .inner_request {
    .statistic_row {
      justify-content: center !important;

      .chart {
        margin-bottom: -10px !important;
      }

      .item {
        padding: 15px !important;
        max-width: 200px !important;
        max-height: 215px !important;

        .title {
          font-size: 11px !important;

        }

        img {
          width: 100%;
          height: auto;
        }

        .img_stat {
          height: 20px !important;
        }

        .info {
          margin: 25px 0 0 !important;

          .left {
            span {
              font-size: 12px !important;
            }
          }

          .right {
            font-size: 16px !important;
          }
        }

        &:nth-child(odd) {
          margin-right: 5px !important;
        }
      }
    }

    .middle_block {
      .request_top {
        display: none !important;
      }
    }
  }
}

.inner_request {
  display: flex;
  width: 100%;
  color: white;

  .inner_block {
    padding-right: 5px;
    position: relative;
    width: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--background_lighten-dark);

      .block {
        display: flex;
        flex-direction: column;

        .btn_prev {
          padding: 1px 15px 2px 30px;
          margin-top: 17px;
          margin-bottom: 10px;
          height: 25px;
          width: fit-content;
          position: relative;
          background: var(--dark);
          border-radius: 20px;

          svg {
            position: absolute;
            top: 9px;
            left: 12px;
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
      }

      span {
        font-size: 32px;
        line-height: 38px;
      }

      .group {
        display: flex;
        margin-top: 5px;
        max-width: 540px;
        width: 100%;
        height: 25px;
        background: rgba(162, 170, 202, 0.2);
        border: 1px solid var(--text_color_dark);
        border-radius: 15px;

        div {
          width: 100%;
          text-align: center;
          color: #757A88;
          transition: all .7s ease;
          white-space: nowrap;
          cursor: pointer;

          &.active {
            background: #3077FF;
            border-radius: 26px;
            color: white;
          }

        }
      }
    }

    .middle_block {
      .top_sort {
        margin-bottom: 15px;
        display: flex;

        .reuse_drop-down {
          max-width: 295px;
          width: 100%;

          &:first-child {
            margin-right: 15px;
          }
        }

        .search {
          margin-right: 0;
          width: 100%;
        }
      }

      .no_content {
        font-size: 18px;
        text-align: center;
      }

      .statistic_row {
        display: flex;
        margin: 20px 0;
        max-height: 225px;
        opacity: 1;
        transition: max-height .7s ease;

        .item {
          padding: 20px 24px;
          max-width: 282px;
          width: 100%;
          max-height: 225px;
          background: var(--dark);
          border: 1px solid var(--background_lighten-dark);
          border-radius: 24px;
          transition: all .7s ease;

          .chart {
            height: 80px;
            margin-bottom: 40px;
            margin-top: -20px;
          }

          .title {
            margin-bottom: 15px;
          }

          .info {
            display: flex;
            margin: 25px 0 10px 0;

            .left {
              margin-right: 15px;
              padding: 4px;
              display: flex;
              background: var(--background_lighten-dark);
              border-radius: 8px;

              svg {
                align-self: center;
              }

              span {
                margin-left: 4px;
                font-size: 14px;
                line-height: 18px;
                color: #FF3434;
              }
            }

            .right {
              font-weight: 600;
              font-size: 24px;
              line-height: 24px;
              white-space: nowrap;
            }
          }

          span {
            font-size: 12px;
            line-height: 16px;
            color: #757A88;
          }

          &:not(:last-child) {
            margin-right: 15px;
          }
        }

        &.hide {
          transition: max-height .7s ease;
          /*transform: scale(0);*/
          visibility: hidden;
          opacity: 0;
          max-height: 0;
        }
      }

      .request_top {
        display: flex;
        margin-bottom: 15px;
        transition: all .7s ease;

        .button_nav {
          display: flex;
          margin-right: 15px;
          padding: 4px 13px 4px 4px;
          background: var(--dark);
          border: 1px solid var(--background_lighten-dark);
          border-radius: 17px;
          transition: all .3s ease;
          cursor: pointer;
          user-select: none;

          div {
            margin-right: 10px;
            position: relative;
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            align-self: center;
            background: #27292F;
            border-radius: 13px;

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          span {
            align-self: center;
          }

          .group_img {
            position: relative;
            margin-right: 10px;
            height: 30px;
            width: 30px;
            align-self: center;

            img {
              position: absolute;
            }
          }

          &.active {
            background: #3077FF;
            box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
            border-radius: 17px;
          }
        }

        &.mrg {
          transition: all .7s ease;
          margin-top: -30px;
        }
      }

      .request_table {
        padding: 10px 15px;
        width: 100%;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 24px;
        transition: max-width .7s ease;

        .table_row {
          margin-bottom: 10px;
          background: var(--background_lighten-dark);
          box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.3);
          border-radius: 15px;
          height: 38px;
          /*transition: all .3s ease;*/

          .group_block {
            display: flex;
            height: 38px;
            padding: 5px 6px;

            img {
              margin-right: 10px;
              width: 27px;
              min-width: 27px;
              height: 27px;
              border-radius: 10px;
            }

            .border {
              margin-right: 10px;
              width: 1px;
              background-color: #757A88;
            }

            .fio {
              margin-right: 15px;
              max-width: 150px;
              align-self: center;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: white;
            }

            .phone {
              max-width: 120px;
              width: 100%;
              margin-right: 10px;
              align-self: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .date {
              margin-right: 15px;
              align-self: center;
              white-space: nowrap;
            }

            .name_course {
              margin-right: 15px;
              max-width: 290px;
              align-self: center;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .drop_down-parent {
              display: flex;
              padding: 5.5px 12px;
              margin-left: auto;
              margin-right: 10px;
              position: relative;
              max-width: 160px;
              width: 100%;
              background: rgba(162, 170, 202, 0.2);
              border: 1px solid #30E0A1;
              border-radius: 10px;
              cursor: pointer;
              z-index: 2;

              .row {
                display: flex;
                padding-bottom: 4px;
                margin-top: 4px;
                flex-grow: 1;
                justify-content: space-between;
                align-self: center;

                span,
                svg {
                  align-self: center;
                  transition: all .3s ease;
                }

                span {
                  max-width: 87px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }

              .groups_children {
                position: absolute;
                padding-top: 5px;
                opacity: 0;
                max-height: 0px;
                min-height: 0px;
                top: 27px;
                left: -1px;
                transform: scale(0);
                width: calc(100% + 2px);
                background: rgba(162, 170, 202, 0.2);
                border: 1px solid #A2AACA;
                border-top: none;
                border-radius: 0 0 10px 10px;
                transition: max-height .3s, min-height .3s, opacity .3s, z-index .3s;
                z-index: -1;

                .group {
                  margin-bottom: 3px;
                  margin-left: 12px;
                  cursor: pointer;

                  &:hover {
                    color: #30E0A1;
                  }

                  &:last-child {
                    margin-bottom: 30px;
                  }

                  &.active {
                    color: #30E0A1;

                    &.select {
                      color: #00ff12;
                    }
                  }
                }


                &.open {
                  transition: max-height .3s, min-height .3s, opacity .3s, z-index .3s;
                  z-index: 1;
                  opacity: 1;
                  transform: scale(1);
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 85px;
                  min-height: 85px;
                }
              }

              .btn_success {
                position: absolute;
                display: flex;
                justify-content: center;
                top: 84px;
                left: -1px;
                padding: 5px;
                width: calc(100% + 2px);
                height: 28px;
                text-align: center;
                opacity: 0;
                color: #30E0A1;
                background: rgb(107, 164, 141);
                border: 1px solid #30E0A1;
                border-radius: 10px;
                transition: all .3s ease;
                z-index: -1;
                transform: scale(0);

                span {
                  align-self: center;
                }

                &:hover {
                  color: lighten(#30E0A1, 35%);
                }
              }

              &.open {
                background: rgba(162, 170, 202, 0.2);
                border-color: var(--text_color_dark);
                border-bottom: none;
                border-radius: 10px 10px 0 0;
                z-index: 0;

                .row {
                  border-bottom: 1px solid #757A88;
                }

                svg {
                  transform: rotate(180deg);
                }

                .btn_success {
                  transition: all .3s ease;
                  opacity: 1;
                  z-index: 1;
                  transform: scale(1);
                }
              }
            }

            .btn {
              margin-left: auto;
              max-width: 160px;
              width: 100%;
              padding: 3px;
              text-align: center;
              border-radius: 10px;
              cursor: pointer;

              &.red {
                background: rgba(255, 52, 52, 0.2);
                color: #FF3434;
              }
            }

          }

          &.open {
            transition: height .3s ease;
            height: 125px;
          }

          &.hide {
            transition: transform .3s ease, height .3s ease, margin .3s ease, padding .3s ease;
            height: 1px;
            transform: scale(0);
            margin: 0 !important;
            padding: 0 !important;

            .group_block {
              transition: transform .3s ease, height .3s ease, margin .3s ease, padding .3s ease;
              height: 1px;
              margin: 0 !important;
              padding: 0 !important;
              transform: scale(0);
            }
          }
        }

        .table_row.row_mobile {
          height: auto;

          .group_block {
            height: auto;
            flex-direction: column;
            transition: all .3s ease;

            .top_row,
            .middle_row,
            .bottom_row {
              display: flex;
              justify-content: space-between;
              margin: 5px 0;
            }

            .bottom_row {
              .drop_down-parent,
              .btn {
                height: 28px;
                margin-left: inherit !important;
                width: 100%;
                max-width: inherit;
              }
            }

            &.open {
              transition: all .3s ease;
              padding-bottom: 90px !important;
            }
          }
        }

        .btn_show-table {
          padding: 4px;
          width: 100%;
          text-align: center;
          color: white;
          background: #3077FF;
          box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
          border-radius: 24px;
          cursor: pointer;
        }

        .pagination_all {
          margin: 0 auto;

          .ant-pagination-item {
            margin-right: 5px;
            background-color: transparent;
            border: none;

            a {
              border-radius: 8px !important;
              color: white;
              background-color: var(--dark);
            }

            &-active {
              a {
                background-color: lighten(#343843, 20%);
              }

              border-color: transparent;
            }
          }

          li.ant-pagination-options {
            display: none;
          }

          li.ant-pagination-prev,
          li.ant-pagination-next {
            display: none;
          }

          span.ant-pagination-item-ellipsis,
          span.anticon.anticon-double-right.ant-pagination-item-link-icon,
          span.anticon.anticon-double-left.ant-pagination-item-link-icon {
            color: #61687c;
          }
        }

        &.max_width {
          transition: max-width .7s ease;
          max-width: 1304px;

          .name_course {
            max-width: 600px;
          }
        }
      }
    }
  }

  // mobile
  .top_filter-mobile {
    padding: 4px;
    margin-bottom: 20px;
    display: none;
    justify-content: space-between;
    background: #343843;
    border-radius: 34px;
    transition: all .2s ease;

    .icon {
      display: flex;
    }

    .filters_mobile {
      display: flex;
      margin: 0 auto;
      align-self: center;
      transition: all .2s ease;

      .search_ {
        position: relative;
        width: 62px;
        height: 34px;
        border: 4px solid #A1A3B2;
        border-radius: 14px;
        background: #27292F;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      div:not(:first-child) {
        margin-left: 5px;
      }

    }

    .settings {
      align-self: center;
    }

    &.hidden {
      .filters_mobile {
        display: none;
        height: 0;
        transform: scale(0);
      }
    }
  }

  .search_mobile {
    position: relative;
    display: none;
    border-radius: 14px;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    transition: all .3s ease;

    .svg {
      position: absolute;
      top: 2px;
      left: 5px;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      margin-right: 10px;
      background: #27292F;
      border-radius: 13px;
      z-index: 4;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .input_search-mobile {
      padding-left: 35px;
      background: rgba(161, 163, 178, 0.2);
      border: 1px solid #A2AACA !important;

      &:hover {
        //border: 1px solid #A2AACA !important;
      }
    }

    span {
      margin-left: 15px;
      align-self: center;
      font-size: 14px;
      color: white;
      cursor: pointer;
    }

    &.active {
      display: flex;
      align-self: center;
      max-height: 34px;
      max-width: fit-content;
      opacity: 1;
    }
  }

}

// mobile setting course
.modal_settings-mobile {
  padding-bottom: 0;
  background: #343843;
  border-radius: 16px;

  .ant-modal-content {
    border-radius: 20px;
  }

  .title {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #474C5A;

    .group_left {
      display: flex;

      img {
        margin-right: 10px;
        align-self: center;
      }

      span {
        font-size: 18px;
        color: white;
      }
    }

    .close {
      align-self: center;

      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }

  }

  .list {
    min-height: 200px;
    font-size: 16px;
    line-height: 19px;
    color: #757A88;
    cursor: pointer;

    div {
      margin-top: 20px;
    }
  }
}