
@media (max-width: 1380px) {
  .history_pay {
    .table_block {
      th {
        white-space: inherit !important;
      }
    }
  }
}

@media (max-width: 820px) {
  .history_pay {
    padding-bottom: 100px !important;
    padding-left: 0 !important;

    .h1 {
      padding-bottom: 10px !important;

      span {
        font-size: 24px !important;
        line-height: 24px !important;
      }
    }

    .top_sort {
      flex-wrap: wrap;

      .reuse_drop-down {
        margin-bottom: 20px !important;
        margin-right: 0 !important;
        max-width: 100% !important;
      }

      .search {
        margin-left: 0 !important;
      }
    }
  }
}

.history_pay {
  display: flex;
  width: 100%;
  color: white;

  .inner_block {
    position: relative;
    width: 100%;

    .h1 {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--background_lighten-dark);

      span {
        font-size: 32px;
        line-height: 38px;
      }
    }

    .top_sort {
      display: flex;
      margin-bottom: 20px;
      user-select: none;

      .reuse_drop-down {
        max-width: 295px;
        width: 100%;
        z-index: 4;

        &:first-child {
          margin-right: 15px;
          z-index: 5;
        }
      }

      .search {
        margin-right: 0;
        width: 100%;
      }
    }

    .statistic_row {
      display: flex;
      margin: 20px 0;
      max-height: 225px;
      opacity: 1;
      transition: max-height .7s ease;

      .item {
        padding: 20px 24px;
        max-width: 282px;
        width: 100%;
        max-height: 225px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 24px;
        transition: all .7s ease;

        .chart {
          height: 80px;
          margin-bottom: 40px;
          margin-top: -20px;
        }

        .title {
          margin-bottom: 15px;
        }

        .info {
          display: flex;
          margin: 25px 0 10px 0;

          .left {
            margin-right: 15px;
            padding: 4px;
            display: flex;
            background: var(--background_lighten-dark);
            border-radius: 8px;

            svg {
              align-self: center;
            }

            span {
              margin-left: 4px;
              font-size: 14px;
              line-height: 18px;
              color: #FF3434;
            }
          }

          .right {
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            white-space: nowrap;
          }
        }

        span {
          font-size: 12px;
          line-height: 16px;
          color: #757A88;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }

      &.hide {
        transition: max-height .7s ease;
        /*transform: scale(0);*/
        visibility: hidden;
        opacity: 0;
        max-height: 0;
      }
    }

    .export_btn {
      margin: 15px 0;
      padding: 3px 15px;
      width: fit-content;
      color: white;
      background-color: green;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
    }

    .table_block {

      .ant-table {
        border-radius: 10px;
        background-color: var(--background_lighten-dark);

        th {
          background-color: var(--background_lighten-dark);
          border-bottom: solid 1px var(--dark);
          color: white;
          white-space: nowrap;

          &:before {
            top: 27px !important;
            height: 100% !important;
          }

        }

        td {
          border-bottom: solid 1px var(--dark);
          color: var(--text_color_dark);
        }

        tr {
          &:last-child {
            td {
              border-bottom: none;

              &:first-child {
                border-radius: 0 0 0 10px;
              }

              &:last-child {
                border-radius: 0 0 10px 0;
              }
            }
          }

          &:hover {
            background-color: var(--dark);

            td {
              background-color: var(--dark);
            }
          }
        }

        th.ant-table-cell.ant-table-cell-scrollbar {
          display: none;
        }

        .ant-table-header {
          border-radius: 10px 10px 0 0;
        }

        th.ant-table-cell {
          &:nth-last-child(2n) {
            border-radius: 0 10px 0 0;
          }
        }

        *::-webkit-scrollbar-corner {
          background: transparent;
        }
      }

      .pagination_all {
        margin: 20px auto 0 auto;

        .ant-pagination-item {
          margin-right: 5px;
          background-color: transparent;
          border: none;

          a {
            border-radius: 8px !important;
            color: white;
            background-color: var(--dark);
          }

          &-active {
            a {
              background-color: lighten(#343843, 20%);
            }

            border-color: transparent;
          }
        }

        li.ant-pagination-options {
          display: none;
        }

        li.ant-pagination-prev,
        li.ant-pagination-next {
          display: none;
        }

        span.ant-pagination-item-ellipsis,
        span.anticon.anticon-double-right.ant-pagination-item-link-icon,
        span.anticon.anticon-double-left.ant-pagination-item-link-icon {
          color: #61687c;
        }
      }
    }
  }
}