@use 'Variables';

@media (max-width: 820px) {
  .btn_scroll-up {
    display: block !important;
  }
  .timer_parent {
    flex-wrap: wrap;

    .timer_item {
      font-size: 20px !important;

      &:first-child {
        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: 540px) {
  .timer_parent {
    justify-content: center !important;
    height: inherit !important;
    border-radius: 20px;
  }
}

/* checkbox classic */
.checkbox_group {
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
  padding-left: 14px;
  align-self: end;
  max-width: 265px;
  width: 100%;

  span {
    font-size: 14px;
    color: var(--text_color_dark);
  }

  .form-group label:before {
    margin-top: -3px;
    background: rgba(162, 170, 202, 0.2);
    border-color: var(--text_color_dark);
  }

  .form-group input:checked + label:after {
    top: 2.5px;
    border-color: var(--text_color_dark);
  }
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background: rgba(129, 70, 255, 0.2);
  border: 1px solid #8146FF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3.5px;
  left: 8px;
  width: 6px;
  height: 12px;
  border: solid #8146FF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* checkbox toggle */
.switch {
  display: inline-block;
  position: relative;
  margin-top: 2px;
  height: 20px;
  width: 48px;

  input {
    display: none;
  }

  .slider {
    background-color: darken(#343843, 5%);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    transition: .4s;
    cursor: pointer;
  }

  .slider:before {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    content: "";
    background-color: var(--dark);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.43);
    transition: .4s;
  }

  input:checked + .slider:before {
    transform: translateX(24px);
    background-color: lighten(#343843, 10%);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #54545b;
  }
}

.danger_skill {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  background-color: transparent;

  .skill {
    padding: 5px 5px 5px 12px;
    height: 33px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    width: fit-content;
    text-align: center;
    background: rgba(22, 21, 27, 0.65);
    border: 1px solid rgba(207, 26, 26, 0.6);
    border-radius: 34px;
    white-space: nowrap;

    img {
      margin-left: 5px;
    }

    span {
      margin-bottom: 1px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

/* ParentBlockProgress */
.analytics_block-gorizontal {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 35px;
  margin-left: 20px;

  .diapazon {
    position: absolute;
    bottom: -10px;
    font-size: 12px;
    line-height: 10px;
    color: #474C5A;
    white-space: nowrap;
  }

  .vertical_line {
    margin-right: 125px;
  }
}

.analytics_block-vertical {
  /*height: 380px;*/
  height: 240px;

  .block_diapazon {
    position: absolute;
    top: 0;
    left: 0;
    /*height: 380px;*/
    height: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .line_row {
    display: flex;
    position: relative;
    max-width: 495px;
    width: 100%;

    .company {
      min-width: 45px;
      margin-right: 10px;
      font-size: 10px;
      line-height: 12px;
      color: white;
      white-space: nowrap;
    }

    img {
      position: absolute;
      top: 7px;
      right: 0;
      width: calc(100% - 45px);
      height: 2px;
    }
  }

  .vertical_progress {
    padding-left: 50px;
    display: flex;
    justify-content: space-around;
    height: 240px;

    .progress {
      background-color: #f5f5f5;
      border-radius: 3px;
      box-shadow: none;
    }

    .progress.vertical {
      position: relative;
      width: 20px;
      /*height: 380px;*/
      height: 240px;
      overflow: inherit;
      display: inline-block;
      margin-right: 10px;

      &.blue {
        .progress-bar {
          background-color: #2661D3;

          &::after {
            position: absolute;
            content: '';
            // content: url("../images/icons/middle_green-vertical.svg");
            background-image: url("../../images/search_profession/middle_blue-vertical.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 21px;
            height: 16px;
            top: -3px;
            right: -1px;
            z-index: 3;
          }

          &.hidden {
            &::before {
              position: absolute;
              content: '';
              // content: url("../images/icons/middle_green-vertical.svg");
              background-image: url("../../images/search_profession/middle_blue-vertical.svg");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: 21px;
              height: 16px;
              top: -16px;
              right: 0px;
              z-index: 3;
            }

            &::after {
              content: none;
            }
          }
        }
      }
    }

    .progress {
      height: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      background-color: #565F71;
      -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 0;

      &::after {
        position: absolute;
        content: "";
        background-image: url("../../images/search_profession/top_grey-vertical.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        top: -2px;
        right: 0;
        width: 21px;
        height: 16px;
        z-index: -1;
      }

      &.blue {
        .progress-bar {
          &::before {
            position: absolute;
            content: '';
            // content: url("../images/icons/middle_green-vertical.svg");
            background-image: url("../../images/search_profession/top_blue-vertical.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 20px;
            height: 17px;
            bottom: -2px;
            right: 0px;
            z-index: -1;
          }
        }
      }
    }

    .progress.vertical > .progress-bar {
      width: 100% !important;
      position: absolute;
      bottom: 0;
    }

    .progress-bar {
      float: left;
      width: 0;
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background-color: #28C98F;
      border-radius: 10px;
      -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
      box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
      -webkit-transition: width 0.6s ease;
      -o-transition: width 0.6s ease;
      transition: width 0.6s ease;

      &::after {
        position: absolute;
        content: '';
        // content: url("../images/icons/middle_green-vertical.svg");
        background-image: url("../../images/search_profession/middle_green-vertical.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 20px;
        height: 16px;
        top: -3px;
        right: 0;
        z-index: 3;
      }

      &::before {
        position: absolute;
        content: '';
        background-image: url("../../images/search_profession/middle_green-vertical.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 20px;
        height: 16px;
        bottom: -2px;
        right: 0;
        z-index: -1;
      }

      &.hidden {
        background-color: transparent;

        &::after {
          content: none;
        }

        &::before {
          position: absolute;
          content: '';
          background-image: url("../../images/search_profession/middle_green-vertical.svg");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 20px;
          height: 16px;
          top: -14px;
          right: 0px;
          z-index: 3;
        }
      }
    }

    .name_employer {
      max-width: 35px;
      bottom: -15px;
      left: -3px;
      /* left:50%;
       transform:translate(-50%, 0);*/
      overflow: hidden;
      /* Обрезаем все, что не помещается в область */
      text-overflow: ellipsis;
      font-size: 10px;
      color: #A2AACA;
      white-space: nowrap;
    }
  }

  .ant-progress-inner {
    width: inherit;

    &::after {
      content: url("../../images/search_profession/top_grey-vertical.svg");
      position: absolute;
      transform: rotate(-90deg);
      top: -1px;
      right: -3px;
      z-index: 0;
    }
  }

  .ant-progress-bg {
    z-index: 1;

    &::after {
      content: url("../../images/search_profession/middle_green-vertical.svg");
      position: absolute;
      transform: rotate(-90deg);
      top: -1px;
      right: -5px;
      z-index: 1;
    }
  }

}

.chart {
  color: white;
  font-size: 10px;
  line-height: 12px;

  .progress_parent {
    position: absolute;
    top: 17px;
    left: 5px;
    width: 100%;
  }

  .gorizontal_progress {
    position: relative;
    margin-bottom: 40px;
    width: 100%;

    .index {
      font-size: 12px;
      line-height: 10px;
    }
  }

  .ant-progress-outer {
    padding-right: 0;
  }
}

/* SkillAnimation */
@keyframes backInUp {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  70% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
    top: 120px;
    left: 58%;
  }
}

.my_animation {
  text-align: center;
  animation-name: backInUp;
  animation-duration: .8s;
  z-index: 10;
}

/* SkillBlockProfession */
.tags_block {
  padding: 20px 20px 10px 20px;
  background: #343843;
  border-radius: 20px;
  border: 1px solid #474C5A;

  .h3 {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 19px;
  }

  .have_skill {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    background-color: transparent;

    .skill {
      padding: 4px 12px 6px 12px;
      margin-right: 5px;
      margin-bottom: 5px;
      width: fit-content;
      text-align: center;
      height: 31px;
      color: white;
      font-size: 14px;
      line-height: 20px;
      background: rgba(22, 21, 27, 0.65);
      border: 1px solid rgba(48, 224, 161, 0.6);
      border-radius: 29px;
      white-space: nowrap;

      &.unlearned {
        color: #FFD23B;
        background: rgba(255, 210, 59, 0.2);
      }
    }
  }

  .left_skill {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    background-color: transparent;

    .skill {
      padding: 5px;
      padding: 4px 13px;
      margin-right: 5px;
      margin-bottom: 5px;
      width: fit-content;
      text-align: center;
      background: rgba(22, 21, 27, 0.65);
      border: 1px solid rgba(48, 224, 161, 0.6);
      border-radius: 34px;
      white-space: nowrap;

      &.unlearned {
        color: #FFD23B;
        background: rgba(255, 210, 59, 0.2);
      }
    }
  }
}

.input_classic {
  width: 100%;
  padding-left: 15px;
  background-color: #343843;
  border: 1px solid #474C5A;
  border-radius: 16px;
  font-size: 16px;
  color: white;
  z-index: 3;
  outline: none;

  &:hover {
    border-color: #474C5A;
  }

  &:active {
    border-color: var(--text_color_dark);
    outline: none !important;
  }

  &:focus {
    border-color: var(--text_color_dark);
    outline: none !important;
    box-shadow: none;
  }

  &::placeholder {
    color: #757A88;
    font-size: 16px;
  }
}

.button_classic {
  padding: 8px;
  color: white;
  font-size: 14px;
  border: none;
  text-align: center;
  background: #3077FF;
  box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
  border-radius: 16px;
  cursor: pointer;
}

.reuse_input-parent {
  .title {
    margin-left: 15px;
    color: var(--text_color_dark);
    font-size: 14px;
    line-height: 17px;
  }

  .input_classic {
    margin-top: 5px;
    margin-bottom: 25px;
    padding: 9px 15px 9px 15px;
    font-size: 14px;
    line-height: 19px;

    &::placeholder {
      font-size: 14px;
      color: #757A88;
    }

    &:focus {
      border: 1px solid #9B99A7;
    }
  }
}

.reuse_select-parent {
  max-width: 265px;
  width: 100%;

  .title {
    margin: 0 0 5px 15px;
    font-size: 14px;
    line-height: 17px;
    color: var(--text_color_dark);
  }

  img {
    margin-right: 11px;
    margin-bottom: 1px;
  }

  .drop_down {
    padding: 7px 15px 9px 15px;
    max-width: 265px;
    width: 100%;
    height: 40px;
    position: relative;
    background-color: var(--dark);
    border-radius: 17px;
    border: 1px solid #474C5A;
    transition: all .2s ease;
    cursor: pointer;

    span {
      color: white;
      vertical-align: middle;
    }

    svg {
      position: absolute;
      top: 17px;
      right: 15px;

      &.active {
        transform: rotate(180deg);
      }
    }

    .placeholder {
      font-size: 14px;
      color: #757A88;
    }

    &.active {
      border-color: transparent;
      border-radius: 17px 17px 0 0;
    }

    .drop_down-block {
      max-height: 0;
      position: absolute;
      top: 39px;
      left: -1px;
      width: calc(100% + 2px);
      opacity: 0;
      transform: scale(0);
      background-color: darken(#474c5a, 10%);
      border: 1px solid transparent;
      border-radius: 0px 0px 17px 17px;
      transition: all .2s ease;
      z-index: 4;
      overflow-y: auto;

      ul {
        padding: 0;
        margin: 0 auto;
      }

      li {
        padding: 5px 15px 5px 10px;
        margin: 0;
        cursor: pointer;

        &:hover {
          background-color: darken(#474c5a, 5%);
        }

        &:last-child {
          padding-top: 0;
          padding-bottom: 5px;
          border-radius: 0 0 17px 17px;
        }
      }

      &.active {
        min-width: 100px;
        max-height: 200px;
        transform: scale(1);
        opacity: 1;
        overflow-y: auto;
      }
    }

    &.active {
      border-color: transparent;
    }
  }
}

.phone_parent {
  position: relative;
  margin-bottom: 25px;
  z-index: 2;

  .title {
    margin-left: 15px;
    margin-bottom: 5px;
    color: var(--text_color_dark);
    font-size: 14px;
    line-height: 17px;
  }

  img {
    max-width: 21px;
    max-height: 15px;
    min-width: 21px;
    min-height: 15px;
  }

  .drop_down-phone {
    max-height: 0;
    opacity: 0;
    transform: scale(0);
    width: 100%;
    background-color: darken(#474c5a, 10%);
    border-radius: 0px 0px 17px 17px;
    transition: all .2s ease;
    z-index: 3;

    ul {
      padding: 0;
      margin: 0 auto;
    }

    li {
      padding: 5px 15px 5px 15px;
      margin: 0;
      font-size: 14px;
      // text-align: center;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: darken(#474c5a, 5%);
      }

      &:last-child {
        padding-top: 0;
        padding-bottom: 5px;
        border-radius: 0 0 17px 17px;
      }
    }

    &.active {
      transition: all .2s ease;
      margin-top: -3px;
      min-width: 100px;
      max-height: 150px;
      transform: scale(1);
      overflow-y: auto;
      opacity: 1;
    }
  }

  .phoneNumber {
    padding: 11px 15px 11px 40px;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    background: #343843;
    border: 1px solid #474C5A;
    border-radius: 16px;
    color: white;
    transition: all .2s ease;

    &.active {
      border: none;
      border-radius: 16px 16px 0 0;
    }

    &:focus:before {
      content: '';
      width: 21px;
      height: 15px;
      // background: url("../images/");
    }

    &:focus {
      border: 1px solid #9B99A7 !important;
    }
  }

  .icon_country {
    position: absolute;
    top: 30px;
    left: 15px;
  }

  .arrow_phone {
    position: absolute;
    top: 23px;
    right: 1px;
    width: 50px;
    height: 40px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 16px;
      right: 20px;
      transition: transform .3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }
}

.overlay {
  position: fixed;
  display: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;

  &.open {
    display: block;
  }
}

.spin {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.spin-blue {
  padding: 5px;
  .ant-spin-dot-item {
    background-color: #3077FF;
  }
}

/* input с кнопкой */
.search {
  margin: 0 10px;
  position: relative;
  width: 35%;

  .input_search {
    padding-bottom: 6px;
    height: 38px;
    padding-left: 54px;

    &:focus {
      border: 1px solid var(--text_color_dark);
    }
  }

  .svg {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    margin-right: 10px;
    background: #27292F;
    border-radius: 13px;
    z-index: 3;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .btn_search {
    position: absolute;
    padding: 4px 25px;
    top: 4px;
    right: 4px;
    background: var(--text_color_dark);
    border-radius: 13px;
    color: #1F1D2C;
    cursor: pointer;
    z-index: 3;
  }
}

/* drop down */
.reuse_drop-down {
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  width: 30%;
  position: relative;
  border: 1px solid transparent;
  background: rgba(162, 170, 202, 0.2);
  border-radius: 16px;
  cursor: pointer;

  svg {
    align-self: center;
    transition: transform .4s ease;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .drop_down-category {
    position: absolute;
    width: calc(100% + 2px);
    top: 34px;
    left: -1px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-height: 0;
    opacity: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--dark);
    border-radius: 0 0 17px 17px;
    transition: all .3s ease;
    z-index: 3;

    li {
      padding: 8px 0 8px 15px;
      margin-right: 0;
      font-size: 16px;
      line-height: 19px;
      color: #757A88;

      &:hover {
        background-color: #4B505C;
      }

      &:last-child {
        border-radius: 0 0 17px 17px;
      }

      &.active {
        background-color: #4B505C;
      }

    }

    &.open {
      border-left: 1px solid var(--text_color_dark);
      border-right: 1px solid var(--text_color_dark);
      border-bottom: 1px solid var(--text_color_dark);
      background-color: var(--dark);
      max-height: 300px;
      opacity: 1;
    }
  }

  &.open {
    border: 1px solid var(--text_color_dark);
    border-bottom-color: transparent;
    border-radius: 17px 17px 0px 0px;

    .svg {
      transform: rotate(180deg);
    }
  }
}

/* input Custom */
.input_custom-parent {
  margin-top: 15px;
  position: relative;

  span {
    color: var(--text_color_dark);
    margin: 0 0 5px 15px;
  }

  .title_info {
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
      cursor: help;
    }
  }

  .input_custom {
    padding: 3px 11px 5px;
    width: 100%;
    height: 40px;
    background: #474C5A;
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    color: white;
    z-index: 0;

    &::placeholder {
      color: #757A88;
      font-size: 14px;
      line-height: 19px;
    }
  }

}

/* textarea antd */

.textarea_antd {
  padding: 12px 15px;
  max-height: 115px;
  min-height: 115px;
  overflow-y: auto;
  resize: none;
  font-size: 16px;
  line-height: 19px;
  color: white;
  background: var(--dark);
  border: 1px solid var(--background_lighten-dark);
  border-radius: 16px;
  box-shadow: none;

  &::placeholder {
    color: #757A88;
    font-size: 14px !important;
  }

  &:hover {
    border-color: var(--background_lighten-dark);
  }

  &:focus {
    border-color: var(--text_color_dark);
    box-shadow: none;
  }

  &:active {
    border-color: var(--text_color_dark);
    box-shadow: none;
  }

}

/* close modal icon */
.close_modal {
  margin-left: auto;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--text_color_dark);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* scroll top */
.btn_scroll-up {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  bottom: 25%;
  right: 20px;
  opacity: 0.7;
  border-radius: 50%;
  background-color: var(--background_lighten-dark);
  z-index: 3;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -50%) rotate(180deg);
  }
}

/* trial */
.timer_parent {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  padding: 7px 20px;
  height: 43px;
  background: #201E26;
  border-radius: 37px;

  .timer_item {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:first-child {
      margin-right: 13px;
      font-size: 18px;
      line-height: 19px;
    }

  }

  .redirect_profile {
    text-decoration: underline;
    margin: 1px 0 0 15px;
    font-size: 18px;
    color: #3077FF;
    cursor: pointer;
  }
}

/* назад кнопка */
.btn_prev {
  padding: 1px 15px 2px 30px;
  height: 25px;
  width: fit-content;
  position: relative;
  background: var(--dark);
  border-radius: 20px;

  svg {
    position: absolute;
    top: 9px;
    left: 12px;
    transform: rotate(90deg);
    cursor: pointer;
  }
}

/* erorr 404 */
.block_404 {
  padding: 25px;
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
  border-radius: 80px;

  .img {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../../images/landing/error_404.svg") no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 60px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      line-height: 48px;
      color: white;
    }
  }
}

/* карточка курса */
.reuse_course-item {
  min-width: 297px;
  width: calc(25% - 30px);
  margin: 0 0 20px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--dark);
  border-radius: 30px;
  order: 4;

  .preview_image {
    border-radius: 30px;
    width: 100%;
    aspect-ratio: 1.77;
  }

  .rate {
    position: absolute;
    display: flex;
    padding: 4px 14px;
    top: 23px;
    left: 22px;
    height: 25px;
    background: rgba(32, 30, 38, 0.6);
    border-radius: 34px;

    .ant-rate {
      font-size: 10px;
    }

    li.ant-rate-star {
      margin-right: 3px;
      margin-bottom: 0;
      background-color: transparent;
    }
  }

  .popular {
    position: absolute;
    top: 23px;
    right: 22px;
    display: flex;
    padding: 2px 3px;
    background: rgba(32, 30, 38, 0.6);
    border-radius: 34px;

    .svg {
      display: flex;

      svg {
        align-self: center;
      }
    }

    span {
      margin: 0 10px;
      font-size: 14px;
    }
  }

  .category {
    display: flex;
    margin: 0 20px 10px 20px;
    padding: 4px 10px;
    width: fit-content;
    border-radius: 34px;

    img {
      margin-right: 10px;
      align-self: center;
    }

    span {
      font-size: 14px;
    }
  }

  .info {
    margin: 0 20px 7px 20px;
    max-width: 280px;
    width: fit-content;
    font-size: 20px;
    line-height: 24px;
    overflow-wrap: break-word;
  }

  .stage {
    ul, li {
      padding: 0;
      margin: 0;
    }

    ul {
      margin: 0 20px 5px 20px !important;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: 16px;
      color: var(--text_color_dark);
      cursor: help;

      img {
        margin-left: 5px;
        margin-top: -1px;
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .title_skill {
    margin: 0 20px 10px 20px;
    font-size: 16px;
  }

  .key_skills {
    margin: 0 20px 10px 20px;
    max-height: 123px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .skills {
      display: flex;
      padding: 4px 10px;
      margin-bottom: 5px;
      position: relative;
      flex-wrap: wrap;
      color: var(--text_color_dark);
      font-size: 14px;
      background: rgba(162, 170, 202, 0.2);
      border-radius: 16px;

      img {
        position: absolute;
        top: 8px;
        right: 5px;
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .date_course {
    padding: 3px;
    margin: 0 20px 10px 20px;
    width: fit-content;
    background: rgba(32, 30, 38, 0.6);
    border-radius: 34px;

    img {
      width: 19px;
      height: 19px;
      vertical-align: sub;
    }

    svg {
      vertical-align: sub;
    }

    span {
      margin: 0 7px;
      font-size: 14px;
    }
  }

  .bottom_block {
    margin-top: auto;

    .link_row {
      display: flex;
      margin: 0 0 10px 20px;

      .copy_link {
        display: flex;
        flex-direction: column;
        max-width: 55px;
        width: 100%;
        margin-right: 10px;

        .title {
          font-size: 16px;
          margin-bottom: 5px;
        }

        .svg {
          padding: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 31px;
          background-color: #797e8d;
          cursor: pointer;

          svg {
            max-width: 30px;
            height: 18px;

            path {
              stroke: white;
            }
          }
        }
      }

      .view_catalog {
        max-width: 210px;
        width: 100%;

        .title {
          font-size: 16px;
          margin-bottom: 5px;
        }

        .corp {
          padding: 1px 5px;
          margin-right: 10px;
          width: fit-content;
          height: 24px;
          color: white;
          background: #474C5A;
          border-radius: 149px;

          div {
            vertical-align: middle;
          }
        }

        .checkbox {
          height: 24px;
          margin-right: 10px;
          align-self: center;

          .switch input:checked + .slider:before {
            transform: translateX(22px);
          }

          input:checked + .slider {
            background-color: #797e8d;
          }

          .switch {
            margin-top: 0;

            .slider.round {
              height: 24px;
            }

            .slider::before {
              height: 19px;
              width: 19px;
              bottom: 3px;
            }

          }
        }
      }
    }

    .comment_row {
      display: flex;
      margin: 10px 20px;

      .comment_count {
        margin-right: 10px;
        max-width: 167px;
        width: 100%;
        height: 24px;
        text-align: center;
        color: white;
        border: 1px solid var(--text_color_dark);
        border-radius: 16px;
      }
    }

    a {
      color: white;
    }
  }

  .cost {
    margin: 0 20px 10px 20px;
    font-size: 16px;

    span {
      margin-right: 5px;
    }
  }

  .group_btn {
    display: flex;
    width: 100%;

    .btn_view {
      margin-left: 10px;
      margin-right: 20px;
      position: relative;
      width: 41px;
      height: 41px;
      background: #3077FF;
      box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
      border-radius: 15px;
      cursor: pointer;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .btn_edit-course {
    margin: 0 0 15px 20px;
    padding: 9px;
    text-align: center;
    flex-grow: 1;
    font-size: 14px;
    background: #3077FF;
    box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
    border-radius: 15px;
    cursor: pointer;
  }

  video,
  iframe {
    border-radius: 30px;
  }
}
