.trajectory_section {
  position: relative;
  background-color: #16151B;
  z-index: 2;

  img {
    position: absolute;
    top: 4px;
    right: 3px;
    z-index: 1;
  }

  .down_block-traektory {
    padding: 5px 10px 10px 10px;
    max-height: 170px;
    border: 1px solid #474C5A;
    border-radius: 24px;
    background-color: var(--dark);

    .group {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .select_block {
        width: 100%;
      }

      .svg {
        position: relative;
        margin-right: 15px;
        width: 45px;
        height: 24px;
        background: #201E26;
        border-radius: 20px;
      }

      span {
        margin-right: 15px;
        margin-top: -2px;
        font-size: 16px;
        white-space: nowrap;
      }
    }

    .block_tags {
      overflow: auto;
      max-height: 80px;
      display: flex;
      flex-wrap: wrap;
    }

    .tag {
      padding: 7px 35px 9px 10px;
      margin-right: 5px;
      margin-bottom: 10px;
      display: flex;
      width: fit-content;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      line-height: 17px;
      background: rgba(22, 21, 27, 0.65);
      border-radius: 29px;

      span {
        font-size: 14px;
        line-height: 16px;
        color: white;
      }

      img {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        cursor: pointer;
      }

      &.without_img {
        padding: 6px 10px;
      }
    }

    .buttons {
      display: flex;
      position: relative;
      z-index: 10000199;

      .btn_search-course {
        padding: 3px;
        text-align: center;
        position: relative;
        box-shadow: none;
        width: 100%;
        border: 1px solid #757A88;
        background: transparent;
        border-radius: 20px;
        cursor: pointer;
        color: white;
        transition: all .3s linear;
        z-index: 10000199;
        user-select: none;

        &.introjs-showElement {
          opacity: 1;
          background: #3077FF;
        }

        &:hover {
          background: #3077FF;
        }

        //introjs-showElement
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  &.z_index_0 {
    /*z-index: 0;*/
    background: rgb(45, 45, 45, 0.3);

    .down_block-traektory {
      background: rgb(45, 45, 45, 0.3);

      .group, .block_tags, .btn_search-course {
        opacity: 0.4;
      }
    }
  }
}
