@media (max-width: 820px) {
  .layout {
    padding: 10px !important;
    .content {
      margin-top: 0 !important;
    }
  }
}

.layout {
  width: 100%;
  display: flex;
  color: white;
  min-height: 100vh;

  .content {
    padding-right: 5px;
    margin: 10px auto 10px;
    max-width: calc(100% - 110px);
    max-height: calc(100vh - 20px);
    overflow-y: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    &.skipped {
      max-width: calc(100% - 260px);
    }
  }

  &.padding {
    padding: 0 15px;
  }
}