.prev_button {
  padding: 1px 15px 2px 30px;
  margin-bottom: 20px;
  width: fit-content;
  position: relative;
  background: var(--dark);
  border-radius: 20px;
  cursor: pointer;

  svg {
    position: absolute;
    top: 9px;
    left: 12px;
    transform: rotate(90deg);
  }
}