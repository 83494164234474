@media (max-width: 1400px) {
  .check_test {
    .list_history-quiz {
      .history_quiz {
        max-width: 100% !important;
        margin-right: 0 !important;
      }
    }
  }
}

@media (max-width: 820px) {
  .check_test {
    padding-bottom: 100px !important;
  }

}

@media (max-width: 500px) {
  .check_test {
    .group_demo-block {
      flex-direction: column !important;
      align-items: inherit !important;

      div:first-child {
        margin-bottom: 10px !important;
      }

      .group_demo {
        div:first-child {
          width: fit-content !important;
          padding: 0 10px !important;
        }
      }
    }

    .history_quiz {
      .top_block {
        flex-direction: column !important;

        .group_left {
          margin: 0 auto 10px auto !important;

          .group_block {
            div {
              margin: 6px 5px 0px !important;
            }
          }
        }
      }
    }
  }

}

@media (max-width: 301px) {
  .check_test {
    margin: 15px 10px !important;
  }

}

.check_test {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;

  .top_info {
    margin: 0 0 25px 0;
    padding: 20px;
    width: 100%;
    background: var(--dark);
    border: 1px solid var(--background_lighten-dark);
    border-radius: 24px;

    .image_block {
      display: flex;
      margin-bottom: 15px;
      width: 100%;
      padding-bottom: 15px;
      border-bottom: 1px solid var(--background_lighten-dark);

      img {
        width: 50px;
        height: 50px;
        border-radius: 16px;
        align-self: center;
      }

      .fio {
        margin-left: 15px;
        align-self: center;
        font-size: 24px;
        line-height: 29px;
      }
    }

    .info_row {
      margin-top: 10px;
      display: flex;

      span {
        align-self: center;
        font-size: 16px;

        &:first-child {
          color: #757A88;
          margin-right: 5px;
        }
      }
    }

  }

  .list_history-quiz {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    width: 100%;

    .history_quiz {
      padding: 20px;
      margin-right: 22px;
      margin-bottom: 22px;
      max-width: 642px;
      width: 100%;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 24px;

      .top_block {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        font-size: 20px;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--background_lighten-dark);

        svg {
          margin-right: 10px;
          align-self: center;
          width: 45px;
          height: 45px;
        }

        span {
          align-self: center;
          white-space: nowrap;
        }

        .group_left {
          display: flex;
        }

        .group_block {
          display: flex;

          span {
            line-height: 17px;
          }
        }
      }

      .date_current {
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;
        text-align: center;
        font-size: 14px;
      }

      .result_list {
        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          font-size: 16px;

          .indx {
            margin-right: 10px;
          }

          .answer {
            margin-left: 20px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
  }
}