@media (max-width: 1070px) {
  .change_lesson-parent {
    .content_parent {
      padding: 5px 15px 15px 15px !important;
      min-width: 405px !important;

      .title {
        flex-direction: column !important;

        .group {
          max-width: inherit !important;
          margin-top: 10px !important;
        }

        span {
          font-size: 24px !important;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .change_lesson-parent {
    padding: 10px 15px 15px 15px !important;
    margin-bottom: 20px !important;
    border-radius: 15px !important;

    .img_remove {
      top: 19px !important;
    }

    .name_lesson {
      padding-top: 5px !important;
      font-size: 16px !important;

      .lesson.input_classic {
        padding: 4px 10px 6px 35px !important;
        max-width: calc(100% - 45px) !important;
        font-size: 16px !important;
      }

      .number_lesson {
        font-size: 16px !important;
        top: 9px !important;
      }
    }

    .stage_parent {
      .btn_save {
        max-width: calc(100% - 45px) !important;
      }
    }

    .group_inner {
      flex-direction: column !important;

      .stage_parent {
        max-width: inherit !important;
      }

      .stage_block {
        padding: 0 !important;
        background-color: transparent !important;
        margin-right: 0 !important;
        max-width: inherit !important;
        border: none !important;

        .stage {
          padding: 8px 5px !important;
          height: 40px !important;
          margin-right: 10px !important;

          img {
            width: 30px;
            height: 30px;
          }
        }

        .btn_add {
          margin-top: 10px !important;
          width: fit-content;
        }
      }

      .content_parent {
        padding: 15px !important;
        margin-top: 15px !important;

        .title {
          span {
            font-size: 18px !important;
          }
        }

        .reuse_drop-down {
          /*span {
            font-size: 14px !important;
          }*/
        }
      }
    }

  }
}

@media (max-width: 500px) {
  .change_lesson-parent {
    .content_parent {
      min-width: inherit !important;

      .form_antd {
        .demo_row {
          margin: -10px 0 15px 0 !important;
          flex-direction: column !important;

          span {
            align-self: auto !important;
          }

          .group {
            margin-top: 5px !important;
            max-width: inherit !important;
          }
        }
      }

      .btn_save {
        font-size: 14px !important;
        padding-top: 3px !important;
        width: 100% !important;
        max-width: 100% !important;
      }
    }

    .buttonWithCount {
      .btn_save {
        max-width: 100% !important;
      }
    }
  }
}

@keyframes show-matherial {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide-matherial {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.change_lesson-parent {
  padding: 18px 18px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  background: var(--dark);
  border: 1px solid var(--background_lighten-dark);
  border-radius: 24px;

  &.entering {
    animation: show-matherial .4s forwards linear;
  }

  &.entered {
    animation: show-matherial .4s forwards linear;
  }

  &.exiting {
    animation: hide-matherial .4s forwards linear;
  }

  &.exited {
    animation: hide-matherial .4s forwards linear;
  }

  /*entering: { opacity: 1 },
entered:  { opacity: 1 },
exiting:  { opacity: 0 },
exited:  { opacity: 0 },*/
  .name_lesson {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    position: relative;

    .img {
      width: 30px;
      height: 30px;
      align-self: center;
      cursor: pointer;
    }

    .lesson.input_classic {
      padding: 9px 10px 11px 35px;
      max-width: 315px;
      background: #201E26;
      border: 1px solid var(--background_lighten-dark);
      border-radius: 16px;
      font-size: 20px;
      line-height: 24px;

      &::placeholder {
        color: white;
      }
    }

    .number_lesson {
      position: absolute;
      top: 6px;
      left: 15px;
      font-size: 20px;
      color: #757A88;
      z-index: 3;
    }

  }

  .img_remove {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    cursor: pointer;

    .svg {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      position: relative;
      background: #27292F;
      border-radius: 13px;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .group_inner {
    display: flex;
  }

  .stage_parent {
    display: flex;
    flex-direction: column;
    max-width: 315px;
    width: 100%;
    margin-right: 35px;

    .btn_save {
      padding: 3px;
      margin-bottom: 15px;
      width: 100%;
      height: 30px;
      text-align: center;
      background: #30E0A1;
      box-shadow: 0px 9px 10px rgba(48, 224, 161, 0.2);
      border-radius: 13px;
      color: var(--dark);
      cursor: pointer;
    }

    .stage_block {
      width: 100%;
      padding: 10px;
      background: #201E26;
      border: 1px solid var(--background_lighten-dark);
      border-radius: 20px;

      .stage {
        padding: 8px 10px;
        margin-bottom: 10px;
        position: relative;
        display: flex;
        width: 100%;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 16px;
        cursor: pointer;

        img {
          margin-right: 10px;
          align-self: center;
        }

        .info {
          align-self: center;
          overflow-wrap: anywhere;
        }

        .remove_icon {
          position: absolute;
          display: flex;
          justify-content: center;
          top: 50%;
          right: 8px;
          width: 20px;
          height: 20px;
          transform: translate(0, -50%);
          background: #FF3434;
          border-radius: 13px;
          cursor: pointer;

          svg {
            align-self: center;
          }
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid #757A88;
        }
      }

      .btn_add {
        display: flex;
        cursor: pointer;
        white-space: nowrap;
        user-select: none;

        img {
          margin-right: 15px;
          align-self: center;
        }

        span {
          color: #3077FF;
        }
      }

      .arrow_block {
        position: relative;
        min-width: 40px;
        height: 40px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 16px;

        svg {
          position: absolute;
          top: 16px;
          left: 14px;
          // transform: translate(-50%, -50%);
          transition: all .3s ease;
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid #757A88;

          svg {
            transition: all .3s ease;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .content_parent {
    padding: 25px 25px;
    max-width: 912px;
    width: 100%;
    background: #201E26;
    border: 1px solid var(--background_lighten-dark);
    border-radius: 20px;

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;

      span {
        font-size: 32px;
        line-height: 38px;
      }

      .group {
        display: flex;
        max-width: 305px;
        width: 100%;
        height: 25px;
        align-self: end;
        background: rgba(162, 170, 202, 0.2);
        border: 1px solid var(--text_color_dark);
        border-radius: 15px;

        div {
          width: 100%;
          text-align: center;
          color: #757A88;
          transition: all .7s ease;
          white-space: nowrap;
          cursor: pointer;

          &.active {
            background: #3077FF;
            border-radius: 26px;
            color: white;
          }

        }
      }
    }

    .group_drop-down {
      width: 100%;
      user-select: none;

      .title {
        margin: 0 0 5px 15px;
        color: var(--text_color_dark);
      }

      .reuse_drop-down {
        padding: 7px 12px;
        margin-bottom: 15px;
        width: 100%;
        height: 40px;
        border: 1px solid transparent;
        background: #474c5a;
        transition: all .4s;
        z-index: 4;

        .drop_down-category {
          li {
            position: relative;
            transition: all .5s;

            svg {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
              width: 12px;
              height: 7px;
              transition: all .5s;
              z-index: 1;

              &.active {
                transition: all .5s;
                transform: rotate(180deg);
                top: 40%;
              }
            }
          }
        }

        span {
          color: white;
        }

        &:hover {
          border-color: var(--text_color_dark);
        }
      }

      &.select {
        .drop_down-category {
          .select_drop {
            width: 100%;
            position: absolute;
            top: -200px;
            opacity: 0;
            max-height: 0;
            visibility: hidden;
            transition: max-height .5s, opacity .1s, position .5s, visibility .5s, top .5s;
            z-index: -1;

            div {
              padding: 8px 0 8px 30px;
              margin-right: 0;
              font-size: 16px;
              line-height: 19px;
              color: #757A88;
              transition: max-height .5s, opacity .3s, position .5s, visibility .5s, background-color .5s;

              span {
                color: #757A88;
              }

              &:hover {
                background-color: #4B505C;
                transition: max-height .5s, opacity .3s, position .5s, visibility .5s, background-color .5s;
              }
            }

            .programming {
              display: flex;
              align-items: center;

              img {
                margin-left: 15px;
                cursor: help;
              }
            }

            &.active {
              transition: max-height .5s, opacity .1s, position .5s, visibility .5s, top .5s;
              position: inherit;
              visibility: inherit;
              opacity: 1;
              z-index: 0;
              max-height: 200px;
            }
          }
        }
      }
    }

    textarea,
    input {
      padding: 5px 15px 6px 15px;
      margin-bottom: 15px;
      width: 100%;
      background: var(--background_lighten-dark);
      border: 1px solid transparent;
      border-radius: 16px;
      resize: none;
      outline: none;
      color: white;
      transition: all .4s;

      &::placeholder {
        font-size: 14px;
        color: #757A88;
      }

      &:active {
        box-shadow: none;
        border-color: transparent;
      }

      &:focus {
        box-shadow: none;
        border-color: var(--text_color_dark);
      }

      &:hover {
        box-shadow: none;
        background: var(--background_lighten-dark);
        border-color: var(--text_color_dark);
      }

      &.resize {
        resize: vertical;
      }
    }

    .textarea_container {
      position: relative;

      .resize_icon {
        position: absolute;
        right: -1px;
        bottom: 14px;
        display: flex;
        flex-direction: column;
        pointer-events: none;
        background-color: var(--dark);
        border-radius: 10px;

        svg:first-child {
          margin-bottom: 2px;
          transform: rotate(180deg);
        }
      }

      ::-webkit-scrollbar {
        width: 8px;
        height: 4px;
      }

      ::-webkit-scrollbar-thumb {
        background: linear-gradient(rgba(162, 170, 202, 0), rgba(128, 137, 158, 1));
        border-radius: 9em;
      }

    }

    .player-wrapper {
      margin-bottom: 10px;

      video,
      iframe {
        border-radius: 10px;
      }
    }

    .demo_row {
      display: flex;
      justify-content: space-between;
      margin: 0 0 15px 0;

      span {
        margin-left: 15px;
        align-self: center;
        color: var(--text_color_dark);
      }

      .group {
        display: flex;
        max-width: 303px;
        width: 100%;
        height: 22px;
        align-self: end;
        background: rgba(162, 170, 202, 0.2);
        border: 1px solid var(--text_color_dark);
        border-radius: 15px;

        div {
          width: 100%;
          text-align: center;
          color: #757A88;
          transition: all .7s ease;
          font-size: 12px;
          white-space: nowrap;
          cursor: pointer;

          &.active {
            background: #3077FF;
            border-radius: 26px;
            color: white;
          }

        }
      }
    }

    .label {
      margin: 0 0 5px 15px;
      color: #A2AACA;
    }

    .group_buttons {
      display: flex;
      user-select: none;

      .btn_save,
      .btn_cansel {
        padding: 3px 6px 6px 6px;
        text-align: center;
        max-width: 170px;
        width: 100%;
        height: 30px;
        color: var(--dark);
        background: #30E0A1;
        border: none;
        box-shadow: 0px 9px 10px rgba(48, 224, 161, 0.2);
        border-radius: 13px;
        transition: all .4s;
        cursor: pointer;

        &.disabled {
          transition: all .4s;
          background-color: var(--dark);
          color: white;
          cursor: auto;
          box-shadow: none;
        }
      }

      .btn_cansel {
        margin-left: 15px;
        //background-color: var(--dark);
        background-color: #FF3434;
        color: white;
        box-shadow: none;
      }
    }

    .create_quiz {
      position: relative;

      .reuse_drop-down {
        margin-bottom: 10px;
        padding-top: 7px;
        height: 40px;
        width: 100%;
        max-width: inherit;
        background-color: var(--background_lighten-dark);
        z-index: 4;
      }

      input {
        margin-bottom: 0;
      }

      .recomenditon {
        margin: 15px 0;

        .reuse_drop-down {
          z-index: 5;
        }

        .textarea_antd {
          margin-top: 10px;

          &:last-child {
            margin-bottom: 15px;
          }
        }
      }
    }

    .edit_quiz {
      .recomenditon {
        .reuse_drop-down {
          z-index: 5;
        }

        .textarea_antd {
          margin-top: 10px;

          &:last-child {
            margin-bottom: 15px;
          }
        }
      }

      .group {
        input {
          padding: 9px 15px 8px 15px;
        }
      }

      .btn_save {
        display: block!important;
        padding: 3px 20px;
        width: 100%;
        max-width: fit-content;
        height: 30px;
        text-align: center;
        background: #30E0A1;
        border: none;
        box-shadow: 0px 9px 10px rgba(48, 224, 161, 0.2);
        border-radius: 13px;
        color: var(--dark);
        cursor: pointer;
      }
    }

    .choice_parent {
      display: flex;
      margin-top: 10px;
      justify-content: center;
      align-items: center;
      position: relative;

      .checkbox_antd {
        span.ant-checkbox-inner {
          background-color: transparent;
          border: 3px solid #474C5A;
        }

        .ant-checkbox.ant-checkbox-checked {
          span.ant-checkbox-inner {
            border: 3px solid #3077FF;

            &:hover {
              //  border: none;
              box-shadow: none;
            }

            //&:after {
            //  opacity: 0;
            //  border: none;
            //}
          }

        }

        .ant-checkbox-checked::after {
          border: none;
        }

        span.ant-checkbox-inner {
          width: 20px;
          height: 20px;
        }

        span.ant-checkbox {
          top: 0.3em;
        }

      }

      .group {
        position: relative;
        width: 100%;

        .input_classic {
          padding-right: 15px;
          height: 40px;
          background: var(--background_lighten-dark);
          border-radius: 16px;
        }

        input {
          margin-bottom: 0;
        }

        img {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 3;
          cursor: pointer;
        }
      }

      .add_answer {
        margin-left: 15px;
        cursor: pointer;
      }
    }

    .row_navigation-questions {
      margin: 15px 0 10px 0;
      display: flex;
      flex-wrap: wrap;

      .number {
        position: relative;
        margin-right: 5px;
        margin-bottom: 5px;
        width: 25px;
        height: 25px;
        background: var(--background_lighten-dark);
        border-radius: 8px;
        transition: all .2s ease;
        cursor: pointer;

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-color: #3077FF;
          box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
        }

        &.active {
          background: #3077FF;
          box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
        }
      }

      .create_question,
      .remove_question {
        position: relative;
        margin-right: 5px;
        width: 25px;
        height: 25px;
        background: #30E0A1;
        border-radius: 8px;
        cursor: pointer;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .remove_question {
        margin-right: 0;
        background: #FF3434;
      }

      .count_resp {
        display: flex;
        margin-left: auto;
        align-self: center;
        color: var(--text_color_dark);

        span {
          margin-right: 20px;
        }

        .ant-input-number.ant-input-number-sm {
          width: 50px;
          height: 27px;
          color: white;
          border-color: transparent;
          box-shadow: none;
          background-color: transparent;

          input {
            &:focus {
              box-shadow: none;
            }

            &:active {
              box-shadow: none;
            }
          }

          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }

    .scorm_matherial {
      position: relative;

      .label_scorm {
        padding: 4px 22px 4px 4px;
        display: flex;
        align-items: center;
        width: fit-content;
        background: #3077FF;
        border-radius: 17px;
        cursor: pointer;

        .upload_icon {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 13px;
          background: #27292F;
        }
      }

      .file_name-scorm {
      }

      .group_buttons {
        margin-top: 50px;
      }
    }

    // antd styles
    .ant-upload.ant-upload-drag {
      margin-bottom: 15px;
      background-color: #474C5A;
      border: 1px solid transparent;

      .text {
        font-size: 16px;
        line-height: 19px;
        color: #757A88;
      }

      &:hover {
        border: 1px solid var(--text_color_dark);
      }
    }

    .ant-upload-list-item-info {
      color: white;

      a {
        color: white;
      }
    }

    .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
      margin: -5px 0 15px 0;
      background-color: var(--dark);
      border-color: transparent;

      svg {
        color: white;
      }
    }

    img.ant-upload-list-item-image {
      border-radius: 5px;
    }

    span.ant-upload-list-item-card-actions.picture {
      /* display: none;*/
    }

  }

  span.ant-progress-text {
    color: white;
    margin-top: -2px;
  }
}