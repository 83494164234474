.modal_invite {
  padding-bottom: 0 !important;
  max-width: 440px !important;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white !important;


  .ant-modal-content,
  .ant-modal-body {
    border-radius: 30px;
    box-shadow: none;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .h1 {
        font-size: 18px;
        font-weight: 500;
      }

      .close {
        margin-left: auto;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #CF1A1A;
        cursor: pointer;
      }
    }

    .present {
      margin: 30px auto;
      width: fit-content;
    }

    .text {
      margin-bottom: 15px;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }

    .copy_btn {
      margin: 0 auto 15px auto;
      padding: 13px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 230px;
      width: 100%;
      border-radius: 24px;
      background: var(--blue);
      cursor: pointer;
      user-select: none;

      span {
        margin-right: 10px;
      }
    }

    .socials {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;

      img {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}