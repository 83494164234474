.view_item-journal {
  max-width: 1430px;
  margin: 0 auto;
  padding-bottom: 20px;
  color: white;

  .box_teatcher-answer-bottom,
  .box_teatcher-answer {
    max-width: inherit;
  }
}