@media (max-width: 1425px) {
  .my_course {
    .course_list {
      justify-content: space-around !important;
    }
  }
}

@media (max-width: 1115px) {
  .my_course {

    .statistic_row {
      display: none !important;
    }

    .top_sort {
      margin-bottom: 15px !important;
    }

    .request_top.mrg {
      margin-top: -10px !important;
    }

    .home_work-list {
      justify-content: space-around;

    }
  }
}

@media (max-width: 783px) {
  .my_course {
    .inner_block {
      .top {
        display: none !important;
      }

      .statistic_block {
        .top_sort {
          .search {
            display: none !important;
          }

          .reuse_drop-down {
            max-width: 100% !important;
          }
        }
      }
    }

    .course_top-filter {
      display: none !important;
    }

    .footer_bar {
      display: block !important;
    }

    .demo_row-mobile {
      display: block !important;
    }

    .top_filter-mobile {
      display: flex !important;
    }
  }
}

@media (max-width: 690px) {
  .my_course {
    .course_list {
      .reuse_course-item {
        margin-right: 0 !important;

        .preview_image {
          width: 100% !important;
        }

        .category {
          margin: 0 auto 5px auto !important;
          width: calc(100% - 30px) !important;
          display: flex;
          justify-content: center;
        }

        .info {
          max-width: inherit !important;
          text-align: center !important;
        }
      }
    }
  }
}

.my_course {
  display: flex;
  margin: 0 auto;
  width: 100%;
  color: white;

  .inner_block {
    position: relative;
    width: 100%;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--background_lighten-dark);

      span {
        font-size: 32px;
        line-height: 38px;
      }

      .group {
        display: flex;
        max-width: 540px;
        margin-top: 5px;
        width: 100%;
        height: 25px;
        background: rgba(162, 170, 202, 0.2);
        border: 1px solid var(--text_color_dark);
        border-radius: 15px;

        div {
          width: 100%;
          text-align: center;
          color: #757A88;
          transition: all .7s ease;
          white-space: nowrap;
          cursor: pointer;

          &.active {
            background: #3077FF;
            border-radius: 26px;
            color: white;
          }

        }
      }
    }

    .course_block {
      .course_top-filter {
        display: flex;
        margin-bottom: 30px;

        .btn_add {
          width: 100%;
          margin-right: 10px;
        }

        .search {
          margin-right: 10px;
          margin-left: 0;
          position: relative;
          width: 100%;
        }

        .reuse_drop-down {
          width: 100%;
        }
      }

      .course_list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
      }

      .btn_add_course {
        margin: 13px auto 0 auto;
        cursor: pointer;
      }

      .pagination_all {
        margin: 0 auto;

        .ant-pagination-item {
          margin-right: 5px;
          background-color: transparent;
          border: none;

          a {
            border-radius: 8px !important;
            color: white;
            background-color: var(--dark);
          }

          &-active {
            a {
              background-color: lighten(#343843, 20%);
            }

            border-color: transparent;
          }
        }

        li.ant-pagination-options {
          display: none;
        }

        li.ant-pagination-prev,
        li.ant-pagination-next {
          display: none;
        }

        span.ant-pagination-item-ellipsis,
        span.anticon.anticon-double-right.ant-pagination-item-link-icon,
        span.anticon.anticon-double-left.ant-pagination-item-link-icon {
          color: #61687c;
        }
      }
    }
  }

  .top_filter-mobile {
    padding: 4px;
    margin-bottom: 20px;
    display: none;
    justify-content: space-between;
    background: #343843;
    border-radius: 34px;
    transition: all .2s ease;

    .icon {
      display: flex;
    }

    .filters_mobile {
      display: flex;
      align-items: center;
      margin: 0 auto;
      align-self: center;
      transition: all .2s ease;

      .add_course,
      .search_course-mobile {
        position: relative;
        width: 62px;
        height: 34px;
        border: 4px solid #3077FF;
        border-radius: 14px;
        background: #27292F;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .search_course-mobile {
        border-color: #A1A3B2;
      }

      div:not(:first-child) {
        margin-left: 5px;
      }
    }

    .settings {
      align-self: center;
      min-height: 30px;
      min-width: 30px;
    }

    &.hidden {
      .filters_mobile {
        display: none;
        height: 0;
        transform: scale(0);
      }
    }
  }

  .search_mobile {
    position: relative;
    display: none;
    border-radius: 14px;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    transition: all .3s ease;

    .svg {
      position: absolute;
      top: 2px;
      left: 5px;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      margin-right: 10px;
      background: #27292F;
      border-radius: 13px;
      z-index: 4;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }


    .input_search-mobile {
      padding-left: 35px;
      background: rgba(161, 163, 178, 0.2);
      border: 1px solid #A2AACA !important;

      &:hover {
        //border: 1px solid #A2AACA !important;
      }
    }

    span {
      margin-left: 15px;
      align-self: center;
      font-size: 14px;
      color: white;
      cursor: pointer;
    }

    &.active {
      display: flex;
      align-self: center;
      max-height: 34px;
      max-width: fit-content;
      opacity: 1;
    }
  }
}

.modal_settings-mobile {
  padding-bottom: 0;
  background: #343843;
  border-radius: 16px;

  .ant-modal-content {
    border-radius: 20px;
  }

  .title {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #474C5A;

    .group_left {
      display: flex;

      img {
        margin-right: 10px;
        align-self: center;
      }

      span {
        font-size: 18px;
        color: white;
      }
    }

    .close {
      align-self: center;

      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }

  }

  .list {
    min-height: 200px;
    font-size: 16px;
    line-height: 19px;
    color: #757A88;
    cursor: pointer;

    div {
      margin-top: 20px;
    }
  }
}

.modal_trial {
  padding-bottom: 0 !important;
  max-width: 460px;
  width: 100% !important;
  background: #201E26;
  border: 1px solid var(--dark);
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white;

  .close_modal {
    cursor: pointer;
  }

  .ant-modal-content,
  .ant-modal-body {
    background: #201E26;
    border-radius: 40px;
    box-shadow: none;

    .title {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 29px;
    }

    .info {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 19px;
      color: #757A88;

      &:nth-child(3) {
        margin-bottom: 5px;
      }
    }

    .feedback_row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px 15px;
        /*max-width: 194px;*/
        width: 100%;
        font-size: 16px;
        line-height: 19px;
        background: var(--dark);
        border-radius: 13px;

        img {
          margin-right: 10px;
        }

        span {
          align-self: baseline;
        }

        /* &:first-child {
           margin-right: 12px;
         }*/
      }
    }

    .btn_access {
      padding: 10px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 17px;
      background: #3077FF;
      box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
      border-radius: 16px;
      cursor: pointer;
    }
  }

  .ant-modal-body {
    padding: 29px;
  }

}
