@media (max-width: 1430px) {
  .groups_list-parent {
    .group_page-list {
      justify-content: center !important;

      .item {
        &:nth-child(4n + 4) {
          margin-right: 20px !important;
        }
      }
    }
  }
}

@media (max-width: 1115px) {
  .groups_list-parent {
    .top_sort {
      margin-bottom: 15px !important;
    }
  }
}

@media (max-width: 1070px) {
  .groups_list-parent {
    .group_page-list {
      .item {
        &:nth-child(3n + 3) {
          margin-right: 20px !important;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .groups_list-parent {

    .inner_block {
      padding-right: 5px !important;
    }


    .top {
      margin-bottom: 15px !important;
      padding-bottom: 0 !important;
      border-bottom: none !important;

      span {
        font-size: 24px !important;
      }

      .group_demo {
        display: none !important;
      }

      .block {
        .btn_prev {
          margin-top: 0 !important;
        }
      }
    }
  }

  .top_filter-mobile {
    display: flex !important;
  }

  .footer_bar {
    display: block !important;
  }
}

@media (max-width: 783px) {
  .groups_list-parent {
    .inner_block {
      .statistic_block {
        .top_sort {
          .search {
            display: none !important;
          }

          .reuse_drop-down {
            max-width: 100% !important;
          }
        }
      }
    }

    .demo_row-mobile {
      display: block !important;
    }
  }
}

@media (max-width: 580px) {
  .groups_list-parent {

    .group_page {
      .group_page-list {
        .item {
          margin-right: 0 !important;
        }
      }
    }
  }

  .top_filter-mobile {
    display: flex !important;
  }
}

@media (max-width: 550px) {
  .groups_list-parent {
    .inner_block {
      .top_filter {
        flex-direction: column !important;

        .reuse_drop-down {
          max-width: inherit !important;

          &:first-child {
            margin-bottom: 10px;
          }
        }

        .search {
          margin: 10px 0 0 0 !important;
        }
      }
    }
  }
}

.groups_list-parent {
  display: flex;
  margin: 0 auto;
  width: 100%;
  color: white;

  .inner_block {
    padding-right: 5px;
    position: relative;
    width: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--background_lighten-dark);

      span {
        font-size: 32px;
        line-height: 38px;
      }

      .location {
        color: #464c59;
      }
    }

    .group_page {
      .top_filter {
        display: flex;
        width: 100%;

        .reuse_drop-down {
          max-width: 500px;
          width: 100%;
          margin-right: 10px;

          .drop_down-category {
            z-index: 4;
          }
        }

        .search {
          margin: 0;
          max-width: 797px;
          width: 100%;
        }
      }

      .group_page-list {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;

        .item {
          display: flex;
          flex-direction: column;
          padding: 15px;
          max-width: 311px;
          margin: 0 14px 15px 0;
          width: 100%;
          background: var(--dark);
          border: 1px solid var(--background_lighten-dark);
          box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
          border-radius: 22px;

          .photo_users {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            svg {
              &.grey_icon {
                rect:nth-child(3) {
                  fill: grey;
                }
              }
            }

            .group_photo {
              display: flex;
              z-index: 0;

              img {
                width: 26px;
                height: 26px;
                border-radius: 10px;
              }
            }
          }

          .title_group {
            margin-bottom: 5px;
          }

          .info {
            margin-bottom: 13px;
            color: #757A88;
          }

          .btn_open {
            padding: 2px 4px 4px 4px;
            max-width: 167px;
            width: 100%;
            height: 26px;
            text-align: center;
            color: white;
            background: #3077FF;
            box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.50);
            border-radius: 13px;
            cursor: pointer;
          }

          .archive {
            margin-left: 10px;
            padding: 2px 10px 4px 10px;
            white-space: nowrap;
            background: var(--text_color_dark);
            border-radius: 13px;
            box-shadow: 0px 9px 10px rgb(149 153 161 / 27%);
            cursor: pointer;
            user-select: none;

            &.out {

            }
          }

          &.light {
            background-color: lighten(#343843, 10%);
          }

        }
      }
    }

    .pagination_all {
      margin: 0 auto;
      width: fit-content;

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }
  }
}

.group_demo {
  display: flex;
  max-width: 540px;
  margin-top: 5px;
  width: 100%;
  height: 25px;
  background: rgba(162, 170, 202, 0.2);
  border: 1px solid var(--text_color_dark);
  border-radius: 15px;

  div {
    width: 100%;
    text-align: center;
    color: #757A88;
    transition: all .7s ease;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      background: #3077FF;
      border-radius: 26px;
      color: white;
    }
  }
}

.top_filter-mobile {
  padding: 4px;
  margin-bottom: 20px;
  height: 44px;
  display: none;
  justify-content: space-between;
  background: #343843;
  border-radius: 34px;
  transition: all .2s ease;

  .icon {
    display: flex;
  }

  .group_navigation-mobile {
    display: flex;
    align-self: center;
    transition: all .3s ease;

    div {
      width: 43px;
      height: 32px;
      position: relative;
      background: #27292F;
      border-radius: 14px;
      border: 4px solid #A1A3B2;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.active {
        border-color: #3077FF;
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }


  }

  .filters_mobile {
    display: flex;
    margin: 0 auto;
    align-self: center;
    transition: all .2s ease;

    div:not(:first-child) {
      margin-left: 5px;
    }

  }

  .settings {
    align-self: center;
  }

  &.hidden {
    .filters_mobile {
      display: none;
      height: 0;
      transform: scale(0);
    }
  }
}

// mobile setting course
.modal_settings-mobile {
  padding-bottom: 0;
  background: #343843;
  border-radius: 16px;

  .ant-modal-content {
    border-radius: 20px;
  }

  .title {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #474C5A;

    .group_left {
      display: flex;

      img {
        margin-right: 10px;
        align-self: center;
      }

      span {
        font-size: 18px;
        color: white;
      }
    }

    .close {
      align-self: center;

      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }

  }

  .list {
    min-height: 200px;
    font-size: 16px;
    line-height: 19px;
    color: #757A88;
    cursor: pointer;

    div {
      margin-top: 20px;
    }
  }
}