@media (max-width: 1430px) {
  .info_students {
    .study_block {
      .member {
        &:nth-child(4n + 4) {
          margin-right: 8px !important;
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .info_students {
    .search_block {
      flex-wrap: wrap;

      .progress_studys,
      .home_,
      .result_test,
      .journal_ {
        margin-bottom: 10px;
      }

      .reuse_drop-down,
      .search {
        max-width: 300px;
        height: fit-content;
      }
    }
  }
}

@media (max-width: 820px) {
  .info_students {
    margin-left: 0 !important;
    padding-bottom: 130px !important;

    .group_demo {
      display: none !important;
    }

    .top_info {
      .block {
        padding: 10px !important;
        min-height: 72px !important;

        img {
          max-width: 52px !important;
          min-width: 52px !important;
          max-height: 52px !important;
          min-height: 52px !important;
          align-self: center !important;
        }

        .progress_antd {
          align-self: center !important;
        }

        .group {
          .title {
            font-size: 20px !important;
          }
        }
      }
    }

    .search_block {
      flex-wrap: nowrap !important;

      .progress_studys,
      .home_,
      .result_test,
      .journal_ {
        display: none !important;
      }

      .reuse_drop-down {
        width: 100% !important;
        max-width: initial !important;
        margin-bottom: 10px;
        z-index: 4 !important;
      }

      .search {
        max-width: initial !important;
        width: 100% !important;
      }
    }

    .content {
      .test_work {
        flex-direction: column !important;

        .stage_block,
        .matherial_block,
        .work_block {
          margin-right: 0 !important;
          padding-bottom: 10px !important;
          max-width: inherit !important;
          width: 100% !important;
          min-height: 90px !important;
          height: fit-content !important;
          border-radius: 16px !important;

        }

        .stage_block {
          margin-bottom: 10px !important;
          padding: 10px !important;
          min-height: 70px !important;

          .stage {
            max-width: inherit !important;
          }
        }

        .matherial_block {
          margin-bottom: 10px !important;
          padding: 10px !important;
          min-height: 70px !important;

          .matherial {
            max-width: inherit !important;
          }
        }
      }

      .home_work {
        flex-direction: column !important;

        .stage_block,
        .matherial_block,
        .work_block {
          margin-bottom: 10px !important;
          padding: 10px !important;
          margin-right: 0 !important;
          max-width: inherit !important;
          width: 100% !important;
          min-height: 90px !important;
          height: fit-content !important;
          border-radius: 16px !important;
        }

        .stage_block {
          margin-bottom: 10px !important;
          min-height: 70px !important;

          .stage {
            max-width: inherit !important;
          }
        }

        .matherial_block {
          min-height: 70px !important;
          margin-bottom: 10px !important;

          .matherial {
            max-width: inherit !important;
          }
        }
      }

      .journal {
        .table_row {
          .top {
            margin-top: 0 !important;

            .type,
            .fio {
              max-width: 100% !important;
            }

            .type {
              margin-right: 0 !important;
              text-align: center !important;
            }

            .border {
              display: none !important;
            }
          }

          .middle {
            .date {
              max-width: 100% !important;
              margin-right: 15px !important;
              text-align: inherit !important;
            }

            .border {
              margin-right: 0 !important;
            }

            .name_course {
              max-width: 100% !important;
              margin-right: 0 !important;
              margin-left: 15px !important;
              text-align: center !important;
            }
          }

          .parent_group {
            max-width: 100% !important;

            .lesson {
              max-width: 100% !important;
              margin-right: 15px !important;
            }

            .border {
              margin-right: 0 !important;
            }

            .stage {
              max-width: 100% !important;
              margin-left: 15px !important;
              text-align: center !important;
            }
          }
        }
      }
    }

    .top_filter-mobile {
      display: flex !important;
    }

    .study_block {
      .member {
        max-width: 100% !important;
        margin-right: 0 !important;

        &:nth-child(4n + 4) {
          margin-right: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .info_students {
    .group_stat {
      flex-direction: column !important;

      .block {
        margin: 0 auto 15px auto !important;
      }
    }
  }
}

@media (max-width: 680px) {
  .info_students {
    .top_info {
      flex-direction: column !important;

      .block {
        margin: 10px 0 0 0 !important;
        max-width: inherit !important;
      }
    }

    .study_block {
      .member {
        margin: 10px 0 0 0 !important;
        max-width: inherit !important;
      }
    }
  }
}

@media (max-width: 580px) {
  .info_students {
    .study_block {
      margin-top: 10px !important;
    }
  }
}

@media (max-width: 450px) {
  .info_students {
    .search_block {
      flex-wrap: wrap !important;
    }
  }
}

.info_students {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;

  .top_info {
    display: flex;
    flex-direction: column;

    .group_demo {
      margin-top: 9px;
    }

    .group_title {
      padding-bottom: 20px;
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 32px;
      border-bottom: 1px solid var(--dark);
    }

    .group_stat {
      display: flex;

      .block {
        display: flex;
        padding: 13px;
        max-width: 428px;
        width: 100%;
        background: #201E26;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 24px;

        svg {
          max-width: 84px;
          max-height: 84px;
          width: 100%;
          align-self: center;
        }

        .group {
          margin-top: -5px;
          margin-left: 15px;
          align-self: center;

          .title {
            font-size: 24px;
          }

          .info {
            font-size: 16px;
            line-height: 19px;
            color: #757A88;
          }
        }

        &:not(:last-child) {
          margin-right: 23px;
        }

        span.ant-progress-text {
          color: white;
        }
      }

      .ant-progress.ant-progress-circle.ant-progress-status-normal.ant-progress-show-info.ant-progress-default {
        display: flex;
        align-items: center;
      }
    }
  }

  .search_block {
    display: flex;
    margin-top: 20px;

    .progress_studys,
    .home_,
    .result_test,
    .journal_ {
      display: flex;
      margin-right: 15px;
      padding: 4px 15px 4px 4px;
      max-width: 180px;
      width: 100%;
      background: #343843;
      border: 1px solid #474C5A;
      border-radius: 17px;
      transition: all .3s ease;
      cursor: pointer;
      user-select: none;

      div {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        position: relative;
        margin-right: 10px;
        background: #27292F;
        border-radius: 13px;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      span {
        white-space: nowrap;
        align-self: center;
      }

      &.active {
        background: #3077FF;
        box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
      }
    }

    .journal_ {
      width: fit-content;
    }

    .reuse_drop-down {
      width: 100%;
      margin-right: 8px;
      padding: 8px 12px;
    }

    .search {
      width: 100%;
      min-width: 200px;
      margin: 0;
    }
  }

  .test_work {
    display: flex;
    margin-top: 10px;
    padding-right: 5px;

    .title {
      margin-bottom: 20px;
      font-size: 18px;
      color: var(--text_color_dark);
      text-align: center;
    }

    .stage_block {
      padding: 20px;
      margin-right: 15px;
      max-width: 375px;
      height: 730px;
      width: 100%;
      background: #201E26;
      box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.25);
      border-radius: 20px;

      .stage {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px;
        margin-bottom: 10px;
        max-width: 335px;
        width: 100%;
        height: 50px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 18px;
        cursor: pointer;

        span {
          max-width: 300px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          align-self: center;
        }

        .count_matherial {
          padding: 2px 12px;
          height: 24px;
          align-self: center;
          font-size: 15px;
          line-height: 18px;
          background: #3077FF;
          box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
          white-space: nowrap;
          border-radius: 8px;
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid var(--text_color_dark);
          box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
          border-radius: 18px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .matherial_block {
      padding: 20px;
      margin-right: 15px;
      max-width: 313px;
      height: 730px;
      width: 100%;
      background: #201E26;
      box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.25);
      border-radius: 20px;

      .matherial {
        display: flex;
        padding: 8px;
        margin-bottom: 10px;
        max-width: 275px;
        width: 100%;
        height: 50px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 18px;
        cursor: pointer;

        .img {
          position: relative;
          margin-right: 10px;
          width: 34px;
          height: 34px;
          min-height: 34px;
          min-width: 34px;
          background: var(--background_lighten-dark);
          border-radius: 11px;

          svg {
            position: absolute;
            top: 9px;
            left: 50%;
            transform: translate(-50%, 0);

          }
        }

        span {
          max-width: 250px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          align-self: center;
        }

        .count_response {
          position: relative;
          margin-left: auto;
          width: 25px;
          height: 25px;
          border-radius: 10px;
          background-color: #3077FF;
          align-self: center;

          span {
            position: absolute;
            top: 2px;
            left: 50%;
            transform: translate(-50%, 0);
          }

          &.null {
            background-color: darken(#343843, 5%);

            span {
              color: var(--text_color_dark);
            }
          }
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid var(--text_color_dark);
          box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
          border-radius: 18px;

          .img {
            background-color: #3077FF;

            svg {
              path {
                fill: white;
              }

              rect {
                fill: white;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .work_block {
      display: flex;
      flex-direction: column;
      padding: 20px;
      max-width: 586px;
      width: 100%;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 24px;

      .row_study {
        padding: 10px;
        margin-bottom: 10px;
        background: #474C5A;
        box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.3);
        border-radius: 15px;

        .top {
          display: flex;
          padding-bottom: 10px;
          margin-bottom: 0;
          border-bottom: 1px solid #343843;

          img {
            width: 30px;
            height: 30px;
            border-radius: 10px;
          }


          .name {
            max-width: 140px;
            width: 100%;
            align-self: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .name_matherial {
            max-width: 190px;
            width: 100%;
            align-self: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .date {
            align-self: center;
            white-space: nowrap;
          }
        }

        .middle {
          display: flex;
          margin: 10px 0;

          .count {
            padding: 3px 6px;
            height: 28px;
            max-width: 70px;
            width: 100%;
            text-align: center;
            align-self: center;
            background: rgba(48, 224, 161, 0.2);
            border-radius: 10px;

            span {
              color: #30E0A1;
            }

            &.false {
              background: rgba(255, 52, 52, 0.2);

              span {
                color: #FF3434;
              }
            }
          }

          .attempts {
            align-self: center;
          }

          span {
            margin: 0 auto;
            align-self: center;
          }
        }

        .bottom {
          padding: 5px;
          width: 100%;
          text-align: center;
          color: #B3DAFF;
          background: rgba(179, 218, 255, 0.2);
          border-radius: 10px;
          cursor: pointer;
        }

        .border {
          margin: 0 10px;
          margin-top: 5px;
          height: 20px;
          width: 1px;
          background-color: #757A88;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .home_work {
    display: flex;
    margin-top: 10px;
    padding-right: 5px;

    .title {
      margin-bottom: 20px;
      font-size: 18px;
      color: var(--text_color_dark);
      text-align: center;
    }

    .stage_block {
      padding: 20px;
      margin-right: 15px;
      max-width: 375px;
      height: 730px;
      width: 100%;
      background: #201E26;
      box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.25);
      border-radius: 20px;

      .stage {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px;
        margin-bottom: 10px;
        max-width: 335px;
        width: 100%;
        height: 50px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 18px;
        cursor: pointer;

        span {
          max-width: 300px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          align-self: center;
        }

        .count_matherial {
          padding: 2px 12px;
          height: 24px;
          align-self: center;
          font-size: 15px;
          line-height: 18px;
          background: #3077FF;
          box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
          white-space: nowrap;
          border-radius: 8px;
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid var(--text_color_dark);
          box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
          border-radius: 18px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .matherial_block {
      padding: 20px;
      margin-right: 15px;
      max-width: 313px;
      height: 730px;
      width: 100%;
      background: #201E26;
      box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.25);
      border-radius: 20px;

      .matherial {
        display: flex;
        padding: 8px;
        margin-bottom: 10px;
        max-width: 275px;
        width: 100%;
        height: 50px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 18px;
        cursor: pointer;

        .img {
          position: relative;
          margin-right: 10px;
          width: 34px;
          height: 34px;
          min-height: 34px;
          min-width: 34px;
          background: var(--background_lighten-dark);
          border-radius: 11px;

          svg {
            position: absolute;
            top: 7px;
            left: 50%;
            transform: translate(-50%, 0);
          }

          &.code {
            background-color: transparent;

            svg {
              top: 0;

              circle {
                fill: var(--background_lighten-dark);
              }
            }
          }
        }

        span {
          max-width: 250px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          align-self: center;
        }

        .count_response {
          position: relative;
          margin-left: auto;
          width: 25px;
          height: 25px;
          border-radius: 10px;
          background-color: #3077FF;
          align-self: center;

          span {
            position: absolute;
            top: 2px;
            left: 50%;
            transform: translate(-50%, 0);
          }

          &.null {
            background-color: darken(#343843, 5%);

            span {
              color: var(--text_color_dark);
            }
          }
        }

        &.active {
          background: var(--background_lighten-dark);
          border: 1px solid var(--text_color_dark);
          box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
          border-radius: 18px;

          .img {
            background-color: #3077FF;

            svg {
              path {
                fill: white;
              }
            }

            &.code {
              background-color: transparent;

              svg {
                circle {
                  fill: #3077FF;
                }
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .work_block {
      display: flex;
      flex-direction: column;
      padding: 20px;
      max-width: 586px;
      width: 100%;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 24px;

      .row_study {
        padding: 10px;
        margin-bottom: 10px;
        background: #474C5A;
        box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.3);
        border-radius: 15px;

        .top {
          display: flex;
          padding-bottom: 10px;
          margin-bottom: 0;
          border-bottom: 1px solid #343843;

          img {
            width: 30px;
            height: 30px;
            border-radius: 10px;
          }

          .border {
            margin: 0 10px;
            margin-top: 5px;
            height: 20px;
            width: 1px;
            background-color: #757A88;
          }

          .name {
            max-width: 140px;
            width: 100%;
            align-self: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .name_matherial {
            max-width: 190px;
            width: 100%;
            align-self: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .date {
            align-self: center;
            white-space: nowrap;
          }
        }

        .middle {
          margin: 10px 0;
        }

        .bottom {
          padding: 5px;
          width: 100%;
          text-align: center;
          color: #B3DAFF;
          background: rgba(179, 218, 255, 0.2);
          border-radius: 10px;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .empty {
      margin: 0 auto;
      font-size: 18px;
    }
  }

  .journal {
    margin-top: 10px;
    padding-right: 5px;

    .request_table {
      padding: 10px 15px 0 15px;
      max-width: 1305px;
      width: 100%;
      background: var(--dark);
      border: 1px solid var(--background_lighten-dark);
      border-radius: 24px;

      .table_row {
        display: flex;
        padding: 5px 6px;
        margin-bottom: 10px;
        height: 38px;
        background: var(--background_lighten-dark);
        box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.3);
        border-radius: 15px;
        cursor: pointer;

        img {
          margin-right: 10px;
          width: 27px;
          height: 27px;
          border-radius: 10px;
        }

        .border {
          margin-right: 10px;
          width: 1px;
          background-color: #757A88;
        }

        .fio {
          margin-right: 15px;
          max-width: 145px;
          align-self: center;
          width: 100%;
          color: white;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .type {
          max-width: 245px;
          width: 100%;
          margin-right: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          align-self: center;
        }

        .date {
          margin-right: 15px;
          max-width: 139px;
          width: 100%;
          text-align: center;
          align-self: center;
          white-space: nowrap;
        }

        .name_course {
          margin-right: 15px;
          max-width: 465px;
          align-self: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .parent_group {
          display: flex;
          max-width: 465px;
          width: 100%;

          .lesson {
            max-width: 130px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            align-self: center;
            white-space: nowrap;
          }

          .stage {
            max-width: 130px;
            width: 100%;
            align-self: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

        }

        .result {
          padding: 3px 6px 6px 6px;
          margin-left: auto;
          height: 28px;
          max-width: 70px;
          width: 100%;
          text-align: center;
          border-radius: 10px;
          background: rgba(48, 224, 161, 0.2);
          white-space: nowrap;

          span {
            color: #30E0A1;
          }

          &.false {
            background-color: rgba(255, 52, 52, 0.2);

            span {
              color: #FF3434;
            }
          }
        }

        .btn {
          margin-left: auto;
          max-width: 160px;
          width: 100%;
          padding: 3px;
          text-align: center;
          border-radius: 10px;

          &.true {
            color: #30E0A1;
            background: rgba(48, 224, 161, 0.2);
          }

          &.false {
            color: #FF3434;
            background: rgba(255, 52, 52, 0.2);
          }

          &.need_check {
            color: #FFD23B;
            background: rgba(255, 210, 59, 0.2);
          }
        }
      }

      .table_row.home_work-mobile {
        height: auto;
        flex-direction: column;

        .top,
        .middle {
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
          margin-bottom: 0 !important;
        }

        .result {
          height: inherit;
          max-width: inherit !important;
          background-color: transparent;

          .btn {
            margin-left: inherit;
            max-width: inherit;
          }
        }
      }

      .table_row.test_mobile {
        height: auto;
        flex-direction: column;

        .top,
        .middle,
        .parent_group {
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
          margin-bottom: 0 !important;
        }

        .result {
          max-width: inherit !important;
        }
      }

      .btn_show-table {
        padding: 4px;
        width: 100%;
        text-align: center;
        color: white;
        background: #3077FF;
        box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
        border-radius: 24px;
        cursor: pointer;
      }

      &.max_width {
        max-width: 1304px;

        .name_course {
          max-width: 600px;
        }
      }
    }
  }

  .no_content {
    margin: 0 auto;
    font-size: 18px;
    text-align: center;
  }

  .study_block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    .member {
      display: flex;
      flex-direction: column;
      margin: 0 8px 10px 0;
      max-width: 318px;
      width: 100%;

      .study {
        display: flex;
        padding: 5px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
        border-radius: 18px;
        cursor: pointer;
        z-index: 2;
        transition: all .4s;

        img {
          margin-right: 10px;
          width: 43px;
          height: 43px;
          align-self: center;
        }

        .fio {
          margin-right: 60px;
          max-width: 180px;
          width: 100%;
          align-self: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ant-progress {
          align-self: center;
          margin-left: auto;
        }

        span.ant-progress-text {
          color: white;
        }

        &:hover {
          border-color: var(--text_color_dark);
        }
      }

      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }
  }

}