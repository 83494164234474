@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.parent_transition {
  &.entering {
    animation: show .8s forwards linear;
  }

  &.entered {
    animation: show .8s forwards linear;
  }

  &.exiting {
    animation: hide .4s forwards linear;
  }

  &.exited {
    animation: hide .4s forwards linear;
  }
}