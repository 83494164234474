@media (max-width: 820px) {
  .payment_resp {
    .parallax {
      display: none !important;
    }
    .center_block {
      padding: 15px !important;
      position: relative !important;
      .h1 {
        font-size: 20px !important;
      }
    }
  }
}

.payment_resp {
  width: 100%;
  max-width: 100%;
  color: white;
  height: 100%;

  .parallax {
    width: calc(100% + 60px);
    margin-left: -60px;
    height: 100%;

    .block_parralax {
      display: flex;
      justify-content: space-between;
      height: 100%;

      .left_col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .left_top,
        .left_bottom {
          margin-left: 60px;
        }

        .left_middle {
          margin-left: 20px;
        }
      }

      .right_col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .right_top {
          margin-left: 40px;
          width: fit-content;
        }

        .right_bottom {
          margin-right: 60px;
        }

        .right_middle {
          position: absolute;
          top: 48%;
          right: -30px;
        }
      }
    }

    div:first-child {
      height: 100%;
    }
  }

  .center_block {
    position: absolute;
    padding: 40px 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #1D1E25;
    border: 1px solid #343843;
    box-shadow: 0px 20px 160px rgba(0, 0, 0, 0.3);
    border-radius: 40px;

    img {
      margin-bottom: 30px;
    }

    .h1 {
      font-size: 36px;
      line-height: 43px;
      text-align: center;
    }

    .status_payment {
      margin-top: 10px;
      max-width: 380px;
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      color: #757A88;
      text-align: center;
    }

    .error_btn,
    .success_btn {
      margin-top: 30px;
      padding: 11px 15px;
      max-width: 265px;
      width: 100%;
      text-align: center;
      background: #CF1A1A;
      box-shadow: 0px 9px 20px rgba(207, 26, 26, 0.2);
      border-radius: 15px;
      cursor: pointer;
    }

    .success_btn {
      background: #3077FF;
      box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
    }
  }
}