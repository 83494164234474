@media (max-width: 1050px) {
  .modal_favorite {
    max-width: calc(100% - 30px) !important;
  }
}
@media (max-width: 820px) {
  .modal_favorite {
    .ant-modal-body {
      padding: 20px !important;
    }
    .recomendation_block {
      .title {
        flex-direction: column !important;

        .btn_show {
          margin: 10px 0 !important;
        }
      }
    }
  }
}

.modal_favorite {
  padding-bottom: 0;
  max-width: 1022px;
  width: 100% !important;
  top: 30px;
  background: #201E26;
  border: 1px solid #343843;
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 40px;
  color: white;

  ul, li {
    margin: 0;
    padding: 0;
  }

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding-bottom: 0px !important;
    background: #201E26 !important;
    box-shadow: none;
  }

  .title {
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #464C5A;
    font-size: 40px;
    line-height: 48px;

    img {
      cursor: pointer;
    }
  }

  .category {
    display: flex;
    margin-bottom: 20px;

    div {
      font-size: 16px;
      line-height: 19px;

      &:not(:first-child) {
        margin-left: 13px;
        color: #757A88;
        cursor: pointer;

        &.active {
          color: #B3DAFF;
        }
      }
    }
  }

  .category_mobile {
    display: none;
    padding: 8px 15px;
    position: relative;
    cursor: pointer;
    background: #343843;
    border-radius: 17px;
    transition: all .3s ease;

    svg {
      position: absolute;
      top: 17px;
      right: 15px;
    }

    &.active {
      border-radius: 17px 17px 0 0;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .drop_down-category-mobile {
    margin-bottom: 20px;
    max-height: 0;
    opacity: 0;
    transform: scale(0);
    background-color: #343843;
    transition: all .3s ease;

    div {
      padding: 4px 15px;
      max-height: 0;

      &:hover {
        background-color: lighten(#343843, 5%);
      }

      &:last-child {
        border-radius: 0 0 17px 17px;
      }
    }

    &.active {
      max-height: fit-content;
      opacity: 1;
      transform: scale(1);
      border-radius: 0 0 17px 17px;

      div {
        cursor: pointer;
        max-height: fit-content;
      }
    }
  }

  .list_category {
    width: 100%;
    margin-bottom: 20px;

    .profession,
    .vacancy {
      display: flex;
      padding: 7px 15px;
      padding-right: 4px;
      border: 1px solid transparent;
      max-height: 42px;
      background: var(--dark);
      border-radius: 15px;
      transition: all .2s ease;
      cursor: pointer;

      .favorite_icon {
        img {
          vertical-align: center;
        }
      }

      .border {
        margin: 0 10px;
        width: 1px;
        background-color: #4E535F;
      }

      /*svg {
        margin-right: 10px;
      }*/

      .progress {
        margin-right: 13px;

        .ant-progress-outer {
          margin-right: 0;
          padding-right: 0;
        }

        .ant-progress-inner {
          width: 26px !important;
          height: 26px !important;

          span {
            font-size: 10px;
            color: white;
          }
        }
      }

      .right_block-favorite {
        margin-left: auto;
        display: flex;

        svg {
          align-self: center;

        }

        .icon_info {
          margin-right: 10px;
          cursor: pointer;

          path {
            fill: #4B505C;
          }

          &.active {
            path {
              fill: #A2AACA;
            }
          }
        }
      }

      .btn_redirect {
        padding: 2px 15px;
        text-align: center;
        white-space: nowrap;
        background: #3077FF;
        box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
        border-radius: 13px;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:hover {
        border: 1px solid #757A88;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6);
      }

      &.active {
        background: #474C5A;
        border: 1px solid #757A88;
      }
    }

    .vacancy {
      .salary {
        // width: 13%;
        white-space: nowrap;
      }

      .company {
        width: 32%;
        margin-right: 15px;
        align-self: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .img_block {
        margin-left: auto;
      }

      img {
        margin-right: 7px;
        align-self: center;
      }
    }
  }

  .list_category-mobile {
    width: 100%;
    margin-bottom: 20px;
    display: none;

    .profession_mobile {
      padding: 15px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      background: #343843;
      border: 1px solid transparent;
      box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.5);
      border-radius: 18px;
      cursor: pointer;

      .border {
        margin: 0 10px;
        width: 1px;
        background-color: #A2AACA;
      }

      .top {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #343843;

        svg {
          &:hover,
          &.active {
            path {
              fill: #FFD23B;
            }
          }
        }

        .favorite_icon {
          align-self: center;
        }

        svg {
          margin-left: auto;
          align-self: center;
        }
      }

      .middle {
        width: 100%;
        padding-top: 10px;

        div:first-child {
          display: flex;
          justify-content: space-between;

        }

        .progress {
          width: 100%;

          .ant-progress-bg {
            &::after {
              content: '' !important;
            }
          }
        }
      }

      .bottom {
        .btn_redirect {
          margin-top: 20px;
          padding: 6px;
          text-align: center;
          background: #3077FF;
          box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
          border-radius: 13px;
        }
      }

      &.active {
        background: #474C5A;
        border: 1px solid #A2AACA;
      }
    }

    .vacancy_mobile {
      padding: 15px 15px;
      margin-top: 10px;
      position: relative;
      background: #343843;
      border-radius: 18px;
      cursor: pointer;
      border: 1px solid transparent;
      transition: all .5s;
      z-index: 2;

      .border {
        width: 1px;
        margin: 0 10px;
        height: auto;
        background-color: #4E535F;
      }

      .top {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #2E2B36;

        svg {
          align-self: center;
          cursor: pointer;

          &:hover,
          &.active {
            path {
              fill: #FFD23B;
            }
          }
        }

        span {
          font-size: 14px;
          color: white;
          white-space: nowrap;
          overflow: hidden; /* Обрезаем все, что не помещается в область */
          text-overflow: ellipsis;
        }


        .btn_redirect {
          margin-left: auto;
        }
      }

      .bottom {
        display: flex;
        margin-top: 10px;

        .left {
          display: flex;
          flex-direction: column;
          width: 50%;

          .salary {
            margin-bottom: 10px;
          }

          .btn_more-info {
            margin-top: auto;
            padding: 7px;
            text-align: center;
            background: #8146FF;
            box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
            border-radius: 13px;
            cursor: pointer;
          }
        }

        .right {
          width: 50%;
          display: flex;
          flex-direction: column;

          .employer {
            margin-bottom: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .btn_redirect {
            margin-top: auto;
            padding: 7px;
            text-align: center;
            background: #3077FF;
            box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
            border-radius: 13px;
            cursor: pointer;
          }
        }
      }

      .drop_down-vacancy-mobile {
        &.active {
          margin-top: 20px;
        }
      }

      &.active {
        transition: all .5s;
        box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.5);
        background-color: #474C5A;;
        border-color: #A2AACA;
      }
    }

  }

  .active_vacancy {
    &-title {
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 38px;
    }

    &-popular {
      margin-bottom: 20px;
    }
  }

  .description {
    margin: 10px 0;
    font-size: 18px;
    line-height: 20px;
  }

  .tags_block {
    margin-bottom: 20px;
    padding: 12px 8px 8px 12px;

    .danger_skill {
      margin-bottom: 0;
    }
  }

  .recomendation_block {
    width: 100%;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .group_ {
        margin-right: 10px;

        .h3 {
          margin-bottom: 8px;
          font-size: 24px;
          line-height: 29px;
        }

        .text {
          font-size: 14px;
          line-height: 17px;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .btn_show {
        max-width: 180px;
        width: 100%;
        padding: 9px;
        height: 40px;
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        background: #8146FF;
        box-shadow: 0px 9px 20px rgba(129, 70, 255, 0.3);
        border-radius: 15px;
        cursor: pointer;
      }

    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .reuse_course-item {
        width: min-content;
        min-height: 600px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        background: #343843;
        border-radius: 30px;

        .rate {
          position: absolute;
          padding: 4px 14px;
          top: 25px;
          left: 22px;
          background: rgba(32, 30, 38, 0.6);
          border-radius: 34px;

          .ant-rate {
            font-size: 10px;
          }

          li.ant-rate-star {
            margin-right: 3px;
            margin-bottom: 0;
            background-color: transparent;
          }
        }

        .popular {
          position: absolute;
          top: 23px;
          right: 22px;
          display: flex;
          padding: 2px 3px;
          background: rgba(32, 30, 38, 0.6);
          border-radius: 34px;

          span {
            margin: 0 10px;
            font-size: 14px;
          }
        }

        .category {
          display: flex;
          margin: 0 20px 15px 20px;
          padding: 4px 10px;
          width: fit-content;
          border-radius: 34px;

          img {
            margin-right: 10px;
            align-self: center;
          }

          span {
            font-size: 14px;
          }
        }

        .info {
          margin: 0 20px 7px 20px;
          max-width: 280px;
          font-size: 20px;
          line-height: 24px;
          overflow-wrap: break-word;
        }

        .stage {
          ul, li {
            padding: 0;
            margin: 0;
          }

          ul {
            margin: 0 20px 5px 20px;
          }

          li {
            white-space: nowrap;
            font-size: 16px;
            color: var(--text_color_dark);

            &:not(:last-child) {
              margin-right: 15px;
            }
          }
        }

        .title_skill {
          margin: 0 20px 10px 20px;
          font-size: 16px;
        }

        .key_skills {
          margin: 0 20px 10px 20px;
          max-height: 140px;
          overflow: auto;
          display: flex;
          flex-wrap: wrap;

          .skills {
            display: flex;
            padding: 4px 10px;
            margin-bottom: 5px;
            position: relative;
            flex-wrap: wrap;
            color: var(--text_color_dark);
            font-size: 14px;
            background: rgba(162, 170, 202, 0.2);
            border-radius: 16px;

            img {
              position: absolute;
              top: 8px;
              right: 5px;
              cursor: pointer;
            }

            &:not(:last-child) {
              margin-right: 8px;
            }
          }
        }

        .date_course {
          padding: 3px;
          margin: 0 20px 10px 20px;
          width: fit-content;
          background: rgba(32, 30, 38, 0.6);
          border-radius: 34px;

          img {
            width: 19px;
            height: 19px;
            vertical-align: sub;
          }

          span {
            margin: 0 7px;
            font-size: 14px;
          }
        }

        .bottom_block {
          margin-top: auto;

          a {
            color: white;
          }
        }

        .cost {
          margin: 0 20px 15px 20px;
          font-size: 16px;

          span {
            margin-right: 5px;
          }
        }

        .btn_open-course {
          padding: 12px;
          text-align: center;
          font-size: 14px;
          background: #3077FF;
          box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
          border-radius: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .salary_title {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 24px;
  }

  // progress-bar start
  .progress_parent {
    width: -webkit-fill-available;
  }

  .analytics_block-gorizontal,
  .analytics_block-vertical {
    margin-left: 0;
    padding-left: 20px;
  }

  .gorizontal_progress {
    width: auto !important;
  }

  .vertical_line {
    margin-right: auto !important;
  }

  .ant-progress-bg {
    &::after {
      content: url("../../../../images/search_profession/middle_green-gorizontal.svg");
      position: absolute;
      top: 0;
      right: 0

    }
  }

  .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-large.progress_bar.blue {
    .ant-progress-bg {
      &::after {
        content: url("../../../../images/search_profession/middle_blue-gorizontal.svg");
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  // progress-bar end

  .bottom_info {
    margin-bottom: 25px;
    padding: 25px;
    background: #343843;
    border: 1px solid #474C5A;
    border-radius: 25px;

    div {
      font-size: 14px;
      line-height: 17px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      span {
        color: #B3DAFF;
      }
    }
  }

}