@media (max-width: 750px) {

  .parent_login,
  .parent_forgot-login {
    width: calc(100vw - 30px) !important;
  }

}

@media (max-width: 600px) {
  .parent_login,
  .parent_forgot-login {
    width: calc(100% - 20px) !important;
    margin: 10px !important;
    position: relative !important;
    transform: translate(0%, 0%) !important;
    top: 0 !important;
    left: 0 !important;
  }
}
.login_page {
  width: 100%;
  height: 100vh;
  background: #16151B;
}
.parent_login,
.parent_forgot-login {
  padding: 20px 20px 0 20px;
  position: absolute;
  max-width: 720px;
  width: 100%;
  min-height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: #201E26;
  border: 1px solid #343843;
  box-shadow: 0px 20px 160px rgba(0, 0, 0, 0.3);
  border-radius: 24px;

  .drop_down-language {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 95px;
    width: 100%;
    right: 20px;
    top: 25px;
    padding: 4px 9px;
    background: var(--dark);
    border: 1px solid var(--background_lighten-dark);
    border-radius: 17px;
    color: white;
    cursor: pointer;
    z-index: 5;

    img {
      max-height: 15px;
      min-height: 15px;
      max-width: 21px;
      min-width: 21px;
    }

    svg {
      transition: all .5s;
      min-height: 5px;
      min-width: 10px;
    }

    .drop_down {
      position: absolute;
      top: 18px;
      left: -1px;
      padding-top: 10px;
      opacity: 0;
      transform: scale(0);
      max-height: 0;
      border-radius: 0 0 17px 17px;
      width: calc(100% + 2px);
      border: 1px solid var(--background_lighten-dark);
      background-color: var(--dark);
      border-top: 1px solid transparent;
      transition: all .3s;
      z-index: -2;

      .language {
        display: flex;
        padding: 4px 10px 4px 9px;
        align-items: center;
        cursor: pointer;

        img {
          max-height: 15px;
          max-width: 21px;
          margin-right: 9px;
        }

        &:hover {
          background-color: var(--background_lighten-dark);
        }

        &:last-child {
          border-radius: 0 0 17px 17px;
        }
      }

      &.open {
        transition: all .3s;
        opacity: 1;
        transform: scale(1);
        max-height: 150px;
      }
    }

    &.open {
      svg {
        transition: all .5s;
        transform: rotate(180deg);
      }
    }
  }

  h2 {
    font-size: 36px;
    line-height: 38px;
    color: white;
    text-align: center;
  }

  .title {
    margin: 0 0 5px 15px;
    color: var(--text_color_dark);
  }

  .checkbox_group {
    margin-top: 0;
    padding-left: 0;

    .form-group {
      margin-top: 10px;
    }
  }

  .ant-form-item-has-error {

    input {
      background-color: var(--background_lighten-dark) !important;

      &:hover {
        border-color: transparent;
        box-shadow: none;
      }

      &:focus {
        border-color: var(--text_color_dark) !important;
        box-shadow: none !important;
      }

      &:active {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .ant-form-item-has-success {
    input {
      // background-color: blue;
    }
  }

  .ant-form-item-extra {
    position: absolute;
    top: 40px;
    left: 0;
    margin-top: 5px;
    color: red;
  }

  input#basic_password {
    background: #474c5a;
    color: white;

  }

  span.anticon.anticon-eye-invisible.ant-input-password-icon {
    &:hover {
      color: white;
    }
  }

  .input_custom {
    padding: 3px 11px 5px;
    width: 100%;
    height: 40px;
    background: var(--background_lighten-dark);
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    border-color: transparent;
    color: white;
    z-index: 0;
    box-shadow: none;

    &::placeholder {
      color: #757A88;
      font-size: 16px;
      line-height: 19px;
    }

    &:hover {
      border-color: transparent;
      box-shadow: none;
    }

    &:focus {
      border-color: var(--text_color_dark);
      box-shadow: none;
    }

    &:active {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .btn_send {
    padding: 5px;
    text-align: center;
    color: white;
    max-width: 170px;
    width: 100%;
    background: #3077FF;
    box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
    border-radius: 15px;
    border: none;
    cursor: pointer;

    &:hover {
      background: #3077FF;
      box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
    }

    &:active {
      box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
    }
  }

  .a {
    color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }

  span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg.ant-input-password.input_custom {
    border: none !important;
    background-color: var(--background_lighten-dark) !important;
    box-shadow: none !important;
  }
}

.parent_forgot-login {
  min-height: inherit;
  background: #201E26;
  border: 1px solid #343843;
  box-shadow: 0 20px 160px rgba(0, 0, 0, 0.3);
  border-radius: 30px;

  .group_btn {
    display: flex;
    justify-content: space-between;

    .btn {
      padding: 5px;
      margin-right: 10px;
      text-align: center;
      color: white;
      max-width: 170px;
      width: 100%;
      background: #3077FF;
      box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
      border-radius: 15px;
      border: none;
      cursor: pointer;

      &:hover {
        background: #3077FF;
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
      }

      &:active {
        box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
      }
    }

    .request {
      max-width: 190px;
    }

    .prev {
      max-width: 130px;
    }
  }

  .ant-input {
    background-color: var(--background_lighten-dark);
  }

  input#restore_password_password,
  input#restore_password_confirm {
    color: white;
  }

}