//.button {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
//  background: #3077FF;
//  border-radius: 15px;
//  transition: all .3s linear;
//  white-space: nowrap;
//  outline: none;
//  border: none;
//  cursor: pointer;
//
//  .text_in {
//    font-size: 14px;
//    line-height: 16px;
//    color: white;
//    user-select: none;
//  }
//
//  &.disabled {
//    cursor: auto;
//    opacity: .5;
//  }
//
//  &:hover {
//    background-color: #3077FF !important;
//  }
//
//  &:focus {
//    outline: none;
//  }
//}
.button {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  transition: all 0.3s linear;
  white-space: nowrap;
  outline: none;
  border: none;
  cursor: pointer;
  &.blue {
    box-shadow: 0 5px 15px rgba(48, 119, 255, 0.45);
    background: #3077FF;
    &:hover {
      background-color: #508bfc;
      box-shadow: 0 0 10px rgba(48, 119, 255, 0.95);
    }
  }
  &.green {
    background-color: #30E0A1;// var(--green);
    box-shadow: 0 5px 15px rgba(48, 224, 161, 0.34);
    &:hover {
      background-color: #4af8b5;
      box-shadow: 0 0 10px rgba(48, 224, 161, 0.95);
    }
  }
  &.red {
    background-color: #FF3434;
    box-shadow: 0 5px 15px rgba(255, 52, 52, 0.34);
    &:hover {
      background-color: #ff4444;
      box-shadow: 0 0 10px rgba(255, 52, 52, 0.95);
    }
  }

  .shadow {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 2px;
  }

  &:focus {
    outline: none;
  }
  .text_in {
    font-size: 13px;
    line-height: 16px;
    color: white;
    user-select: none;
  }

  &.disabled {
    pointer-events: none;
    cursor: auto;
    opacity: .5;
  }
}