.modal_reject {
  color: #FFF;

  .ant-modal-body {
    padding: 25px;
    border-radius: 40px;
    border: 1px solid var(--dark);
    background: #201E26;
    box-shadow: 0 30px 120px 0 rgba(0, 0, 0, 0.99);

    .title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 24px;

      img {
        cursor: pointer;
      }
    }

    .label {
      margin: 0 0 5px 15px;
      font-size: 14px;
      line-height: 14px;
      color: var(--text_color_dark);
    }

    textarea {
      padding: 5px 15px 6px 15px;
      margin-bottom: 15px;
      width: 100%;
      background: var(--background_lighten-dark);
      border: 1px solid transparent;
      border-radius: 16px;
      resize: none;
      outline: none;
      color: white;
      transition: all .4s;

      &::placeholder {
        font-size: 14px;
        color: #757A88;
      }

      &:active {
        box-shadow: none;
        border-color: transparent;
      }

      &:focus {
        box-shadow: none;
        border-color: var(--text_color_dark);
      }

      &:hover {
        box-shadow: none;
        background: var(--background_lighten-dark);
        border-color: var(--text_color_dark);
      }
    }
  }
}

@media (max-width: 820px) {
  .modal_reject {
    .ant-modal-body {
      padding: 20px 25px;

      .title {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
  }
}