@media (max-width: 1430px) {
  .parent_landing {
    margin: 55px 0 auto 20px !important;
  }
}

@media (max-width: 1100px) {
  .parent_landing {
    header {
      .nav_bar {
        display: none !important;
      }

      .btn_redirect {
        display: none !important;
      }

      .btn_redirect-mobile {
        display: block !important;
      }
    }
  }
}

@media (max-width: 820px) {
  .parent_landing {
    margin: 15px 0 0 0 !important;
    padding: 15px !important;
    border-radius: 25px !important;

    header {
      padding: 0 !important;
      margin-bottom: 10px !important;

      .nav_bar {
        display: none !important;
      }

      .btn_redirect-mobile {
        display: block !important;
      }
    }

    .preview_course {
      padding: 15px !important;
      min-height: 650px !important;
      max-height: 650px !important;
      border-radius: 25px !important;

      .info_course {
        padding: 15px !important;
        margin: 0 auto 0 auto !important;
        border-radius: 18px !important;

        .record {
          border-radius: 14px !important;
        }

        .title,
        .info {
          margin-bottom: 15px !important;
        }
      }

      .group {
        flex-direction: column;

        .left {
          margin-top: 40px !important;
          width: auto !important;
          text-align: center;

          .info {
            margin: auto;
            max-width: fit-content !important;
          }

          .btn_bottom {
            display: inline-block !important;

            .video_icon {
              display: none !important;
            }
          }
        }

        .right {
          margin-top: 40px !important;
          width: 100% !important;

          .image {
            margin: 0 auto !important;
            width: 540px !important;

            img {
              left: 25% !important;
            }
          }
        }
      }

      .course_figure6 {
        bottom: 70px !important;
        right: 50px !important;
      }

    }

    .preview_skills {
      border-radius: 25px !important;
    }

    .title_video {
      margin: 40px 0 15px 0 !important;
      font-size: 26px !important;
      line-height: 31px !important;
    }

    .video {

      video,
      iframe {
        border-radius: 25px !important;
      }
    }

    .spikers_new,
    .title_speakers {
      display: none !important;
    }

    .speakers_title-mobile {
      display: block !important;
    }

    .spikers_mobile-new {
      display: block !important;
    }

    .course_contains {
      margin-top: 40px !important;
      padding: 15px !important;
      border-radius: 25px !important;

      .h1 {
        font-size: 26px !important;
        line-height: 31px !important;
      }

      .info_row {
        margin-right: auto !important;
        width: fit-content !important;
        flex-direction: column;
        align-items: initial !important;

        .matherial {
          margin-bottom: 10px !important;
          flex-wrap: wrap;
        }

        .info {
          flex-wrap: wrap;

          .date_row {
            margin-bottom: 10px;
            height: fit-content;
          }
        }

      }

      .stages {
        .name {
          margin-top: 30px !important;
          font-weight: 500;
          font-size: 20px !important;
          line-height: 24px !important;
        }
      }

      .date_row {
        margin-right: 10px !important;
      }

      .academic_hours {
        margin-left: 0 !important;
      }
    }

    .format_study {
      display: none !important;
    }

    .format_study-mobile {
      display: block !important;
    }

    .course_figure6 {
      width: 40px !important;
      height: 40px !important;

      img {
        width: 15px;
        height: 19px;
        left: 12.5px !important;
        top: 10px !important;
      }
    }

    .who_suits {
      margin-top: 40px !important;
      justify-content: center !important;

      .title {
        margin-bottom: 20px !important;
        font-size: 28px !important;
        line-height: 31px !important;
      }

      .list {
        flex-direction: column !important;
        justify-content: center !important;

        li {
          padding: 15px !important;
          margin-left: auto !important;
          margin-right: auto !important;
          margin-bottom: 10px !important;
        }
      }
    }

    .what_learn {
      margin-top: 40px !important;
      padding: 0 !important;

      .title {
        margin-bottom: 15px !important;
        font-size: 26px !important;
        line-height: 31px !important;
      }

      .list {
        align-items: center;
        flex-direction: column !important;

        .check_icon {
          display: none !important;
        }

        .info {
          margin-bottom: 15px !important;
        }
      }

      .check_icon-mobile {
        display: block !important;
        margin-bottom: 10px;
      }
    }

    .footer {
      margin-top: 40px !important;
      padding: 15px !important;
      min-height: 650px !important;
      max-height: 650px !important;
      border-radius: 25px !important;

      .reuse_form {
        margin: auto !important;
        padding: 15px !important;
        border-radius: 18px !important;

        input,
        textarea,
        .btn_send {
          border-radius: 14px !important;
        }

        .h1,
        .info {
          margin-bottom: 15px !important;
        }

        .h1 {
          font-size: 32px !important;
          line-height: 38px !important;
        }
      }

    }

    .feedback {
      .title {
        font-weight: 600;
        font-size: 26px !important;
        line-height: 31px !important;
        text-align: center;
      }

      .card_feedback {
        display: block !important;
        margin: 0 auto !important;
        max-width: 70% !important;
      }

      .dots {
        display: block !important;
      }

      ul.ant-rate.ant-rate-disabled {
        top: -7px !important
      }
    }

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }

  .footer_bar {
    display: block !important;
  }

  .modal_feedback {
    width: calc(100% - 30px) !important;

    .ant-modal-body {
      padding: 15px !important;

      .close {
        top: 15px !important;
        right: 15px !important;
        height: 30px !important;
        width: 30px !important;

        img {
          height: 30px !important;
          width: 30px !important;
        }
      }

      .reuse_form {
        .h1 {
          font-size: 26px !important;
          line-height: 31px !important;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .parent_landing {

    .who_suits {
      display: none !important;
    }

    .who_suits-mobile {
      display: block !important;
    }

    .footer {
      padding: 20px !important;

      .h1 {
        white-space: inherit !important;
      }

      .reuse_form {
        margin: auto !important;
      }
    }

    .ant-modal.modal_pay {
      .modal_row-pay {
        .name_module {
          margin-right: 0 !important;
          font-size: 16px !important;
          line-height: 20px !important;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .ant-modal.modal_pay {
    display: none !important;
  }

  .ant-modal.modal_pay-mobile {
    display: block !important;
  }
}

@media (max-width: 650px) {
  .parent_landing {

    .who_suits-mobile {
      margin-top: 40px !important;
      // margin: 40px 20px 0 20px !important;
    }

    .what_learn {
      padding: 0 !important;
    }

    .feedback_mobile {
      margin: 40px 15px 0 15px !important;
    }
  }
}

@media (max-width: 580px) {
  .parent_landing {
    .info_course {
      .title {
        font-size: 36px !important;
        line-height: 36px !important;
      }
    }
  }
}

@media (max-width: 550px) {
  .parent_landing {
    header {
      padding: 0 !important;
    }

    .group_right {
      padding: 15px !important;
      border-radius: 40px !important;
    }

    .format_study-mobile {

      .slick-slider.format_study-slides-mobile {
        height: 410px !important;

        /*.slider_item {
          transform: scale(0.8) !important;
        }*/
      }

      .block_dots {
        margin-top: 40px !important;
      }
    }

    .preview_course {

      .info_course {
        padding: 15px !important;

        .title {
          text-align: center !important;
          font-size: 28px !important;
          line-height: 28px !important;
          font-weight: 400 !important;
        }

        .info {
          text-align: center !important;
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }

      .record {
        max-width: 100% !important;
        width: 100% !important;
      }
    }

    .preview_skills {
      border-radius: 16px !important;
    }

    .footer {

      .reuse_form {
        padding: 12px !important;
        margin: 0 auto 0 auto !important;

        .h1 {
          font-size: 32px !important;
          line-height: 32px !important;
        }

        .btn_send {
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .parent_landing {
    .spikers_mobile-new {
      img {
        min-width: initial !important;
        max-width: initial !important;
        min-height: initial !important;
        max-height: initial !important;
        width: inherit;
        height: auto;
      }
    }

    .footer {
      .reuse_form {
        .h1 {
          font-size: 26px !important;
          line-height: 31px !important;
        }
      }
    }

  }

  .ant-modal.modal_feedback {
    width: calc(100vw - 30px) !important;
  }
}

@media (max-width: 350px) {
  .parent_landing {
    header {
      margin-bottom: 10px !important;
      flex-direction: column !important;
      align-items: center !important;
    }

    .format_study-mobile {
      .slick-slider.format_study-slides-mobile {
        .slider_item {
          width: 100% !important;
          /* min-height: 400px !important;
           max-height: 400px !important;*/

          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.parent_landing {
  padding: 30px;
  margin: 55px auto auto auto;
  position: relative;
  max-width: 1305px;
  /*height: 100vh;*/
  width: 100%;
  /* max-height: calc(100vh - 68px);
   min-height: calc(100vh - 68px);*/
  color: var(--dark);
  background: #FFFFFF;
  /* border-radius: 35px; */
  /*overflow-y: scroll;*/

  header {
    padding: 0 60px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      line-height: 12px;

      span {
        font-size: 38px;
        font-weight: 500;
        line-height: 10px;
        vertical-align: middle;
        text-transform: uppercase;
      }

      svg {
        margin-top: 2px;
      }
    }

    .nav_bar {
      white-space: nowrap;
      align-self: center;

      li {
        display: inline-block;
        font-size: 18px;
        color: #757A88;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }

    .btn_redirect {
      padding: 13px;
      white-space: nowrap;
      max-width: 150px;
      width: 100%;
      text-align: center;
      background: #3077FF;
      box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 18px;
      color: white;
      cursor: pointer;
    }

    .btn_redirect-mobile {
      position: relative;
      display: none;
      width: 40px;
      height: 40px;
      background-color: #3077ff;
      box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      cursor: pointer;

      img {
        position: absolute;
        top: 11px;
        left: 11px;
      }
    }
  }

  .main {
    width: 100%;
    height: fit-content;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 8px 8px;
  }

  .drop_down-header {
    position: absolute;
    padding-top: 25px;
    border-top: 1px solid rgba(161, 163, 178, 0.3);
    height: 0;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transition: all .3s ease;

    .list {
      li {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #757A88;
        cursor: pointer;

        &:hover {
          color: darken(#757A88, 15%);
        }
      }
    }

    .btn_redirect-burger {
      padding: 13px 25px;
      margin: 0 auto 30px auto;
      width: fit-content;
      font-size: 16px;
      line-height: 19px;
      white-space: nowrap;
      text-align: center;
      background: #3077ff;
      box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 18px;
      color: white;
      cursor: pointer;
    }

    .h1 {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
    }

    .info {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
    }

    .reuse_form {
      padding: 14px 50px;
      margin-bottom: 30px;
      background: #C5F0F7;
      border-radius: 20px;

      .arrow_phone {
        top: 54px;
      }

      .btn_send {
        width: -webkit-fill-available;
      }
    }

    &.active {
      position: relative;
      visibility: inherit;
      height: auto;
      opacity: 1;
      transform: scale(1);
    }
  }

  .preview_course {
    width: 100%;
    min-height: 650px;
    max-height: 650px;
    display: flex;
    padding: 30px 27px;
    position: relative;
    overflow: hidden;
    background-size: cover !important;
    background-position: 100% 100% !important;
    background-repeat: no-repeat !important;
    border-radius: 35px;
    color: var(--dark);

    .btn_share {
      padding: 8px 15px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      opacity: 0.7;
      border-radius: 16px;
      background: var(--blue);
      cursor: pointer;
      color: white;
      transition: all .3s linear;

      &:hover {
        opacity: 1;
      }
    }

    .course_figure6 {
      position: fixed;
      width: 80px;
      height: 80px;
      bottom: 40px;
      right: 6%;
      border-radius: 50%;
      background-color: var(--dark);
      filter: drop-shadow(0px 30px 40px rgba(0, 0, 0, 0.3));
      z-index: 1;
      cursor: pointer;

      img {
        left: 25px;
        position: absolute;
        top: 20px;
      }
    }

    .info_course {
      margin: auto auto auto 0;
      max-width: 550px;
      padding: 40px;
      width: fit-content;
      height: fit-content;
      position: relative;
      background: rgba(52, 56, 67, 0.5);
      backdrop-filter: blur(2px);
      border-radius: 33px;
      color: white;


      .title {
        margin-bottom: 10px;
        font-size: 42px;
        font-weight: 600;
        line-height: 42px;
        overflow-wrap: break-word;
      }

      .info {
        margin-bottom: 20px;
        max-width: 600px;
        font-size: 16px;
        line-height: 19px;
        overflow-wrap: break-word;
      }

      .btn_bottom {
        display: flex;

        .record {
          padding: 10px;
          width: 280px;
          text-align: center;
          color: white;
          font-size: 16px;
          background: #3077FF;
          box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
          border-radius: 18px;
          cursor: pointer;
        }

        .video_icon {
          position: relative;
          margin-left: 15px;
          width: 45px;
          height: 45px;
          background-color: var(--dark);
          border-radius: 50%;
          cursor: pointer;
          filter: drop-shadow(10px 61px 25px rgba(0, 0, 0, 0.01)) drop-shadow(6px 34px 21px rgba(0, 0, 0, 0.05)) drop-shadow(3px 15px 15px rgba(0, 0, 0, 0.09)) drop-shadow(1px 4px 8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));

          img {
            position: absolute;
            top: 16px;
            left: 19px;
            vertical-align: middle;
          }
        }
        .price {
          margin-left: 20px;
          font-size: 26px;
          font-weight: 500;
          color: #30E0A1;
        }
      }

      &.right {
        margin: auto 0 auto auto;
      }
    }

    .btn_describe {
      position: absolute;
      bottom: 40px;
      right: 40px;
      padding: 10px;
      width: 240px;
      text-align: center;
      color: white;
      opacity: .7;
      font-size: 16px;
      background: #3077FF;
      box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.1), 6px 34px 21px rgba(0, 0, 0, 0.1), 3px 15px 15px rgba(0, 0, 0, 0.1), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: 18px;
      transition: all .2s linear;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .price_ {
      position: absolute;
      top: 60px;
      left: 20%;
      color: white;
      font-size: 24px;
      font-weight: 600;
    }

    &.default {
      .info_course {
        background-color: transparent;

        .record {
          background-color: var(--dark);
        }
      }
    }

    &.dark {
      .info_course {

        .title,
        .info {
          color: var(--dark) !important;
        }
      }
    }
  }

  .title_video {
    margin: 100px 0 40px 0;
    font-size: 48px;
    line-height: 57px;
    font-weight: 600;
    color: var(--dark);
  }

  .video {
    .react-player {
      width: 100% !important;
      height: 515px !important;
      margin-bottom: 0 !important;
    }

    video,
    iframe {
      border-radius: 35px;
    }
  }

  .preview_skills {
    padding: 25px 25px 10px 25px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
    background: var(--dark);
    border-radius: 35px;

    .skill {
      margin: 0 15px 15px 0;
      padding: 8px 20px 9px 20px;
      height: 39px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      background: rgba(22, 21, 27, 0.65);
      border: 1px solid #30E0A1;
      border-radius: 34px;
      color: white;

      span {
        line-height: 16.7px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .title_speakers {
    margin: 100px 0 40px 0;
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
    color: var(--dark);
  }

  .spikers_new {
    width: 100%;
    min-height: 350px;
    max-height: 350px;
    border-radius: 38px;
    background-color: var(--dark);

    .spikers_slides {
      height: 350px;
      border-radius: 35px;

      .slider_card {
        height: 100%;
        display: flex !important;
        color: white;

        .slider_image {
          max-height: 350px;
          max-width: 351px;
          min-height: 350px;
          min-width: 351px;
          border-radius: 35px;
          background-size: cover !important;
          background-position: center;
          background-color: darken(#343843, 10%);
        }
      }

      .slick-list {
        border-radius: 35px;
        height: 100%;
      }

      .info_speaker {
        padding: 23px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .title {
          margin-bottom: 20px;
          max-width: 305px;
          width: 100%;
          font-weight: 500;
          font-size: 32px;
          line-height: 38px;
        }

        .skills_list {
          margin-bottom: 15px;
          display: flex;
          flex-wrap: wrap;
          max-height: 110px;
          overflow-y: auto;

          .skill {
            margin: 0 15px 15px 0;
            padding: 8px 20px 9px 20px;
            height: 39px;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(22, 21, 27, 0.65);
            border: 1px solid #30E0A1;
            border-radius: 34px;

            span {
              line-height: 16.7px;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 9em;
            background: var(--background_lighten-dark);
          }
        }

        .name {
          margin: auto 0 15px 0;
          padding-bottom: 15px;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          border-bottom: 1px solid #757A88;
        }

        .position {
          display: flex;
          margin-bottom: 15px;

          span {
            line-height: 16px;
          }
        }

        .description {
          margin: 0;
          max-height: 90px;
          overflow-y: auto;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          white-space: break-spaces;
          transition: all .3s linear;

          &::-webkit-scrollbar-thumb {
            border-radius: 9em;
            background: var(--background_lighten-dark);
          }
        }
      }

      .slick-prev,
      .slick-next {
        position: absolute;
        top: 255px;
        right: 30px;
        width: 30px;
        height: 30px;

        img {
          width: 30px;
          height: 30px;
        }

        &::before {
          content: '';
        }
      }

      .slick-prev {
        left: initial;
        right: 80px;
        z-index: 1;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #757A88 !important;
    }
  }

  .spikers_mobile-new {
    display: none;
    color: white;

    .speakers_title-mobile {
      margin: 40px 0 15px 0;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      color: var(--dark);
    }

    .spikers_mobile-inner {
      padding: 25px 15px;
      margin: 0 auto;
      max-width: 345px;
      width: 100%;
      background: var(--dark);
      border-radius: 25px;

      .title {
        margin-bottom: 18px;
        max-width: 250px;
        width: 100%;
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;
      }

      img {
        margin: 15px 0;
        min-width: 315px;
        max-width: 315px;
        min-height: 260px;
        max-height: 260px;
        border-radius: 30px;
      }

      .skills_list {
        display: flex;
        flex-wrap: wrap;
        max-height: 70px;
        overflow-y: auto;
        user-select: none;

        .skill {
          margin: 0 10px 10px 0;
          padding: 0 20px 3px 20px;
          height: 28px;
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(22, 21, 27, 0.65);
          border: 1px solid #30E0A1;
          border-radius: 34px;

          span {
            font-size: 12px;
            line-height: 14px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &::-webkit-scrollbar-thumb {
          background: #757A88 !important;
        }
      }

      .name {
        margin: auto 0 15px 0;
        padding-bottom: 15px;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        border-bottom: 1px solid #757A88;
      }

      .position {
        margin-left: 1px;
        display: flex;
        margin-bottom: 15px;

        span {
          line-height: 16px;
        }
      }

      .description {
        margin: 0;
        max-height: 90px;
        overflow-y: auto;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        white-space: break-spaces;
        transition: all .3s linear;

        &::-webkit-scrollbar-thumb {
          background: #757A88 !important;
        }
      }
    }

  }

  .course_contains {
    margin-top: 100px;
    padding: 50px 60px;
    position: relative;
    width: 100%;
    border: 1px solid var(--dark);
    border-radius: 35px;

    .h1 {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 48px;
      line-height: 57px;
      color: var(--dark);
    }

    .info_row {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .matherial {
        display: flex;
        align-items: center;

        div {
          padding: 4px 10px;
          margin-right: 10px;
          background: rgba(162, 170, 202, 0.2);
          border-radius: 34px;

          span {
            font-size: 16px;
            line-height: 19px;
            color: #475067;
          }
        }
      }

      .info {
        display: flex;

        .date_row {
          margin-right: 10px;
          padding: 2.5px;
          display: flex;
          align-items: center;
          background: var(--dark);
          border-radius: 34px;

          .date {
            display: flex;
            align-items: center;

            img {
              height: 20.5px;
              width: 20.5px;
            }

            span {
              margin: 0 7px;
              color: white;
            }
          }
        }

        .academic_hours {
          padding: 2.5px 12px 2.5px 2.5px;
          display: flex;
          align-items: center;
          background: var(--dark);
          border-radius: 34px;
          height: fit-content;
          color: white;

          img {
            width: 21px;
            height: 20.5px;
          }

          span {
            margin-left: 6px;
          }
        }
      }
    }

    .text_description {
      padding: 1px;
      width: 100%;
      color: #757A88;
      font-size: 16px;
      line-height: 19px;
      max-height: 200px;
      white-space: break-spaces;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      overflow-y: hidden;
      transition: all .3s linear;
      overflow-wrap: break-word;

      a {
        color: blue;
      }

      &.open {
        max-height: 650px;
        overflow-y: auto;
      }

      &::-webkit-scrollbar-thumb {
        background: #757A88;
      }
    }

    .show_all {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #3077FF;
      width: fit-content;
      cursor: pointer;

      span {
        line-height: 16px;
      }
    }

    .title_stages {
      margin: 10px 0;
      font-weight: 500;
      font-size: 24px;
    }

    .stages {

      .name {
        margin-top: 10px;
        max-width: 550px;
        width: 100%;
        font-size: 16px;
        color: #757A88;

        &:first-child {
          margin-top: 30px;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: -2px;
      right: -1px;
      background: url("/images/landing/corner.svg");
      width: 62.5px;
      height: 63.5px;
      background-color: white;
    }
  }

  .format_study {
    margin: 100px 0 -20px 0;

    .title {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 48px;
      line-height: 57px;
    }

    .list {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      li {
        margin-right: 20px;
        margin-bottom: 20px;
        min-height: 466px;
        width: min-content;
        display: flex;
        flex-direction: column;
        border: 1px solid #757A88;
        border-radius: 30px;

        .top {
          padding: 15px;

          .title {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
          }

          .info {
            font-size: 16px;
            line-height: 19px;
            color: #757A88;
          }
        }

        .bottom {
          margin-top: auto;

          img {
            margin-left: -1px;
            margin-bottom: -1px;
          }
        }
      }
    }
  }

  .format_study-mobile {
    display: none;
    margin-top: 40px;

    .title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
    }

    .format_study-slides-mobile {
      .parent_slider-item {
        display: block !important;
      }

      .slider_item {
        margin: 0 auto;
        min-height: 430px;
        max-height: 430px;
        max-width: 300px;
        width: min-content;
        display: flex;
        flex-direction: column;
        border: 1px solid #757A88;
        border-radius: 30px;

        .top {
          padding: 15px;

          .title {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
          }

          .info {
            font-size: 16px;
            line-height: 19px;
            color: #757A88;
          }
        }

        .bottom {
          margin-top: auto;
        }
      }
    }

  }

  .who_suits {
    margin-top: 100px;
    margin-bottom: 40px;
    color: var(--dark);

    .title {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 48px;
      line-height: 57px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-bottom: 10px;
        margin-right: 20px;
        padding: 30px 20px;
        background: #D1F7FD;
        width: fit-content;
        max-width: 414px;
        min-width: 300px;
        border-radius: 30px;

        .number {
          position: relative;
          width: 41px;
          height: 41px;
          font-size: 20px;
          line-height: 24px;
          background-color: var(--dark);
          border: 1px solid #474C5A;
          border-radius: 50%;

          span {
            color: white;
            position: absolute;
            top: 7px;
            left: 13px;
          }
        }

        .to_whom {
          margin-top: 15px;
          max-width: 320px;
          width: 100%;
          font-weight: 500;
          font-size: 32px;
          line-height: 38px;

          span {
            white-space: nowrap;
          }
        }

        .info {
          margin-top: 8px;
          max-width: 350px;
          font-size: 16px;
          line-height: 19px;
          color: #757A88;
        }

        &:nth-child(2) {
          background: #B1E5ED;
        }

        &:nth-child(3) {
          background: #88CDD8;
        }

      }
    }
  }

  .who_suits-mobile {
    //margin: 40px 100px 0 100px;
    margin-top: 40px;
    display: none;

    .title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
    }

    .who_suits-slides-mobile {
      .slider_item {
        margin-right: 10px;
        padding: 15px 20px;
        background: #D1F7FD;
        border-radius: 25px;

        .number {
          position: relative;
          width: 35px;
          height: 35px;
          font-size: 16px;
          line-height: 19px;
          background-color: var(--dark);
          border: 1px solid #474C5A;
          border-radius: 50%;

          span {
            color: white;
            position: absolute;
            top: 6px;
            left: 12px;
          }
        }

        .to_whom {
          margin-top: 10px;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;

          span {
            white-space: nowrap;
          }
        }

        .info {
          margin-top: 8px;
          font-size: 14px;
          line-height: 17px;
          color: #757A88;
        }
      }
    }
  }

  .what_learn {
    margin-top: 100px;

    .title {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 48px;
      line-height: 57px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0 10px 10px 0;
        max-width: 400px;
        width: 100%;
        min-width: 300px;

        .parent_check {
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 100%;
        }

        .check_icon {
          margin-bottom: 15px;
          align-self: center;
        }

        .check_icon-mobile {
          display: none;
          align-self: center;
        }

        .skill {
          padding: 3px 15px 7px 15px;
          margin: 0 auto;
          margin-bottom: 10px;
          font-size: 20px;
          text-align: center;
          line-height: 24px;
          color: #23CD90;
          background: rgba(48, 224, 161, 0.2);
          border-radius: 34px;
        }

        .info {
          padding: 2px;
          margin-top: auto;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #757A88;
          height: 100%;
          white-space: pre-wrap;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        }

        .check_icon-mobile {
          display: none;
        }
      }
    }
  }

  .feedback {
    margin-top: 100px;

    .title {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 48px;
      line-height: 57px;
    }

    .card_feedback {
      position: relative;
      padding: 25px;
      max-width: 530px;
      height: inherit;
      min-height: 200px;
      /*max-height: 340px;*/
      background: var(--dark);
      border-radius: 30px;

      .title {
        display: flex;
        margin-bottom: 15px;

        img {
          margin-right: 15px;
          border-radius: 16px;
        }

        .right {
          .fio {
            margin-top: 10px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: white;
          }

          .rate {
            position: relative;

            .ant-rate {
              position: absolute;
              top: -17px;
              left: 0;
              font-size: 10px;
            }

            li.ant-rate-star {
              margin-right: 3px;
              margin-bottom: 0;
              background-color: transparent;
            }
          }
        }
      }

      .comment_feedback {
        font-size: 16px;
        color: white;
      }

      .remove {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--background_lighten-dark);
        transition: all .3s ease;
        cursor: pointer;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-color: lighten(#474c5a, 5%);
        }
      }
    }

    .feedback_slides {
      position: relative;

      .slick-track {
        display: flex;
      }

      .slick-slide {
        height: inherit;
        margin-right: 20px;

        &>div:first-child {
          height: 100%;
        }

        &.slick-active {
          height: inherit;
          margin-right: 20px;

          &>div:first-child {
            height: 100%;
          }
        }
      }

      .slick-prev,
      .slick-next {
        position: absolute;
        top: -70px;
        left: 84%;

        &::before {
          content: '';
        }

        &.slick-disabled {
          opacity: .5;
        }
      }

      .slick-prev {
        top: -70px;
        left: 80%;
        z-index: 1;
      }
    }

    .dots {
      display: none;
    }
  }

  .feedback_mobile {
    display: none;
    margin: 40px 100px 0 100px;

    .title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
    }

    .feedback-slides-mobile {

      .card_feedback {
        padding: 15px;
        margin-left: 10px;
        margin-right: 10px;
        max-width: 530px;
        min-height: 245px;
        max-height: 245px;
        background: var(--dark);
        border-radius: 30px;

        .title {
          display: flex;
          margin-bottom: 15px;

          img {
            margin-right: 15px;
            border-radius: 16px;
          }

          .right {
            .fio {
              margin-top: 10px;
              font-weight: 500;
              font-size: 22px;
              line-height: 26px;
              color: white;
            }

            .rate {
              position: relative;

              .ant-rate {
                position: absolute;
                top: -8px;
                left: 0;
                font-size: 10px;
              }

              li.ant-rate-star {
                margin-right: 3px;
                margin-bottom: 0;
                background-color: transparent;
              }
            }
          }
        }

        .comment_feedback {
          font-size: 14px;
          color: white;
        }

      }
    }
  }

  .footer {
    margin-top: 100px;
    min-height: 650px;
    max-height: 650px;
    display: flex;
    padding: 30px 27px;
    position: relative;
    overflow: hidden;
    background-size: cover !important;
    background-position: 100% 100% !important;
    background-repeat: no-repeat !important;
    border-radius: 35px;
    color: var(--dark);

    .reuse_form {
      margin: auto auto auto 0;
      padding: 30px;
      max-width: 504px;
      width: 100%;
      height: fit-content;
      background: rgba(52, 56, 67, 0.5);
      backdrop-filter: blur(2px);
      border-radius: 33px;
      transition: .1s ease;

      input,
      textarea {
        background: #FFFFFF;
        border-radius: 20px;
        border: none;
        color: var(--dark);
      }
    }

    &.right {
      .reuse_form {
        margin: auto 0 auto auto;
      }
    }
  }

  .block_dots {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9em;
    background: var(--background_lighten-dark);
  }
}

.btn_prev-landing {
  position: absolute;
  top: 12px;
  left: 265px;
  padding: 4px 15px 4px 30px;
  width: fit-content;
  background: var(--dark);
  border-radius: 20px;
  color: white;
  cursor: pointer;

  svg {
    position: absolute;
    top: 11px;
    left: 10px;
    transform: rotate(90deg);
  }
}

.footer_bar {
  display: none;
  width: calc(100% - 30px);
  z-index: 3;
  bottom: 5px !important;
}

.modal_feedback {
  position: relative;
  width: 100% !important;
  max-width: 525px;
  background: white;
  border: 1px solid var(--dark);
  box-shadow: 0px 30px 120px rgba(0, 0, 0, 0.99);
  border-radius: 25px;
  color: var(--dark);

  .title {
    margin-bottom: 20px;
    max-width: 300px;
    width: 100%;
    font-size: 24px;
    line-height: 29px;
    color: var(--dark);
  }

  .close {
    position: absolute;
    top: -30px;
    right: -30px;
    cursor: pointer;
  }


  .h1 {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;

  }

  .text_info {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
  }


  .btn_send {
    width: 100% !important;
    height: 40px;
    padding: 9px;
    text-align: center;
    background: #3077FF;
    box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
    border-radius: 16px;
    cursor: pointer;

    &.disabled {
      cursor: auto;
      opacity: .5;
    }
  }

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    background: transparent !important;
    box-shadow: none;

  }
}

.reuse_form {
  .h1 {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
  }

  .info {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
  }

  .feedbackForm {
    .ant-row.ant-form-item {
      margin-bottom: -2px;
    }
  }

  .arrow_phone {
    position: absolute;
    top: 51px;
    right: 3px;
    width: 50px;
    height: 40px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 16px;
      right: 20px;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .drop_down-phone {
    max-height: 0;
    opacity: 0;
    transform: scale(0);
    width: 100%;
    background-color: darken(#474c5a, 10%);
    border-radius: 0px 0px 17px 17px;
    transition: all .2s ease;
    z-index: 3;

    ul {
      padding: 0;
      margin: 0 auto;
    }

    li {
      padding: 5px 15px 5px 15px;
      margin: 0;
      font-size: 14px;
      // text-align: center;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: darken(#474c5a, 5%);
      }

      &:last-child {
        padding-top: 0;
        padding-bottom: 5px;
        border-radius: 0 0 17px 17px;
      }
    }

    &.active {
      margin-top: -15px;
      margin-bottom: 10px;
      min-width: 100px;
      max-height: 200px;
      transform: scale(1);
      opacity: 1;
    }
  }

  .phoneNumber {
    margin: 15px 0;
    padding: 9px 15px 9px 40px;
    font-size: 14px;
    line-height: 17px;
    border: none;
    outline: none;
    background: #343843;
    border: 1px solid #474C5A;
    border-radius: 16px;
    color: white;
    transition: all .2s ease;

    &.active {
      border: none;
      border-radius: 16px 16px 0 0;
    }

    &:focus:before {
      content: '';
      width: 21px;
      height: 15px;
      // background: url("../images/");
    }
  }

  .icon_country {
    position: absolute;
    top: 59px;
    left: 15px;
  }

  .btn_send {
    margin-top: 15px;
    padding: 8px;
    width: 220px;
    font-size: 16px;
    color: white !important;
    background: #3077FF !important;
    box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 18px;
    border: none;
    --antd-wave-shadow-color: transparent;

    &.disabled {
      opacity: .5;
    }
  }

  .deal {
    padding-bottom: 10px;
    margin-top: 15px;
    font-size: 14px;
    line-height: 17px;
    color: #757A88;
    border-bottom: 1px solid #757A88;
  }

  .cost {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;

    .left_cost {
      align-self: end;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-decoration: line-through;
      color: #757A88;
    }

    .right_cost {
      padding: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: var(--dark);
  }

}


@media (max-width: 820px) {
  .modal_feedback {
    width: calc(100% - 30px) !important;
  }
}