.tag {
  padding: 6px 35px 6px 10px;
  margin-right: 5px;
  margin-bottom: 10px;
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  line-height: 17px;
  background: rgba(22, 21, 27, 0.65);
  border: 1px solid var(--blue);
  border-radius: 29px;

  span {
    font-size: 14px;
    line-height: 16px;
    color: white;
  }

  img {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .label {
    padding: 1.5px 7px;
    position: absolute;
    display: flex;
    align-items: center;
    width: fit-content;
    top: -15px;
    right: -20px;
    border-radius: 17px;
    background: #757A88;

    .count {
      font-size: 10px;

      &:nth-child(2) {
        margin-right: 3px;
      }
    }

    .figure {
      margin-right: 3px;
      width: 5px;
      height: 5px;
      min-width: 5px;
      min-height: 5px;
      background: white;
      border-radius: 50%;

      &:nth-child(3) {
        background: var(--text_color_dark);
      }
    }
  }

  &.without_img {
    padding: 6px 10px;
  }

  &.border {
    border-color: #30E0A1;
  }

  &.color_active {
    background: rgba(48, 224, 161, 0.2);
    border: 1px solid #30E0A1;
    color: #30E0A1;
  }

  &.cursor_pointer {
    cursor: pointer;
  }

  &.exist_quest {
    padding-right: 30px;
    border: 1px solid transparent;

    img {
      margin-left: 5px;
      display: block;
    }
  }

  &.margin_right {
    margin-right: 27px;
    margin-bottom: 15px;
  }
}