.input_parent {
  position: relative;
  height: fit-content;

  .input_custom {
    position: relative;
    width: 100%;
    outline: none;
    transition: all .3s linear;
    border: 1px solid transparent;
    font-size: 16px;
    color: white;

    &:hover {
      border-color: var(--text_color_dark) !important;
    }

    &:active {
      border-color: var(--text_color_dark) !important;
      outline: none;
    }

    &:focus {
      border-color: var(--text_color_dark) !important;
      outline: none;
      box-shadow: none;
    }

    &.error {
      border-color: red;
    }

    &.disabled {
      color: var(--text_color_dark);
    }

    &.z_index_0 {
      z-index: 0;
    }

    &::placeholder {
      color: #757A88;
      font-size: 16px;
    }
  }

  .btn_find {
    position: absolute;
    padding: 4px 25px 5px 25px;
    display: flex;
    justify-content: center;
    top: 4px;
    right: 4px;
    max-width: 90px;
    background: var(--dark);
    border-radius: 13px;
    cursor: pointer;
  }

  .icon_find {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  .icon_find-mobile {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 4;
  }
}

