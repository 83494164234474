@media (max-width: 1445px) {
  .modal_course {
    margin: -80px 15px auto 5px !important;
  }
}

@media (max-width: 820px) {
  .modal_course {
    .title {
      font-size: 24px !important;
      line-height: 29px !important;
      margin-bottom: 10px !important;

      &_info {
        font-size: 12px !important;
        border: none !important;
      }
    }

    .sort {
      display: none !important;
    }

    .sort_mobile {
      display: block !important;
    }

    .traektory {
      &_title {
        margin-top: 15px !important;
        font-size: 16px !important;
        line-height: 16px !important;
      }
    }

    .close_title {
      width: 24px;
      height: 24px;
    }

    .ant-modal-body {
      padding: 20px 15px !important;
    }
  }
}

@media (max-width: 710px) {
  .modal_course {
    .course_list {
      li {
        margin-right: 0 !important
      }
    }
  }
}

.modal_course {
  max-width: 1415px !important;
  width: 100% !important;
  margin: 30px auto auto auto;
  /*  width: 100% !important;*/
  // margin-top: -65px;
  // margin-left: 30px;
  //  margin-right: 30px;
  color: white;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    margin: 0 15px !important;
    background: #201E26 !important;
    box-shadow: none;
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 40px;
    line-height: 48px;

    &_info {
      width: 100%;
      padding-bottom: 20px;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid #464C5A;
    }

    img {
      cursor: pointer;
    }
  }

  .sort {
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
    padding-bottom: 20px;
    font-size: 16px;
    border-bottom: 1px solid #464C5A;

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      cursor: pointer;

      &:not(first-child) {
        margin-left: 20px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.5)
      }

      &.active {
        color: #B3DAFF;
      }
    }
  }

  .sort_mobile {
    position: relative;
    padding: 8px 15px;
    background: #343843;
    border-radius: 17px;
    cursor: pointer;

    svg {
      position: absolute;
      top: 17px;
      right: 15px;

      &.active {
        transform: rotate(180deg);
      }
    }

    &.active {
      border-radius: 17px 17px 0 0;
    }
  }

  .sort_mobile-drop-down {
    max-height: 0;
    opacity: 0;
    // transform: scale(0);
    transition: all .1s ease;

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    li {
      padding: 6px 15px;
      background-color: #4B505C;

      &:hover {
        background-color: lighten(#4B505C, 5%);
      }

      &:last-child {
        border-radius: 0 0 16px 16px;
      }
    }

    &.active {
      max-height: fit-content;
      opacity: 1;

      li {
        cursor: pointer;
      }

      // transform: scale(1);
    }
  }

  .traektory {

    &_title {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 29px;
    }

    &_basket {
      padding: 12px 12px 7px 12px;
      margin-bottom: 30px;
      max-height: 90px;
      overflow: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background: #343843;
      border-radius: 20px;

      .skills {
        padding: 4px 5px 5px 12px;
        margin: 0 5px 10px 0;
        position: relative;
        display: flex;
        align-items: center;
        width: -moz-fit-content;
        width: fit-content;
        background: rgba(22, 21, 27, 0.65);
        border-radius: 29px;

        img {
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .course_list_modal {
    margin-left: 0;
    max-width: 1304px;
    width: 100% !important;

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .reuse_course-item {
      margin: 0 0 10px 0;
      min-height: 550px;
    }

    .pagination_all {
      margin: 0 auto;

      ul {
        display: block;
      }

      .ant-pagination-item {
        margin-right: 5px;
        background-color: transparent;
        border: none;

        a {
          border-radius: 8px !important;
          color: white;
          background-color: var(--dark);
        }

        &-active {
          a {
            background-color: lighten(#343843, 20%);
          }

          border-color: transparent;
        }
      }

      li.ant-pagination-options {
        display: none;
      }

      li.ant-pagination-prev,
      li.ant-pagination-next {
        display: none;
      }

      span.ant-pagination-item-ellipsis,
      span.anticon.anticon-double-right.ant-pagination-item-link-icon,
      span.anticon.anticon-double-left.ant-pagination-item-link-icon {
        color: #61687c;
      }
    }

    .ant-spin.ant-spin-spinning {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    /*li {
      width: min-content;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      background: #343843;
      border-radius: 30px;

      .rate {
        position: absolute;
        padding: 4px 14px;
        top: 25px;
        left: 22px;
        background: rgba(32, 30, 38, 0.6);
        border-radius: 34px;

        .ant-rate {
          font-size: 10px;
        }

        li.ant-rate-star {
          margin-right: 3px;
          margin-bottom: 0;
          background-color: transparent;
        }
      }

      .popular {
        position: absolute;
        top: 23px;
        right: 22px;
        display: flex;
        padding: 2px 3px;
        background: rgba(32, 30, 38, 0.6);
        border-radius: 34px;

        span {
          margin: 0 10px;
          font-size: 14px;
        }
      }

      .category {
        display: flex;
        margin: 0 20px 15px 20px;
        padding: 4px 10px;
        width: fit-content;
        border-radius: 34px;

        img {
          margin-right: 10px;
          align-self: center;
        }

        span {
          font-size: 14px;
        }
      }

      .info {
        margin: 0 20px 7px 20px;
        max-width: 280px;
        font-size: 20px;
        line-height: 24px;
      }

      .stage {
        ul, li {
          padding: 0;
          margin: 0;
        }

        ul {
          margin: 0 20px 5px 20px;
        }

        li {
          white-space: nowrap;
          font-size: 16px;
          color: var(--text_color_dark);

          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }

      .title_skill {
        margin: 0 20px 10px 20px;
        font-size: 16px;
      }

      .key_skills {
        margin: 0 20px 10px 20px;
        max-height: 140px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        .skills {
          display: flex;
          padding: 4px 10px 4px 10px;
          margin-bottom: 5px;
          position: relative;
          flex-wrap: wrap;
          color: var(--text_color_dark);
          font-size: 14px;
          background: rgba(162, 170, 202, 0.2);
          border-radius: 34px;

          img {
            position: absolute;
            top: 8px;
            right: 5px;
            cursor: pointer;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }
        }
      }

      .date_course {
        padding: 3px;
        margin: 0 20px 10px 20px;
        width: fit-content;
        background: rgba(32, 30, 38, 0.6);
        border-radius: 34px;

        img {
          width: 19px;
          height: 19px;
          vertical-align: sub;
        }

        span {
          margin: 0 7px;
          font-size: 14px;
        }
      }

      .bottom_block {
        margin-top: auto;

        a {
          color: white;
        }
      }

      .cost {
        margin: 0 20px 15px 20px;
        font-size: 16px;

        span {
          margin-right: 5px;
        }
      }

      .btn_open-course {
        margin: 0 20px 15px 20px;
        padding: 12px;
        text-align: center;
        font-size: 14px;
        background: #3077FF;
        box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
        border-radius: 15px;
        cursor: pointer;
      }
    }*/
  }
}