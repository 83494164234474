.button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
  background: #3077FF;
  border-radius: 15px;
  transition: all .3s linear;
  white-space: nowrap;
  outline: none;
  border: none;
  cursor: pointer;

  .text_in {
    font-size: 14px;
    line-height: 16px;
    color: white;
    user-select: none;
  }

  &.disabled {
    cursor: auto;
    opacity: .5;
  }

  &:hover {
    background-color: #3077FF !important;
  }

  &:focus {
    outline: none;
  }
}