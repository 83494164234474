.course_manage {
  max-width: 1425px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  color: white;

  .inner_right {
    padding-right: 5px;
    margin: auto 0;
    max-width: 1320px;
    width: 100%;
    max-height: calc(100vh - 30px);
    min-height: calc(100vh - 30px);
    overflow-y: auto;
    overflow-x: hidden;

    .timer_parent {
      margin-top: 15px;
    }

    .top_navigation {
      display: flex;
      justify-content: space-between;

      .btn_prev {
        padding: 1px 15px 2px 30px;
        margin-top: 9px;
        height: 25px;
        width: fit-content;
        position: relative;
        background: var(--dark);
        border-radius: 20px;

        svg {
          position: absolute;
          top: 9px;
          left: 12px;
          transform: rotate(90deg);
          cursor: pointer;
        }
      }

      .group {
        display: flex;
        max-width: 540px;
        margin-left: 10px;
        width: 100%;
        height: 25px;
        align-self: end;
        background: rgba(162, 170, 202, 0.2);
        border: 1px solid var(--text_color_dark);
        border-radius: 15px;

        div {
          width: 100%;
          text-align: center;
          color: #757A88;
          transition: all .7s ease;
          white-space: nowrap;
          cursor: pointer;

          &.active {
            background: #3077FF;
            border-radius: 26px;
            color: white;
          }
        }
      }

      .empty {
        max-width: 140px;
        width: 100%;
      }
    }

    .top_filter-mobile {
      margin-top: 15px;
      margin-bottom: 20px;
      padding: 4px;
      display: none;
      justify-content: space-between;
      background: #343843;
      border-radius: 34px;
      transition: all .2s ease;

      .icon {
        display: flex;
      }

      .filters_mobile {
        display: flex;
        margin: 0 auto;
        align-self: center;
        transition: all .2s ease;

        .search_,
        .btn_share {
          position: relative;
          width: 62px;
          height: 34px;
          border: 4px solid #3077FF;
          border-radius: 14px;
          background: #27292F;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 19px;

            path {
              stroke: white;
            }
          }
        }

        .btn_share {
          border: 4px solid #30E0A1;
        }

        div:not(:first-child) {
          margin-left: 5px;
        }
      }

      .settings {
        align-self: center;
      }

      &.hidden {
        .filters_mobile {
          display: none;
          height: 0;
          transform: scale(0);
        }
      }
    }

    .search_mobile {
      position: relative;
      display: none;
      border-radius: 14px;
      max-height: 0;
      max-width: 0;
      opacity: 0;
      transition: all .3s ease;

      .svg {
        position: absolute;
        top: 2px;
        left: 5px;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        margin-right: 10px;
        background: #27292F;
        border-radius: 13px;
        z-index: 4;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .input_search-mobile {
        padding-left: 35px;
        background: rgba(161, 163, 178, 0.2);
        border: 1px solid #A2AACA !important;

        &:hover {
          //border: 1px solid #A2AACA !important;
        }
      }

      span {
        margin-left: 15px;
        align-self: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
      }

      &.active {
        display: flex;
        align-self: center;
        max-height: 34px;
        max-width: fit-content;
        opacity: 1;
      }
    }

    .top_title {
      width: 100%;
      //padding-bottom: 10px;
      //margin-bottom: 20px;
      //border-bottom: 1px solid var(--dark);
    }

    .lesson_block {
      .group_block {
        display: flex;
        margin-bottom: 20px;

        .add_lesson {
          padding: 4px;
          padding-right: 12px;
          margin-right: 15px;
          display: flex;
          background: #3077FF;
          border-radius: 17px;
          cursor: pointer;

          img {
            width: 30px;
            height: 30px;
            align-self: center;
            margin-right: 10px;
          }

          div {
            white-space: nowrap;
            align-self: center;
          }
        }

        .search {
          margin-left: 0;
          width: 60%;
        }

        .reuse_drop-down {
          max-width: 23%;
          width: 100%;

          .drop_down-category {
            max-height: 140px;
            display: none;

            &.open {
              display: block;
            }
          }
        }

        .btn_share {
          display: flex;
          margin-left: 10px;
          padding: 4px;
          max-width: 150px;
          width: 100%;
          background: #30E0A1;
          border-radius: 17px;
          color: var(--dark);
          cursor: pointer;

          div {
            position: relative;
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            align-self: center;
            margin-right: 10px;
            background: #27292F;
            border-radius: 13px;

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 19px;

              path {
                stroke: white;
              }
            }
          }

          span {
            margin-left: 10px;
            align-self: center;
          }
        }
      }

      .lesson_row {
        margin-bottom: 15px;
        padding: 8px 30% 0 18px;
        position: relative;
        display: flex;
        width: 100%;
        height: 40px;
        background: var(--dark);
        border: 1px solid var(--background_lighten-dark);
        border-radius: 16px;
        cursor: pointer;
        transition: all .3s linear;

        .eye {
          padding: 5px;
          border-radius: 50%;
          background-color: #201E26;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all .3s linear;
          transform: rotate(180deg);
        }

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        .group_icon {
          position: absolute;
          display: flex;
          align-items: center;
          top: -2px;
          right: 15px;
          z-index: 3;

          .img_edit,
          .img_remove {
            position: relative;
            cursor: pointer;
            height: 40px;
            width: 40px;
            z-index: 5;

            img {
              position: absolute;
              top: 12px;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }

          .img_edit {
            img {
              top: 13px;
            }
          }

          .ant-spin.ant-spin-spinning {
            margin: 5px 20px 0 0;

            span {
              overflow: initial;
            }
          }

          &.hide {
            display: none;
          }
        }

        &.hide {
          display: none;
        }

        &:hover {
          background: transparent;
          box-shadow: 0 5px 5px 0 rgb(35, 35, 35);
        }
      }
    }

    .ant-image-mask {
      border-radius: 20px;
    }
  }

  .ant-tabs {
    color: #757a88;
  }
  .ant-tabs-tab:hover {
    color: #8146ff;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 2px solid #343843;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #8146ff;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    background: #8146ff;
    height: 6px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.modal_info {
  /* top: 30px;*/
  max-width: 600px;
  width: 100% !important;
  color: white;

  .ant-modal-body {
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    background: #201E26;
    border-radius: 16px;

    .close {
      position: absolute;
      margin-left: auto;
      top: 20px;
      right: 30px;
      cursor: pointer;
    }

    .info {
      margin-top: 20px;
      font-size: 20px;
      line-height: normal;
      text-align: center;
    }

    .buttons {
      margin-top: 25px;
      display: flex;
      align-items: center;

      .button {

      }
    }
  }
}

@media (max-width: 1150px) {
  .course_manage {
    .top_navigation {
      .empty {
        display: none !important;
      }
    }
  }

}

@media (max-width: 820px) {
  .course_manage {
    .inner_right {
      padding-bottom: 100px !important;

      .top_title {
        margin-top: 10px;
        padding-bottom: 0 !important;
        border-bottom: none !important;

        span {
          font-size: 24px !important;
        }
      }
    }

    .top_title {
      margin-bottom: 10px !important;
    }

    .top_filter-mobile {
      display: flex !important;
    }

    .top_navigation {
      .btn_prev {
        margin-top: 0px !important;
      }

      /*.group {
        display: none !important;
      }*/
    }

    .group_block {
      display: none !important;
    }

    .footer_bar {
      display: block !important;
    }
  }
}

@media (max-width: 550px) {
  .course_manage {
    .top_navigation {
      flex-direction: column;

      .group {
        margin: 15px 0 0 0 !important;
      }
    }
  }
}

