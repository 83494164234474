.add_course-parent {
  display: flex;
  width: 100%;
  color: white;

  .h2 {
    //margin-bottom: 25px;
    font-size: 32px;//24px;
    //line-height: 29px;
  }

  .group {
    padding-right: 5px;
    margin-top: 17px;
    width: 100%;

    .remove_course {
      position: absolute;
      top: 50px;
      right: 50px;
      width: 80px;
      height: 80px;
      background-color: #FF3434;
      border-radius: 50%;
      cursor: pointer;
      z-index: 4;

      .group_image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%);

        svg {
          position: absolute;

          &:nth-child(2) {
            top: 15px;
            left: 13px;
          }
        }

      }
    }

    .top_navigation {
      //margin-bottom: 15px;
      margin-right: 30px;
      display: flex;
      justify-content: space-between;

      .btn_prev {
        padding: 1px 15px 2px 30px;
        margin-top: 9px;
        height: 25px;
        width: fit-content;
        position: relative;
        background: var(--dark);
        border-radius: 20px;

        svg {
          position: absolute;
          top: 9px;
          left: 12px;
          transform: rotate(90deg);
          cursor: pointer;
        }
      }

      .group {
        display: flex;
        max-width: 540px;
        padding-right: 0;
        margin-top: 0;
        margin-left: 10px;
        width: 100%;
        height: 25px;
        align-self: end;
        background: rgba(162, 170, 202, 0.2);
        border: 1px solid var(--text_color_dark);
        border-radius: 15px;

        div {
          width: 100%;
          text-align: center;
          color: #757A88;
          transition: all .7s ease;
          white-space: nowrap;
          cursor: pointer;

          &.active {
            background: #3077FF;
            border-radius: 26px;
            color: white;
          }
        }
      }

      .empty {
        max-width: 150px;
        width: 100%;
      }
    }

    .inner {
      min-width: 580px;
      width: 100%;

      .steps {
        display: flex;
        max-width: 580px;
        padding: 15px 20px;
        margin-bottom: 25px;
        width: 100%;

        background: #201F26;
        border-radius: 25px;

        .step {
          position: relative;
          margin-right: 10px;
          width: 40px;
          height: 40px;
          align-self: center;
          background: var(--dark);
          border: 1px solid var(--background_lighten-dark);
          border-radius: 50%;

          span {
            position: absolute;
            font-size: 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:not(:first-child) {
            margin-left: 10px;
          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background: #3077FF;
            box-shadow: 0px 9px 10px rgba(48, 119, 255, 0.27);
            border-color: transparent;
          }
        }

        .line {
          align-self: center;
          max-width: 190px;
          width: 100%;
          height: 1px;
          background-color: var(--dark);
        }
      }

      .step_1 {

        .h2 {
          margin: 0 0 15px 15px;
          font-size: 20px;
          max-width: 580px;
          line-height: 24px;
        }

        .cards_course-parent {
          display: flex;
          margin-bottom: 40px;

          .cards_course {
            padding: 20px;
            max-width: 580px;
            width: 100%;
            background: #201F26;
            border-radius: 25px;

            .title {
              margin: 0 0 10px 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              width: fit-content;
              color: var(--text_color_dark);
              font-size: 14px;
              white-space: nowrap;

              span {
                line-height: 16.71px;
              }

              svg {
                margin-left: 10px;
                cursor: help;
              }

            }

            .form-group {
              margin: 0 0 0 15px;

              label:before {
                margin-bottom: 1px;
                border-color: var(--text_color_dark);
                background: transparent;
                box-shadow: none;
              }

              label:after {
                margin-bottom: 1px;
                border-color: var(--text_color_dark);
                box-shadow: none;
              }
            }

            .drop_down {
              position: relative;
              padding: 8px 15px 8px 15px;
              background: #8146FF;
              border-radius: 16px;
              cursor: pointer;
              color: white;
              font-size: 14px;
              z-index: 2;

              img {
                margin-right: 14px;
                width: 16px;
                height: 16px;
              }

              span {
                vertical-align: middle;
              }

              .arrow {
                position: absolute;
                top: 9px;
                right: 18px;
                transition: transform .3s;

                &.tranform {
                  top: 12px;
                  transform: rotate(180deg);
                }
              }

              &-list {
                width: 100%;
                padding-right: 17px;
                max-height: 0;
                transform: scale(0);
                background-color: #8146FF;
                border-radius: 0 0 16px 16px;
                transition: all .3s ease;
                z-index: 2;
                user-select: none;

                ul {
                  margin: 0;
                  padding: 0;
                  padding-top: 10px;
                  max-height: 0px;
                  opacity: 0;
                  z-index: -1;
                  transition: max-height .5s, opacity .5s, z-index .5s;
                }

                li {
                  padding: 11px 0;
                  border-top: 1px solid rgba(255, 255, 255, 0.2);
                  color: white;
                  font-size: 14px;
                  cursor: pointer;

                  &:last-child {
                    padding-bottom: 0;
                  }
                }

                &.active {
                  transition: max-height .5s, opacity .5s, z-index .5s;
                  max-height: 340px;
                  opacity: 1 !important;
                  z-index: 3;
                  transform: scale(1);

                  ul {
                    transition: max-height .5s, opacity .5s, z-index .5s;
                    max-height: 340px;
                    opacity: 1;
                    z-index: 3;
                  }
                }
              }
            }

            .input_custom-parent {
              margin-bottom: 15px;

              .title_info {
                margin-bottom: 10px;

                span {
                  margin-bottom: 0;
                  line-height: 16.71px;
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }

            .demo_row {
              display: flex;
              justify-content: space-between;
              margin: 15px 0;

              span {
                align-self: center;
                color: var(--text_color_dark);
              }

              .group_demo {
                display: flex;
                max-width: 303px;
                margin-top: 0;
                width: 100%;
                height: 22px;
                align-self: end;
                background: rgba(162, 170, 202, 0.2);
                border: 1px solid var(--text_color_dark);
                border-radius: 15px;

                div {
                  width: 100%;
                  text-align: center;
                  color: #757A88;
                  transition: all .7s ease;
                  font-size: 12px;
                  white-space: nowrap;
                  cursor: pointer;

                  &.active {
                    background: #3077FF;
                    border-radius: 26px;
                    color: white;
                  }

                }
              }
            }

            .draggable {
              .label_crop {
                height: 77px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px dashed transparent;
                text-align: center;
                background: var(--dark);
                border-radius: 10px;
                cursor: pointer;

                img {
                  margin-bottom: 5px;
                  width: 26px;
                  height: 20px;
                  user-select: none;
                }

                span {
                  font-size: 14px;
                  line-height: 19px;
                  color: #757A88;
                  user-select: none;
                }
              }

              .ant-upload.ant-upload-drag {
                background-color: var(--dark);
                border: 1px dashed transparent;

                &:hover {
                  border-color: var(--background_lighten-dark);
                }
              }

              .ant-upload-list-item {
                background-color: var(--dark);
                color: white;
                border: none;

                a {
                  color: white;
                }

                span.anticon.anticon-delete {
                  svg {
                    color: white;
                  }
                }

              }

              .text {
                font-size: 14px;
                line-height: 19px;
                color: #757A88;
              }
            }

            .date_range {
              margin-bottom: 15px;

              .date_range-antd {
                width: 100%;
              }

              .ant-picker.ant-picker-range {
                padding: 8px 10px;
                background-color: var(--dark);
                border: 1px solid var(--background_lighten-dark);
                border-radius: 16px;

              }

              .ant-picker.ant-picker-range.ant-picker-focused {
                border-color: var(--text_color_dark) !important;
                outline: none !important;
                box-shadow: none;
              }

              .ant-picker-active-bar {
                background-color: var(--text_color_dark);
              }

              span.anticon.anticon-swap-right {
                color: white;
              }

              span.ant-picker-suffix {
                color: white;
              }
            }

            .input_classic {
              height: 40px;
            }
          }

          .parent_course-card {
            margin-left: 30px;
          }
        }

        .landing_parent {
          margin-bottom: 40px;
          display: flex;
          position: relative;

          .landing_block {
            padding: 20px;
            position: relative;
            max-width: 580px;
            width: 100%;
            background: #201F26;
            border-radius: 25px;
            transition: all .3s linear;

            .title_info {
              margin: 0 0 10px 15px;
              display: flex;
              align-items: center;
              color: var(--text_color_dark);

              .form-group {
                margin: 0 0 0 auto;

                label:before {
                  margin-bottom: 1px;
                  border-color: var(--text_color_dark);
                  background: transparent;
                  box-shadow: none;
                }

                label:after {
                  margin-bottom: 1px;
                  border-color: var(--text_color_dark);
                  box-shadow: none;
                }
              }

              svg {
                margin-left: 10px;
                cursor: help;
              }

              span {
                line-height: 16.71px;
              }

              &.hide {
                transition: opacity, visibility, max-height .1s linear;
                position: absolute;
                opacity: 0;
                visibility: hidden;
                max-height: 0;
              }
            }

            .demo_row {
              margin-bottom: 10px;
              display: flex;
              width: 100%;
              height: 22px;
              align-self: end;
              background: rgba(162, 170, 202, 0.2);
              border: 1px solid var(--text_color_dark);
              border-radius: 15px;
              transition: all .3s linear;
              user-select: none;

              div {
                width: 100%;
                text-align: center;
                color: #757A88;
                font-size: 12px;
                white-space: nowrap;
                transition: all .3s ease;
                cursor: pointer;

                &.active {
                  background: #3077FF;
                  border-radius: 26px;
                  color: white;
                }
              }

              span {
                align-self: center;
                color: var(--text_color_dark);
              }

              &.border {
                border-color: #3077FF;
              }
            }

            .draggable {
              margin-bottom: 15px;

              .ant-upload.ant-upload-drag {
                background-color: var(--dark);
                border: 1px dashed transparent;

                &:hover {
                  border-color: var(--background_lighten-dark);
                }
              }

              .ant-upload-list-item {
                background-color: var(--dark);
                color: white;
                border: none;

                a {
                  color: white;
                }

                span.anticon.anticon-delete {
                  svg {
                    color: white;
                  }
                }

              }

              .text {
                font-size: 14px;
                line-height: 19px;
                color: #757A88;
              }
            }

            .crop_image {
              .label_crop {
                height: 77px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px dashed transparent;
                text-align: center;
                background: var(--dark);
                border-radius: 10px;
                cursor: pointer;

                img {
                  margin-bottom: 5px;
                  width: 26px;
                  height: 20px;
                  user-select: none;
                }

                span {
                  font-size: 14px;
                  line-height: 19px;
                  color: #757A88;
                  user-select: none;
                }
              }

              .ant-upload {
                visibility: hidden;
                opacity: 0;
                position: absolute;
              }

              .ant-upload-list-item {
                background-color: var(--dark);
                color: white;
                border: none;
              }

              .ant-upload-list-item-progress {
                width: 90%;
              }

              span.anticon.anticon-delete {
                color: white;
              }

              a {
                color: white;
              }
            }

            .textarea_parent {
              position: relative;

              .resize {
                position: absolute;
                right: -1px;
                bottom: 14px;
                display: flex;
                flex-direction: column;
                pointer-events: none;
                background-color: var(--dark);
                border-radius: 10px;

                svg:first-child {
                  margin-bottom: 2px;
                  transform: rotate(180deg);
                }
              }
            }

            ::-webkit-scrollbar {
              width: 8px;
              height: 4px;
            }

            ::-webkit-scrollbar-thumb {
              background: linear-gradient(rgba(162, 170, 202, 0), rgba(128, 137, 158, 1));
              border-radius: 9em;
            }

            .ant-input[disabled] {
              background: var(--dark);
              border-color: transparent;
            }

            textarea.ant-input.textarea_antd {
              resize: vertical;
              max-height: inherit;
              min-height: 76px;

              &:not(:last-child) {
                margin-bottom: 15px;
              }

              &.hide {
                transition: opacity, visibility, max-height .1s linear;
                position: absolute;
                opacity: 0;
                visibility: hidden;
                max-height: 0;
                max-width: 540px;
                left: 0;
              }

              &.description {
                min-height: 120px;

              }

              &.contain {
                min-height: 220px;
              }

            }
          }

          .parent_banner-preview {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            width: 100%;
            right: 0;
            max-height: 417px;
            transition: all .5s linear;

            .btn_share {
              padding: 8px 15px;
              position: absolute;
              top: 20px;
              right: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: fit-content;
              opacity: 0.7;
              border-radius: 16px;
              background: var(--blue);
              cursor: pointer;
              color: white;
              transition: all .3s linear;

              &:hover {
                opacity: 1;
              }
            }

            .banner_preview {
              padding: 0 30px;
              display: flex;
              position: relative;
              max-height: 417px;
              height: 100%;
              width: 100%;
              background-size: 100% 100% !important;
              background-position: center;
              background-repeat: no-repeat;
              border-radius: 30px;
              color: white;

              .label {
                position: absolute;
                font-size: 20px;
                line-height: 24px;
                top: -40px;
                left: 15px;
              }

              .banner {
                margin: auto auto auto 0;
                padding: 20px;
                position: relative;
                max-width: 300px;
                width: 100%;
                height: fit-content;
                backdrop-filter: blur(10px);
                border-radius: 33px;
                background: rgba(52, 56, 67, 0.5);

                .title {
                  margin-bottom: 10px;
                  font-size: 32px;
                  font-weight: 600;
                  line-height: 32px;
                  overflow-wrap: break-word;
                }

                .info {
                  margin-bottom: 20px;
                  max-width: 600px;
                  font-size: 16px;
                  line-height: 19px;
                  overflow-wrap: break-word;
                }

                .btn_bottom {
                  display: flex;
                  user-select: none;

                  .record {
                    padding: 5px;
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    background: #3077FF;
                    box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 14px;
                    cursor: pointer;
                  }
                }

                &.right {
                  margin: auto 0 auto auto;
                }
              }

              &.default {
                .banner {
                  background-color: transparent;

                  .record {
                    background-color: var(--dark);
                  }
                }
              }

              &.dark {
                .banner {
                  .title,
                  .info {
                    color: var(--dark) !important;
                  }
                }
              }
            }
          }
        }

        .group_block {
          padding: 20px;
          margin-bottom: 40px;
          max-width: 580px;
          background: #201F26;
          border-radius: 25px;

          .description_row {
            margin-bottom: 15px;

            .title {
              margin: 0 0 10px 15px;
              color: var(--text_color_dark);
            }

            .description {
              display: flex;
              padding: 8px 15px;
              background: var(--dark);
              border: 1px solid var(--background_lighten-dark);
              border-radius: 16px;

              img {
                margin-right: 15px;
                align-self: center;
              }

              .text_1,
              .text_2 {
                margin-right: 15px;
                max-width: 180px;
                width: 100%;
                align-self: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .text_2 {
                max-width: 160px;
              }

              .group_icon {
                display: flex;
                margin-left: auto;

                img:last-child {
                  margin-right: 0px;
                }

                img {
                  align-self: center;
                  cursor: pointer;
                }
              }
            }
          }

          .input_custom-parent {
            width: 100%;
            margin: 0 0 15px 0;

            .title_info {
              margin-bottom: 10px;

              span {
                margin-bottom: 0;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .btn_add {
            display: flex;
            width: fit-content;
            cursor: pointer;

            img {
              margin-right: 10px;
              align-self: center;
            }

            span {
              align-self: center;
              color: #3077FF;
            }
          }
        }

        .basic_info-parent {
          display: flex;
          margin-bottom: 40px;

          .basic_info-block {
            padding: 20px;
            position: relative;
            width: 100%;
            max-width: 580px;
            background: #201F26;
            border-radius: 25px;

            .demo_row {
              margin-bottom: 15px;
              display: flex;
              width: 100%;
              height: 22px;
              align-self: end;
              background: rgba(162, 170, 202, 0.2);
              border: 1px solid var(--text_color_dark);
              border-radius: 15px;
              transition: all .3s linear;
              user-select: none;

              div {
                width: 100%;
                text-align: center;
                color: #757A88;
                font-size: 12px;
                white-space: nowrap;
                transition: all .3s ease;
                cursor: pointer;

                &.active {
                  background: #3077FF;
                  border-radius: 26px;
                  color: white;
                }
              }

              span {
                align-self: center;
                color: var(--text_color_dark);
              }

              &.border {
                border-color: #3077FF;
              }
            }

            .title_info {
              margin-bottom: 10px;
              display: flex;
              align-items: center;

              span {
                margin: 0 0 0 15px;
                color: #A2AACA;
                line-height: 16.71px;
              }

              svg {
                margin-left: 10px;
                cursor: help;
              }
            }

            .select_block {
              position: relative;
              /* margin-bottom: 15px;*/

              .input_classic {
                padding-top: 1px;
                margin-bottom: 0;
                height: 40px;
                z-index: 5;
              }

              .parent_tag {
                position: absolute;
                width: 100%;
                padding-top: 23px;
                top: 20px;
                left: 0;
                opacity: 0;
                max-height: 0;
                z-index: -1;
                background-color: var(--background_lighten-dark);
                border-radius: 0 0 10px 10px;
                transition: all .3s;
                overflow-y: auto;
                overflow-x: hidden;

                .tag {
                  width: 100%;
                  padding: 5px 0 5px 15px;
                  cursor: pointer;
                  border-radius: 0;
                  margin-bottom: 0;

                  &:hover {
                    background-color: darken(#474c5a, 10%);
                  }

                  &:last-child {
                    &:hover {
                      border-radius: 0 0 10px 10px;
                    }
                  }
                }

                &.open {
                  transition: all .3s;
                  opacity: 1;
                  overflow-y: auto;
                  max-height: 155px;
                  z-index: 4;
                }
              }

              .input_select-skill {
                width: 100%;
                background: #757A88;
                border-radius: 16px;

                .ant-select-selector {
                  padding: 0px 40px 0 15px;
                  background: var(--dark);
                  height: 40px;
                  border: 1px solid transparent;
                  border-radius: 16px;
                  color: #FFFFFF;
                  box-shadow: none;

                  input {
                    margin-top: 3px;
                  }
                }

                span.ant-select-selection-item {
                  top: 3px;
                  height: fit-content;
                  color: white !important;
                }

                .ant-select-selection-placeholder {
                  margin-top: 9px;
                  font-size: 14px;
                  line-height: 17px;
                  color: white;
                }

                .ant-select-open {
                  .ant-select-selector {
                    border-radius: 16px 16px 0 0;
                    border-color: transparent;
                  }
                }

                .ant-select-arrow {
                  display: none;
                }

                &.active {
                  border-radius: 16px 16px 0 0;
                  border-color: #9B99A7;

                  .ant-select-selector {
                    border-radius: 16px 16px 0 0 !important;
                  }
                }
              }

              span.ant-select-selection-search {
                left: 13px;
              }

              .img_add {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                z-index: 4;
              }

              .ant-select-item.ant-select-item-option {
                &:hover {
                  background-color: lighten(#343843, 10%);
                }
              }

              .spin_loading {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 5;
              }
            }

            .icon_currency {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 88px;
              right: 24px;
              height: 32px;
              height: 32px;
              width: 32px;
              background: var(--background_lighten-dark);
              border: 1px solid #757A88;
              border-radius: 12px;
              z-index: 3;

              span {
                margin: 0 0 0 1px;
              }
            }

            .input_classic {
              margin-bottom: 15px;
              height: 40px;
            }

            .h3 {
              margin: 15px 0;
              font-size: 16px;
              line-height: 19px;
            }

            .skill_block {
              margin-top: 15px;
              padding: 7.5px 12px 2.5px 12px;
              display: flex;
              flex-wrap: wrap;
              background: var(--dark);
              border-radius: 16px;
              border: 1px solid var(--background_lighten-dark);

              .skill {
                position: relative;
                padding: 2px 30px 3px 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                width: fit-content;
                color: #30E0A1;
                background: rgba(48, 224, 161, 0.2);
                border-radius: 34px;

                span {

                }

                img {
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);
                  cursor: pointer;
                }
              }
            }
          }

          .academic_plan {
            margin-left: 34px;
            padding: 15px 15px 5px 15px;
            width: 100%;
            max-height: 150px;
            background: var(--dark);
            border: 1px solid var(--background_lighten-dark);
            border-radius: 30px;

            .title {
              margin-bottom: 15px;
              display: flex;
              align-items: center;

              img {
                margin-right: 15px;
              }

              span {
                font-size: 16px;
                line-height: 19px;
              }
            }

            .list_skills {
              display: flex;
              flex-wrap: wrap;
              max-height: 77px;
              overflow-y: auto;

              .skill {
                margin: 0 10px 10px 0;
                padding: 5px 5px 5px 10px;
                display: flex;
                width: fit-content;
                font-size: 14px;
                background: rgba(22, 21, 27, 0.65);
                border-radius: 29px;
                cursor: pointer;

                span {
                  margin-right: 10px;
                }
              }
            }

            .no_data {
              color: var(--text_color_dark);

            }
          }
        }

        .contact_block {
          display: flex;
          padding: 20px;
          margin-bottom: 40px;
          max-width: 580px;
          background: #201F26;
          border-radius: 25px;

          .title_info {
            margin-bottom: 10px;

            span {
              margin: 0 0 0 15px;
              line-height: 16.71px;
            }
          }

          .input_custom-parent {
            margin-top: 0;
            margin-right: 10px;
            width: 100%;
          }

          .phone_parent {
            margin-bottom: 0;
            width: 100%;

            .title {
              margin-bottom: 10px;
              line-height: 16.71px;
            }

            .phoneNumber {
              height: 40px;
              background-color: var(--dark);
            }

            .icon_country {
              top: 34px;
            }

            .arrow_phone {
              top: 27px;
            }
          }
        }

        .btn_next {
          user-select: none;
          max-width: 580px;
          box-shadow: none;
        }

        span.ant-upload-list-item-card-actions.picture {
        }

        span.ant-progress-text {
          color: white;
        }
      }

      .step_2 {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 180px);

        .h2 {
          margin: 25px 0 15px 15px;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;

          svg {
            margin-left: 10px;
            margin-top: 2px;
            cursor: help;
          }
        }

        .speaker_parent-block {
          display: flex;
          flex-direction: column;
          position: relative;

          .left_col {
            padding: 20px;
            position: absolute;
            max-height: 1px;
            transform: scale(0);
            opacity: 0;
            background: #201F26;
            border-radius: 25px;
            transition: margin .5s, max-height .5s, max-width .5s, min-width .5s, transform .7s, opacity .5s;

            .photo_row {
              display: flex;
              margin-bottom: 15px;

              .photo {
                margin-right: 10px;
                position: relative;


                img {
                  max-width: 265px;
                  min-width: 265px;
                  max-height: 265px;
                  min-height: 265px;
                }

                .btn_photo {
                  position: absolute;
                  padding: 3px 4px 4px 4px;
                  height: 28px;
                  width: calc(100% - 20px);
                  bottom: 10px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
              }

              .fio {
                display: flex;
                flex-direction: column;
                align-self: baseline;
                margin-top: -10px;

                .input_custom-parent {
                  margin-top: 25px;
                }
              }
            }

            .textarea_antd {
              margin-bottom: 15px;
            }

            &.show {
              position: relative;
              margin-top: 25px;
              max-height: 500px;
              max-width: 580px;
              min-width: 580px;
              transform: scale(1);
              opacity: 1;
              transition: margin .5s, max-height .5s, max-width .5s, min-width .5s, transform .7s, opacity .5s;
            }
          }

          .right_col {
            padding: 10px 20px 5px 20px;
            /*  margin-left: 25px;*/
            max-width: 580px;
            width: 100%;
            height: fit-content;
            background: #201F26;
            border-radius: 25px;

            .description_row {
              margin-bottom: 15px;
            }
          }

          .btn_save {
            margin-top: 0;
          }
        }

        .who_suit-block {
          padding: 20px;
          max-width: 580px;
          background: #201F26;
          border-radius: 25px;

          .description_row {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .input_custom-parent {
            width: 100%;
            margin-bottom: 15px;
            margin-top: 0;
          }

          .textarea_antd {
            margin-bottom: 15px;
          }

          &.template {
            position: absolute;
            max-height: 1px;
            max-width: 1px;
            min-width: 1px;
            transform: scale(0);
            opacity: 0;
          }

          &.show {
            position: relative;
            margin-top: 25px;
            max-height: 500px;
            max-width: 580px;
            min-width: 580px;
            transform: scale(1);
            opacity: 1;
            transition: margin .5s, max-height .5s, max-width .5s, min-width .5s, transform .7s, opacity .5s;
          }
        }

        .what_learn-block {
          padding: 20px;
          max-width: 580px;
          margin-bottom: 25px;
          background: #201F26;
          border-radius: 25px;

          .description_row {
            margin-bottom: 15px;

            .title {
              line-height: 17px;
            }

            &:last-child {
              margin-bottom: 0px;
            }
          }

          .input_custom-parent {
            width: 100%;
            margin-bottom: 15px;
          }

          .textarea_antd {
            margin-bottom: 15px;
          }

          .drop_down-parent {
            padding: 6px 15px;
            position: relative;
            width: 100%;
            height: 40px;
            background: var(--dark);
            border: 1px solid var(--background_lighten-dark);
            box-shadow: 10px 61px 25px rgba(0, 0, 0, 0.01), 6px 34px 21px rgba(0, 0, 0, 0.05), 3px 15px 15px rgba(0, 0, 0, 0.09), 1px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            cursor: pointer;
            z-index: 3;

            .placeholder {
              position: absolute;
              top: 5px;
              left: 15px;
              font-size: 16px;
              color: #757A88;
            }

            span {
              font-size: 16px;
            }

            svg {
              position: absolute;
              top: 17px;
              right: 17px;
              transition: transform .3s;
            }

            &.open {
              svg {
                transition: transform .3s;
                transform: rotate(180deg);
              }
            }
          }

          .drop_down {
            padding-top: 35px;
            margin-top: -30px;
            margin-bottom: 5px;
            max-height: 0px;
            opacity: 0;
            overflow-y: auto;
            overflow-x: hidden;
            background: var(--background_lighten-dark);
            border: 1px solid #757A88;
            border-radius: 16px;
            transition: max-height .5s, opacity .5s, margin .5s, z-index .5s;
            z-index: -1;

            .item {
              padding: 5px 15px;
              width: 100%;
              cursor: pointer;
              transition: color .3s;

              &:hover {
                color: var(--text_color_dark);
              }
            }

            &.open {
              transition: max-height .5s, opacity .5s, margin .5s, z-index .5s;
              position: relative;
              margin-bottom: 15px;
              opacity: 1;
              max-height: 200px;
              z-index: 2;
            }
          }

          &.template {
            position: absolute;
            max-height: 1px;
            max-width: 1px;
            min-width: 1px;
            transform: scale(0);
            opacity: 0;
          }

          &.show {
            position: relative;
            margin-top: 25px;
            max-height: 500px;
            max-width: 580px;
            min-width: 580px;
            transform: scale(1);
            opacity: 1;
            transition: margin .5s, max-height .5s, max-width .5s, min-width .5s, transform .7s, opacity .5s;
          }
        }

        .btn_prev {
          padding: 9px;
          height: 40px;
          max-width: 135px;
          width: 100%;
          margin-right: 15px;
          color: white;
          text-align: center;
          background: var(--background_lighten-dark);
          border-radius: 15px;
          cursor: pointer;
        }

        .btn_next {
          user-select: none;
          box-shadow: none;
          max-width: 580px;
          width: 100%;
        }

        // переиспользуемые
        .btn_add {
          display: flex;
          cursor: pointer;

          img {
            margin-right: 10px;
            align-self: center;
          }

          span {
            align-self: center;
            color: #3077FF;
          }

          &.show_speaker {
            margin: 25px 0 0 15px;
          }
        }

        .btn_save {
          padding: 3px 6px 6px 6px;
          margin-top: 15px;
          max-width: 167px;
          height: 30px;
          width: 100%;
          text-align: center;
          color: var(--dark);
          background: #30E0A1;
          box-shadow: 0px 9px 10px rgba(48, 224, 161, 0.2);
          border-radius: 13px;
          cursor: pointer;
          user-select: none;
        }

        .btn_preview {
          max-width: 220px;
          width: 100%;
          height: 40px;
          background: #30E0A1;
          box-shadow: 0px 9px 10px rgba(48, 224, 161, 0.2);
          user-select: none;
        }

        .btn_save-course {
          margin-right: 15px;
          margin-bottom: 20px;
          height: 40px;
          max-width: 220px;
          width: 100%;
          user-select: none;
        }

        .description_row {
          .title {
            margin: 0 0 5px 15px;
            color: var(--text_color_dark);
          }

          .description {
            display: flex;
            padding: 8px 15px;
            background: var(--dark);
            border: 1px solid var(--background_lighten-dark);
            border-radius: 16px;
            transition: all .3s;

            img {
              margin-right: 15px;
              align-self: center;
            }

            .text_1,
            .text_2 {
              margin-right: 15px;
              max-width: 180px;
              width: 100%;
              align-self: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .text_2 {
              max-width: 160px;
            }

            .group_icon {
              display: flex;
              margin-left: auto;

              img:last-child {
                margin-right: 0px;
              }

              img {
                align-self: center;
                cursor: pointer;
              }
            }

            &.active {
              border-color: var(--text_color_dark);
            }
          }
        }
      }

      .step_3 {
        .demo_row {
          max-width: 580px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 0px 0 15px 0;

          span {
            align-self: center;
            color: var(--text_color_dark);
          }

          .group_demo {
            display: flex;
            margin-top: 0;
            margin-bottom: 25px;
            width: 100%;
            height: 22px;
            align-self: end;
            background: rgba(162, 170, 202, 0.2);
            border: 1px solid var(--text_color_dark);
            border-radius: 15px;

            div {
              width: 100%;
              text-align: center;
              color: #757A88;
              transition: all .7s ease;
              font-size: 12px;
              white-space: nowrap;
              cursor: pointer;

              &.active {
                background: #3077FF;
                border-radius: 26px;
                color: white;
              }

            }
          }
        }

        .btn_row {
          display: flex;
          max-width: 580px;
          margin-bottom: 25px;
          width: 100%;

          .btn_prev {
            padding: 9px;
            margin-right: 15px;
            max-width: 135px;
            height: auto;
            width: 100%;
            text-align: center;
            background: var(--background_lighten-dark);
            border-radius: 15px;
            cursor: pointer;
            user-select: none;

          }

          .btn_send {
            padding: 9px;
            margin-top: 0;
            height: 40px;
            width: 100%;
            text-align: center;
            color: white;
            background: #3077FF;
            box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
            border-radius: 15px;
            cursor: pointer;
          }
        }

        .parent_course {
          padding: 25px;
          display: flex;
          justify-content: center;
          max-width: 580px;
          width: 100%;
          background: #1E1F26;
          border-radius: 30px;

          .reuse_course-item {
            min-width: 297px;
            width: calc(25% - 30px);
            height: fit-content;
            margin: auto;
            position: relative;
            display: flex;
            flex-direction: column;
            background: #343843;
            border-radius: 30px;
            order: 4;

            .preview_image {
              border-radius: 30px;
              width: 100%;
              aspect-ratio: 1.77;
            }

            .rate {
              position: absolute;
              display: flex;
              padding: 4px 14px;
              top: 23px;
              left: 22px;
              height: 25px;
              background: rgba(32, 30, 38, 0.6);
              border-radius: 34px;

              .ant-rate {
                font-size: 10px;
              }

              li.ant-rate-star {
                margin-right: 3px;
                margin-bottom: 0;
                background-color: transparent;
              }
            }

            .popular {
              position: absolute;
              top: 23px;
              right: 22px;
              display: flex;
              padding: 2px 3px;
              background: rgba(32, 30, 38, 0.6);
              border-radius: 34px;

              .svg {
                display: flex;

                svg {
                  align-self: center;
                }
              }

              span {
                margin: 0 10px;
                font-size: 14px;
              }
            }

            .category {
              display: flex;
              margin: 0 20px 10px 20px;
              padding: 4px 10px;
              width: fit-content;
              border-radius: 34px;

              img {
                margin-right: 10px;
                align-self: center;
              }

              span {
                font-size: 14px;
              }
            }

            .info {
              margin: 0 20px 7px 20px;
              max-width: 280px;
              font-size: 20px;
              line-height: 24px;
            }

            .stage {
              ul, li {
                padding: 0;
                margin: 0;
              }

              ul {
                margin: 0 20px 5px 20px !important;
                display: flex;
                flex-wrap: wrap;
              }

              li {
                display: flex;
                align-items: center;
                white-space: nowrap;
                font-size: 16px;
                color: var(--text_color_dark);
                cursor: help;

                img {
                  margin-left: 5px;
                  margin-top: -1px;
                }

                &:not(:last-child) {
                  margin-right: 15px;
                }
              }
            }

            .title_skill {
              margin: 0 20px 10px 20px;
              font-size: 16px;
            }

            .key_skills {
              margin: 0 20px 10px 20px;
              max-height: 123px;
              overflow: auto;
              display: flex;
              flex-wrap: wrap;

              .skills {
                display: flex;
                padding: 4px 10px;
                margin-bottom: 5px;
                position: relative;
                flex-wrap: wrap;
                color: var(--text_color_dark);
                font-size: 14px;
                background: rgba(162, 170, 202, 0.2);
                border-radius: 16px;

                img {
                  position: absolute;
                  top: 8px;
                  right: 5px;
                  cursor: pointer;
                }

                &:not(:last-child) {
                  margin-right: 8px;
                }
              }
            }

            .date_course {
              padding: 3px;
              margin: 0 20px 10px 20px;
              width: fit-content;
              background: rgba(32, 30, 38, 0.6);
              border-radius: 34px;

              img {
                width: 19px;
                height: 19px;
                vertical-align: sub;
              }

              svg {
                vertical-align: sub;
              }

              span {
                margin: 0 7px;
                font-size: 14px;
              }
            }

            .bottom_block {
              margin-top: auto;

              .link_row {
                display: flex;
                margin: 0 0 10px 20px;

                .copy_link {
                  display: flex;
                  flex-direction: column;
                  max-width: 55px;
                  width: 100%;
                  margin-right: 10px;

                  .title {
                    font-size: 16px;
                    margin-bottom: 5px;
                  }

                  .svg {
                    padding: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 31px;
                    background-color: #797e8d;

                    svg {
                      max-width: 30px;
                      height: 18px;
                      cursor: pointer;

                      path {
                        stroke: white;

                      }
                    }
                  }
                }

                .view_catalog {
                  max-width: 210px;
                  width: 100%;

                  .title {
                    font-size: 16px;
                    margin-bottom: 5px;
                  }

                  .corp {
                    padding: 1px 5px;
                    margin-right: 10px;
                    width: fit-content;
                    height: 24px;
                    color: white;
                    background: #474C5A;
                    border-radius: 149px;

                    div {
                      vertical-align: middle;
                    }
                  }

                  .checkbox {
                    height: 24px;
                    margin-right: 10px;
                    align-self: center;

                    .switch input:checked + .slider:before {
                      transform: translateX(22px);
                    }

                    input:checked + .slider {
                      background-color: #797e8d;
                    }

                    .switch {
                      margin-top: 0;

                      .slider.round {
                        height: 24px;
                      }

                      .slider::before {
                        height: 19px;
                        width: 19px;
                        bottom: 3px;
                      }

                    }
                  }
                }
              }

              .comment_row {
                display: flex;
                margin: 10px 20px;

                .comment_count {
                  margin-right: 10px;
                  max-width: 167px;
                  width: 100%;
                  height: 24px;
                  text-align: center;
                  color: white;
                  border: 1px solid var(--text_color_dark);
                  border-radius: 16px;
                }


              }

              a {
                color: white;
              }
            }

            .cost {
              margin: 0 20px 10px 20px;
              font-size: 16px;

              span {
                margin-right: 5px;
              }
            }

            .group_btn {
              display: flex;
              width: 100%;

              .btn_edit-course {
                margin: 0 0 15px 20px;
                padding: 9px;
                text-align: center;
                flex-grow: 1;
                font-size: 14px;
                background: #3077FF;
                box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
                border-radius: 15px;
                cursor: pointer;
              }

              .btn_view {
                margin-left: 10px;
                margin-right: 20px;
                position: relative;
                width: 41px;
                height: 41px;
                background: #3077FF;
                box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
                border-radius: 15px;
                cursor: pointer;

                svg {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }

            video,
            iframe {
              border-radius: 30px;
            }

            &:nth-child(4n+4) {
              margin-right: 0;
            }

          }
        }

        .btn_send {
          padding: 7px;
          margin-top: 25px;
          height: 40px;
          max-width: 580px;
          width: 100%;
          text-align: center;
          color: white;
          background: #3077FF;
          box-shadow: 0px 9px 20px rgba(48, 119, 255, 0.45);
          border-radius: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .group_bottom-btn {
    margin-top: auto;
  }

  .input_classic {
    background-color: var(--dark);
  }

  .ant-upload.ant-upload-drag {
    background-color: var(--dark) !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .ant-tabs {
    color: #757a88;
  }
  .ant-tabs-tab:hover {
    color: #8146ff;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 2px solid #343843;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #8146ff;
  }
  .ant-tabs-tab-btn:active {
    color: #757a88;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    background: #8146ff;
    height: 6px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

@media (max-width: 1300px) {
  .add_course-parent {
    .step_1 {
      .cards_course-parent {
        flex-direction: column;

        .chat_gpt {
          margin: 40px 0 0 0 !important;
          max-width: 580px !important;
          min-height: 300px;

          .result {
            max-height: 250px !important;
            min-height: 250px !important;
          }
        }
      }

      .landing_parent {
        flex-direction: column;
        width: 100%;
        max-width: 580px !important;

        .landing_block {
          margin-bottom: 60px !important;
        }

        .parent_banner-preview {
          bottom: 0;
          margin: 0 !important;
          max-width: 580px !important;
          min-height: 400px !important;

          .banner_preview {
            height: 100vh !important;
          }
        }

        .parent_course-card {
          bottom: 0;
          left: 0;
        }
      }

      .basic_info-parent {

        max-width: 580px !important;
        flex-direction: column;

        .basic_info-block {
          margin-bottom: 20px !important;
        }

        .academic_plan {
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 1035px) {
  .add_course-parent {
    .step_1 {
      .cards_course-parent {
        max-width: 580px !important;
        flex-direction: column;

        .reuse_course-item {
          margin: 20px auto 40px auto !important;

          .label {
            left: 35px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .add_course-parent {
    .top_navigation {
      margin-right: 0 !important;
      flex-direction: column !important;

      .btn_prev {
        margin-bottom: 10px !important;
      }

      .group {
        margin-left: 0 !important;
        max-width: 100% !important;
        margin-right: auto !important;
        padding-bottom: 0 !important;
      }

      .empty {
        display: none !important;
      }
    }

    .remove_course {
      top: 70px !important;
      transform: scale(0.5) !important;
      right: 4% !important;
    }

    .h2 {
      margin-bottom: 15px !important;
    }

    .group {
      padding-bottom: 100px !important;
    }

    .parent_buttons {
      display: none !important;
    }

    .footer_bar {
      display: block !important;
    }

    .inner {
      min-width: inherit !important;

      .steps {
        max-width: inherit !important;
        margin-bottom: 15px !important;

        .line {
          margin: 0 10px !important;
          max-width: inherit !important;
        }

        .step {
          min-width: 32px !important;
          min-height: 32px !important;
          max-width: 32px !important;
          max-height: 32px !important;
          margin: 0 !important;
        }
      }

      .step_1 {
        padding-bottom: 110px !important;

        .contact_block {
          flex-direction: column !important;

          .input_custom-parent {
            margin-right: 0 !important;
            margin-bottom: 10px !important;
          }

          .phone_parent {
            max-width: inherit !important;
          }
        }

        .cards_course-parent,
        .banner_preview {
          max-width: inherit !important;
        }

        .cards_course,
        .group_block,
        .landing_block,
        .contact_block,
        .basic_info-block,
        .btn_next {
          padding: 15px !important;
          max-width: inherit !important;
          min-width: inherit !important;
          border-radius: 16px !important;
        }

        .btn_next {
          padding: 8px !important;
        }
      }

      .step_2 {
        padding-bottom: 110px !important;

        .h2 {
          margin-top: 10px !important;
        }

        .speaker_parent-block {
          flex-direction: column !important;

          .left_col {
            margin-bottom: 10px !important;
            max-width: inherit !important;
            min-width: inherit !important;

            &.show {
              max-height: 760px !important;
            }
          }

          .right_col {
            margin-left: 0 !important;
            max-width: inherit !important;
            min-width: inherit !important;
          }

          .btn_add {
            width: fit-content !important;
          }
        }

        .who_suit-block,
        .what_learn-block,
        .group_bottom-btn {
          max-width: inherit !important;
          min-width: inherit !important;
        }

        .group_bottom-btn {
          flex-direction: column !important;

          .btn_prev,
          .btn_save-course,
          .btn_preview {
            margin: 0 0 10px 0 !important;
            width: 100% !important;
            max-width: 100% !important;
          }
        }

        .btn_add {
          width: fit-content !important;
        }
      }
    }
  }
  .parent_landing-isAuth {
    .preview_course {
      .group_photo {
        flex-direction: column !important;

        .left {
          margin: 50px auto 0 auto !important;
          width: 100% !important;
          text-align: center !important;

          .info {
            max-width: 100% !important;
            text-align: center !important;
          }
        }

        .btn_bottom {

          .record {
            margin: 0 auto !important;
          }
        }

        .right {
          width: 100% !important;

          .image {
            width: inherit !important;
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .add_course-parent {
    .remove_course {
      transform: scale(0.5) !important;
      right: 4% !important;
      top: 92px !important;
    }

    .top_navigation {
      .group {
        height: initial !important;
        flex-direction: column !important;
        border-radius: 8px !important;

        div {
          border-radius: 4px !important;
        }
      }
    }

    .photo_row {
      flex-direction: column !important;

      .photo {
        display: flex;

        img {
          max-width: 316px !important;
          margin: 0 auto !important;
        }

        .btn_photo {
          width: calc(100% - 170px) !important;
        }

      }

      .fio {
        align-self: auto !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .add_course-parent {
    .group_demo {
      flex-direction: column !important;
      height: auto !important;
      border-radius: 10px !important;

      div {
        padding: 5px 0 !important;
        border-radius: 10px !important;
      }
    }
  }

}

@media (max-width: 380px) {
  .add_course-parent {
    .photo_row {
      .photo {
        .btn_photo {
          width: calc(100% - 60px) !important;
        }
      }
    }
  }
}

// antd styles
.modal_edit-image {
  .ant-modal-content {
    border-radius: 40px;
  }

  .edit_image {
    margin: auto;
    display: flex;
    flex-direction: column;

    .ReactCrop {
      margin: 0 auto;
      /*border-radius: 20px;*/
    }

    .canvas,
    canvas {
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;
    }

    .group_btn {
      display: flex;
      margin: 15px 0 0 0;

      .btn {
        padding: 3px 16px;
        width: 100%;
        text-align: center;
        background-color: #3077FF;
        border-radius: 16px;
        color: white;
        cursor: pointer;

        &.cansel {
          margin-left: 10px;
          max-width: 100px;
          background-color: var(--background_lighten-dark);
        }
      }
    }
  }
}

.ant-select-dropdown {
  background-color: #343843;
  margin-top: -20px;
  border-radius: 0;

  &.ant-select-dropdown-placement-topLeft {
    padding: 0;
    background-color: #343843;
    border-radius: 16px 16px 0 0;
  }

  &.ant-select-dropdown-placement-bottomRight {
    padding: 0;
    background-color: #343843;
  }
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: lighten(#343843, 10%);
}

.ant-select-item.ant-select-item-option {
  color: white;

  &:hover {
    background-color: lighten(#343843, 10%);
  }
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: lighten(#343843, 10%);
}

.ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement {
  color: white;
}

.ant-picker-date-panel {
  color: white;
  background-color: var(--dark);

  button {
    color: white;
  }
}

.ant-picker-cell-inner {
  color: white;
  border-color: var(--background_lighten-dark);

  &:hover {

  }
}

.ant-picker {
  input {
    color: white;
  }
}

.ant-picker-header {
  border-bottom: 1px solid var(--background_lighten-dark);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--dark);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--text_color_dark);
}

//
.createDateRangePicker
.ant-picker-cell-in-view.ant-picker-cell-range-start
.ant-picker-cell-inner {
  background-color: #27c456 !important;
}

.createDateRangePicker
.ant-picker-cell-in-view.ant-picker-cell-range-end
.ant-picker-cell-inner {
  background-color: #27c456 !important;
}

.createDateRangePicker
.ant-picker-cell-in-view.ant-picker-cell-today
.ant-picker-cell-inner::before {
  // border-color: #1f9643e5 !important;
}

.createDateRangePicker
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.createDateRangePicker
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.createDateRangePicker
.ant-picker-time-panel-column
> li.ant-picker-time-panel-cell-selected
.ant-picker-time-panel-cell-inner {
  background: var(--background_lighten-dark) !important;
}

.createDateRangePicker .ant-btn-primary {
  background-color: #1f9643e5 !important;
  border-color: #1f9643e5 !important;
}

// modal Date
.modal_date {
  span {
    font-size: 16px;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .btn_ok {
    margin-left: auto;
    padding: 8px;
    color: white;
    max-width: 90px;
    width: 100%;
    font-size: 14px;
    border: none;
    text-align: center;
    background: #3077FF;
    box-shadow: 0px 9px 20px rgb(48 119 255 / 45%);
    border-radius: 8px;
    cursor: pointer;
  }

  .ant-modal-content {
    background-color: var(--dark);
  }
}


