.modal_traektory-mobile {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    background: #27292F;
    box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.6);
    border-radius: 20px;
  
    .title {
      display: flex;
  
      img {
        align-self: center;
      }
  
      span {
        margin-left: 8px;
        font-size: 18px;
        align-self: center;
        color: white;
      }
    }
  
    .select_block {
      position: relative;
      margin-top: 15px;
      width: 100%;
      height: 34px;
      color: white;
      z-index: 4;
  
      .input_classic {
        padding: 0 15px 1px 15px;
        height: 34px;
        border: 1px solid transparent;
        z-index: 4;
  
        &:focus {
          border: 1px solid #757A88 !important;
        }
  
        &:hover {
          border: 1px solid #757A88 !important;
        }
      }
  
      .parent_tag {
        position: absolute;
        width: 100%;
        padding-top: 15px;
        top: 20px;
        left: 0;
        opacity: 0;
        transform: scale(0);
        max-height: 0;
        background-color: var(--background_lighten-dark);
        border-radius: 0 0 10px 10px;
        transition: opacity .3s ease, max-height .6s ease;
        z-index: 4;
  
        .tag_ {
          padding: 3px 0 3px 15px;
          cursor: pointer;
          z-index: 3;
  
          &:hover {
            background-color: darken(#474c5a, 10%);
          }
  
          &:last-child {
            &:hover {
              border-radius: 0 0 10px 10px;
            }
          }
        }
  
        &.open {
          transition: opacity .3s ease, max-height .3s ease;
          transform: scale(1);
          opacity: 1;
          overflow-y: auto;
          max-height: 155px;
          z-index: 3;
        }
  
        &::-webkit-scrollbar-thumb {
          border-radius: 9em;
          background: var(--dark);
        }
      }
  
      .spin_loading {
        position: absolute;
        top: 8px;
        right: 10px;
        z-index: 5;
      }
    }
  
    .down_block-traektory {
      margin-top: -28px;
      padding: 30px 10px 0 10px;
      border: 1px solid #474C5A;
      border-radius: 10px 10px 24px 24px;
      background-color: var(--dark);
  
      .block_tags {
        display: flex;
        flex-wrap: wrap;
      }
  
      .tag {
        padding: 2px 30px 4px 15px;
        margin: 0 5px 10px 0;
        position: relative;
        display: flex;
        align-items: center;
        width: -moz-fit-content;
        width: fit-content;
        background: rgba(22, 21, 27, 0.65);
        border: 1px solid transparent;
        border-radius: 29px;
        transition: all 0.3s linear;
        color: white;
        font-size: 12px;
  
        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2px;
          z-index: 3;
        }
      }
    }
  
    .button_classic {
      margin-top: 15px;
      padding: 6px;
      text-align: center;
      box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
      border-radius: 20px;
    }
  
    .close {
      margin-top: 15px;
      font-size: 14px;
      color: white;
      text-align: center;
    }
  
    .buttons {
      position: relative;
      z-index: 1;
  
      .btn_search-course {
        padding: 3px;
        text-align: center;
        //  box-shadow: 0 9px 20px rgba(48, 119, 255, 0.45);
        box-shadow: none;
        width: 100%;
        border: 1px solid #757A88;
        background: transparent;
        border-radius: 20px;
        cursor: pointer;
        color: white;
        transition: all .3s linear;
        z-index: 1;
  
        &:hover {
          background: #3077FF;
        }
  
        &:first-child {
          margin: 15px 0;
        }
      }
    }
  }